import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { loggedUser } from "@shared/helpers";
import { useUserProfile } from "@shared/hooks";

import { AuthUser } from "@app/types";

export function useAuthUser() {
  const authentication = useSelector(state => state.authentication);
  const { userProfile } = useUserProfile();

  const getUserWithInfo = useCallback((): AuthUser => {
    if (authentication?.user?.id) {
      const xRole = userProfile?.role;
      return {
        ...authentication.user,
        name: `${authentication.user.firstname} ${authentication.user.lastname}`,
        isHostUser: loggedUser.doesBelongToHost(authentication.user),
        isClientUser: loggedUser.doesBelongToClient(authentication.user),
        isMemberOfUserGroup: userGroup =>
          loggedUser.doesBelongToUserGroup(userGroup, authentication.user),
        isLoggedIn: authentication.loggedIn && authentication.verified,
        checkAccess: (resource, accessLevel) =>
          loggedUser.checkAccess(xRole?.permissions, resource, accessLevel),
        xRole
      };
    } else {
      return {
        isHostUser: false,
        isClientUser: false,
        isMemberOfUserGroup: () => false,
        checkAccess: () => false,
        isLoggedIn: false
      };
    }
  }, [
    authentication.loggedIn,
    authentication.user,
    authentication.verified,
    userProfile?.role
  ]);

  const [user, setUser] = useState<AuthUser>(getUserWithInfo());

  useEffect(() => {
    if (authentication.user?.id) {
      setUser(getUserWithInfo);
    }
  }, [
    authentication.user,
    authentication.loggedIn,
    authentication.verified,
    userProfile?.id,
    getUserWithInfo
  ]);

  return { user, userProfile };
}
