import React, { useEffect, useMemo } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ResourceName,
  ResourcePermission,
  RoleFormData,
  RoleMode
} from "@app/types";

import { NameValuePair } from "@atoms/NameValuePair";
import { Pill, PillSize } from "@atoms/Pill";

import Form from "@components/atoms/Form";
import RadioGroup from "@components/atoms/Form/RadioGroup";
import { Inline, Stack } from "@components/fermions";
import ResourcesForm from "@components/organisms/ResourcesForm";
import BoxTemplate from "@components/templates/BoxTemplate";

type Props = {
  defaultValue?: Partial<RoleFormData>;
  resourcesPermissions?: ResourcePermission[];
  mode: RoleMode;
  isLoading?: boolean;
};

function RoleFields(props: Props) {
  const { defaultValue, resourcesPermissions = [], mode, isLoading } = props;
  const { t } = useTranslation();
  const { reset, watch } = useFormContext();

  const watchType = watch("type");

  useEffect(() => {
    if (!isLoading) {
      reset(defaultValue);
    }
  }, [defaultValue, reset, isLoading]);

  const resourcesToDisplay = useMemo(
    () =>
      resourcesPermissions
        .filter(
          resource => resource.section !== ResourceName.ENTITY_RESTRICTION
        )
        .filter(resource => resource.section !== ResourceName.ONETEAM_AI),
    [resourcesPermissions]
  );

  const showEntityRestrictionField = useMemo(() => {
    const hasEntityRestriction = resourcesPermissions.some(
      resource => resource.section === ResourceName.ENTITY_RESTRICTION
    );
    return watchType === "CLIENT" && hasEntityRestriction;
  }, [resourcesPermissions, watchType]);

  const showOneTeamAIField = useMemo(() => {
    const hasOneTeamAI = resourcesPermissions.some(
      resource => resource.section === ResourceName.ONETEAM_AI
    );
    return watchType === "HOST" && hasOneTeamAI;
  }, [resourcesPermissions, watchType]);

  const keyInformationContents = useMemo(() => {
    if (mode === RoleMode.VIEW) {
      const nameWidth = "130px";
      return (
        <Stack gap="200">
          <NameValuePair
            name="roles:ui.name.label"
            value={defaultValue?.name}
            nameWidth={nameWidth}
          />
          <NameValuePair
            name="roles:ui.description.label"
            value={defaultValue?.description}
            nameWidth={nameWidth}
          />
          <NameValuePair
            name="roles:ui.type.label"
            value={
              defaultValue?.type && (
                <Pill
                  label={t("roles:role.type", {
                    context: defaultValue.type
                  }).toUpperCase()}
                  size={PillSize.SMALL}
                />
              )
            }
            nameWidth={nameWidth}
          />
          {showEntityRestrictionField && (
            <NameValuePair
              name={`roles:ui.role.resource_${ResourceName.ENTITY_RESTRICTION}`}
              value={t(
                defaultValue?.entityRestricted
                  ? "common:ui.forms.yes.label"
                  : "common:ui.forms.no.label"
              )}
              nameWidth={nameWidth}
            />
          )}
          {showOneTeamAIField && (
            <NameValuePair
              name={`roles:ui.role.resource_${ResourceName.ONETEAM_AI}`}
              value={t(
                defaultValue?.oneTeamAIEnabled
                  ? "common:ui.forms.yes.label"
                  : "common:ui.forms.no.label"
              )}
              nameWidth={nameWidth}
            />
          )}
        </Stack>
      );
    }
    return (
      <Stack gap="300">
        <Form.TextField
          name="name"
          label={t("roles:ui.name.label")}
          defaultValue={defaultValue?.name}
          required
        />

        <Form.TextArea
          name="description"
          label={t("roles:ui.description.label")}
          defaultValue={defaultValue?.description}
        />

        <Inline wrap gap="500">
          <RadioGroup
            name="type"
            label={t("roles:ui.type.label")}
            items={[
              {
                value: "HOST",
                label: t("roles:role.type", { context: "HOST" })
              },
              {
                value: "CLIENT",
                label: t("roles:role.type", { context: "CLIENT" })
              }
            ]}
            defaultValue={defaultValue?.type}
            disabled={mode === RoleMode.EDIT}
          />
          {showEntityRestrictionField && (
            <RadioGroup
              name="entityRestricted"
              label={t(
                `roles:ui.role.resource_${ResourceName.ENTITY_RESTRICTION}`
              )}
              description={t(
                `roles:ui.role.resource.description_${ResourceName.ENTITY_RESTRICTION}`
              )}
              items={[
                { value: true, label: t("common:ui.forms.yes.label") },
                {
                  value: false,
                  label: t("common:ui.forms.no.label")
                }
              ]}
              defaultValue={defaultValue?.entityRestricted}
              disabled={mode === RoleMode.EDIT}
            />
          )}
          {showOneTeamAIField && (
            <RadioGroup
              name="oneTeamAIEnabled"
              label={t(`roles:ui.role.resource_${ResourceName.ONETEAM_AI}`)}
              items={[
                { value: true, label: t("common:ui.forms.yes.label") },
                {
                  value: false,
                  label: t("common:ui.forms.no.label")
                }
              ]}
              defaultValue={defaultValue?.oneTeamAIEnabled}
              disabled={mode === RoleMode.VIEW}
            />
          )}
        </Inline>
      </Stack>
    );
  }, [
    defaultValue?.description,
    defaultValue?.entityRestricted,
    defaultValue?.name,
    defaultValue?.oneTeamAIEnabled,
    defaultValue?.type,
    mode,
    showEntityRestrictionField,
    showOneTeamAIField,
    t
  ]);

  return (
    <Stack style={{ position: "relative" }} gap="300" width="100">
      <BoxTemplate
        title={t("admin:ui.manageRequestTypes.keyInformation.title")}
        stickyTitle
      >
        {keyInformationContents}
      </BoxTemplate>
      <ResourcesForm
        resourcesPermissions={resourcesToDisplay}
        defaultValues={defaultValue?.permissions}
        disabled={mode === RoleMode.VIEW}
        mode={mode}
      />
    </Stack>
  );
}

export default RoleFields;
