import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const projectDocumentService = createApi({
  reducerPath: "projectDocumentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: [
    "projectDocuments",
    "taggedProjectDocuments",
    "searchResult",
    "archivedProjectDocuments"
  ],
  endpoints: builder => ({
    getProjectFolderDocuments: builder.query({
      query: ({ projectId }) =>
        `/api/projects/${projectId}/documents/foldersAndDocuments?folderType=project`,
      providesTags: result => {
        const projectDocumentTags = result.documents.map(
          (document: { id: number }) => ({
            type: "projectDocuments",
            id: document.id
          })
        );
        projectDocumentTags.push({ type: "projectDocuments", id: "LIST" });
        return projectDocumentTags;
      }
    }),
    getArchivedProjectDocuments: builder.query({
      query: ({ projectId }) =>
        `/api/projects/${projectId}/documents/status?filter=ARCHIVED`,
      providesTags: (_, __, { projectId }) => {
        return [{ type: "archivedProjectDocuments", id: projectId }];
      }
    }),
    getProjectDocumentsByTag: builder.query({
      query: ({ projectId, tagId }) =>
        `/api/projects/${projectId}/documents?tagId=${tagId}`,
      providesTags: ["taggedProjectDocuments"]
    }),
    getProjectDocumentsBySearchString: builder.query({
      query: ({ projectId, searchString }) =>
        `/api/projects/${projectId}/documents/search?string=${searchString}`,
      providesTags: ["searchResult"]
    }),
    updateProjectDocument: builder.mutation({
      query: ({ documentId, doc }) => ({
        url: `/api/documents/${documentId}`,
        method: "PUT",
        body: doc
      }),
      invalidatesTags: () => [{ type: "projectDocuments", id: "LIST" }]
    }),
    archiveProjectDocument: builder.mutation({
      query: ({ documentId, doc }) => ({
        url: `/api/documents/${documentId}`,
        method: "PUT",
        body: doc
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { documentId, doc } = request;
        const projectId = doc.projectId;
        const removeDocumentFromProjectDocuments = dispatch(
          projectDocumentService.util.updateQueryData(
            "getProjectFolderDocuments",
            { projectId },
            draft => {
              draft.documents = draft.documents.filter(
                (d: { id: number }) => d.id !== documentId
              );
            }
          )
        );
        const addArchivedDocument = dispatch(
          projectDocumentService.util.updateQueryData(
            "getArchivedProjectDocuments",
            { projectId },
            draft => {
              draft.push(doc);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          removeDocumentFromProjectDocuments.undo();
          addArchivedDocument.undo();
        }
      }
    }),
    deleteProjectDocument: builder.mutation({
      query: ({ documentId }) => ({
        url: `/api/documents/${documentId}`,
        method: "DELETE"
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { documentId, projectId } = request;
        const patchResult = dispatch(
          projectDocumentService.util.updateQueryData(
            "getArchivedProjectDocuments",
            { projectId },
            draft => {
              return draft.filter((d: { id: number }) => d.id !== documentId);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      }
    }),
    restoreProjectDocument: builder.mutation({
      query: ({ documentId, projectId, payload }) => ({
        url: `/api/documents/${documentId}/restore`,
        method: "POST",
        body: {
          ...payload,
          projectId
        }
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { documentId, projectId } = request;
        const removeFromArchivedDocumentsList = dispatch(
          projectDocumentService.util.updateQueryData(
            "getArchivedProjectDocuments",
            { projectId },
            draft => {
              return draft.filter((d: { id: number }) => d.id !== documentId);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (e) {
          removeFromArchivedDocumentsList.undo();
        }
      },
      invalidatesTags: [{ type: "projectDocuments", id: "LIST" }]
    })
  })
});

export const {
  useGetProjectFolderDocumentsQuery,
  useGetProjectDocumentsByTagQuery,
  useGetProjectDocumentsBySearchStringQuery,
  useGetArchivedProjectDocumentsQuery,
  useUpdateProjectDocumentMutation,
  useArchiveProjectDocumentMutation,
  useDeleteProjectDocumentMutation,
  useRestoreProjectDocumentMutation,
  util: projectDocumentUtil
} = projectDocumentService;
