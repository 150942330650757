import React from "react";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Icon } from "@atoms/Icon";

import "./DataViewSwitcher.scss";

export enum Switchers {
  GRID = "grid",
  TABLE = "table",
  KANBAN = "view_column"
}

export type SwitchersToEnable = {
  selectedView: Switchers;
  enabledSwitchers: Array<Switchers>;
};

interface DataViewSwitcherProps {
  switchersToEnable: SwitchersToEnable;
  onChangeView: (value: Switchers) => void;
}

const iconMap = {
  [Switchers.GRID]: "apps",
  [Switchers.TABLE]: "menu",
  [Switchers.KANBAN]: "view_column"
};

export const DataViewSwitcher = ({
  switchersToEnable,
  onChangeView
}: DataViewSwitcherProps) => {
  const selectedView = switchersToEnable.selectedView;

  const enabledSwitchers = switchersToEnable.enabledSwitchers.map(
    (switcher, index) => {
      return (
        <React.Fragment key={switcher}>
          <div
            className={`ot-data-view-switcher__icon ot-data-view-switcher__icon--${switcher}`}
            key={switcher}
          >
            <Icon
              key={switcher}
              name={iconMap[switcher]}
              onClick={() => onChangeView(switcher)}
              className={`ot-data-view-switcher__${switcher} ${returnStringIfTrue(
                switcher === selectedView,
                `ot-data-view-switcher__${switcher}--on`
              )}`}
              size="l"
            />
          </div>
          {index < switchersToEnable.enabledSwitchers.length - 1 && (
            <div
              className="ot-data-view-switcher__divider"
              key={`${switcher}--divider`}
            />
          )}
        </React.Fragment>
      );
    }
  );

  return (
    <div
      data-testid="ot-data-view-switcher-id"
      className="ot-data-view-switcher"
      key="ot-data-view-switcher"
    >
      {enabledSwitchers}
    </div>
  );
};
