import React, { useCallback, useEffect, useState } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
  useGetDocumentPropertiesQuery,
  useUpdateProjectDocumentPropertiesMutation
} from "@shared/services/documentService";
import { useGetProjectByIdQuery } from "@shared/services/projectService";

import { convertEntitiesToIds } from "@app/helpers/entity";

import DocumentPropertiesModalForm from "@components/molecules/DocumentPropertiesModalForm";

const UpdateProjectDocumentPropertiesModal = props => {
  const dispatch = useDispatch();
  const { document, onUpdated, onCancel } = props;
  const [documentWithProperties, setDocumentWithProperties] =
    useState(document);

  const [
    updateProjectDocumentProperties,
    {
      isSuccess: updatedSuccessfully,
      error: updateError,
      isLoading: updatingDocument
    }
  ] = useUpdateProjectDocumentPropertiesMutation();
  const { data: project, isLoading: isLoadingProject } = useGetProjectByIdQuery(
    { projectId: document.projectId },
    { skip: !document?.projectId, refetchOnMountOrArgChange: true }
  );
  const { data: documentProperties, isLoading: isLoadingProperties } =
    useGetDocumentPropertiesQuery(
      { documentId: document.id },
      { skip: !document.id }
    );
  const { smartFormName, refNo, categoryName } =
    documentProperties?.properties?.source || {};

  const handleUpdate = useCallback(
    ({ name: updatedName, entities: updatedEntities }) => {
      if (updatingDocument) {
        return;
      }
      const originalIds = convertEntitiesToIds(document.properties?.entities);
      const updatedIds = convertEntitiesToIds(updatedEntities);
      const entitiesChanged = !isEqual(originalIds.sort(), updatedIds.sort());
      if (updatedName !== document.name || entitiesChanged)
        updateProjectDocumentProperties({
          document: {
            ...document,
            name: updatedName,
            properties: {
              ...document.properties,
              entities: updatedIds
            }
          }
        });
      else {
        onCancel();
      }
    },
    [document, onCancel, updateProjectDocumentProperties, updatingDocument]
  );

  useEffect(() => {
    if (
      documentProperties?.properties?.entities &&
      !document?.properties?.entities
    ) {
      setDocumentWithProperties({
        ...document,
        properties: {
          ...document?.properties,
          entities: [...documentProperties.properties.entities]
        }
      });
    }
  }, [document, documentProperties]);

  useEffect(() => {
    if (updatedSuccessfully) {
      onUpdated(project);
    }
  }, [dispatch, onUpdated, project, updatedSuccessfully]);

  return (
    <DocumentPropertiesModalForm
      document={documentWithProperties}
      entities={project?.entities}
      onSubmit={handleUpdate}
      onCancel={props.onCancel}
      source={{ smartFormName, refNo, categoryName }}
      error={updateError}
      isLoading={isLoadingProperties || isLoadingProject}
    />
  );
};

UpdateProjectDocumentPropertiesModal.defaultProps = {};

UpdateProjectDocumentPropertiesModal.propTypes = {
  document: PropTypes.any.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default UpdateProjectDocumentPropertiesModal;
