import { useMemo } from "react";

import { useParams } from "react-router-dom";

import {
  useGetClientByIdQuery,
  useGetEngagementByIdQuery,
  useGetProjectByIdQuery
} from "@shared/hooks";

export function useGetClientFromParams() {
  const { engagementId, projectId, clientId: clientIdFromURL } = useParams();

  const { data: engagementsFromQuery, isLoading: isEngagementsLoading } =
    useGetEngagementByIdQuery(engagementId, {
      skip: !engagementId
    });
  const { data: projectFromQuery, isLoading: isProjectLoading } =
    useGetProjectByIdQuery(
      { projectId },
      {
        skip: !projectId
      }
    );

  const clientId = useMemo(
    () =>
      clientIdFromURL ??
      projectFromQuery?.client?.id ??
      engagementsFromQuery?.clientId,
    [
      clientIdFromURL,
      engagementsFromQuery?.clientId,
      projectFromQuery?.client?.id
    ]
  );

  const { data: client, isLoading: isClientLoading } = useGetClientByIdQuery(
    clientId,
    {
      skip: !clientId
    }
  );

  const isLoading = useMemo(
    () =>
      isClientLoading ||
      isEngagementsLoading ||
      isProjectLoading ||
      (clientId && clientId != client?.id), // To not show the prev client when switching between clients
    [isClientLoading, isEngagementsLoading, client, clientId, isProjectLoading]
  );

  return { client: clientId ? client : undefined, isLoading };
}
