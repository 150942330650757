import React, { useCallback, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { menuItems } from "@shared/helpers/menuItems";
import {
  useAuthUser,
  useFeatures,
  useGetClientFromParams,
  useGetMenusQuery
} from "@shared/hooks";
import useAccessControl from "@shared/hooks/useAccessControl";
import { useSecondaryNav } from "@shared/hooks/useSecondaryNav";

import SecondaryNavTemplate from "@components/templates/SecondaryNavTemplate";

const { getSideMenuItems, getCurrentSideNavContext } = menuItems;

const SideMenu = props => {
  const { width, handleNavigate, project } = props;
  const { t } = useTranslation();
  const {
    toggleExpanded,
    setExpanded,
    expandedContext,
    isExpanded,
    getMenuItemExpanded
  } = useSecondaryNav();

  const location = useLocation();
  const { user } = useAuthUser();

  const { client } = useGetClientFromParams();

  const { isEnabled, isInteractiveReportEnabled } = useFeatures();
  const { filterByPermissionFn } = useAccessControl();

  const { projectId } = useParams();
  const { data: interactiveReportMenusData } = useGetMenusQuery(
    {
      projectId: project?.id
    },
    { skip: !project?.id || !isInteractiveReportEnabled }
  );

  const handleClickNavigate = useCallback(
    (path, state) => {
      handleNavigate(path, { state });
    },
    [handleNavigate]
  );

  const currentSideNavContext = useMemo(
    () => getCurrentSideNavContext(location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (!currentSideNavContext) {
      setExpanded({ context: undefined, isExpanded: false });
      return;
    }
    //To expand when the sideMenu content changes
    if (currentSideNavContext !== expandedContext) {
      setExpanded({ context: currentSideNavContext, isExpanded: true });
    }
  }, [currentSideNavContext, expandedContext, setExpanded]);

  const sideNavItems = useMemo(() => {
    return getSideMenuItems({
      user,
      clientId: client?.id,
      project,
      projectId,
      i18nText: t,
      currentSideNavContext,
      interactiveReportMenusData:
        projectId == project?.id ? interactiveReportMenusData : []
    })
      .filter(filterByPermissionFn)
      .filter(
        ({ enabledFeatures }) =>
          !enabledFeatures || enabledFeatures.map(isEnabled).every(Boolean)
      )
      .reduce((acc, item) => {
        const pos = item.position ?? "top";
        if (!acc[pos]) {
          acc[pos] = [];
        }
        acc[pos].push(item);
        return acc;
      }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    client?.id,
    project,
    t,
    currentSideNavContext,
    interactiveReportMenusData,
    isEnabled,
    projectId,
    // To force rerender for interactive report navigation
    window.location.pathname
  ]);

  return (
    <SecondaryNavTemplate
      user={user}
      width={width}
      isExpanded={isExpanded}
      getMenuItemExpanded={getMenuItemExpanded}
      onClickArrow={toggleExpanded}
      onClickNavigate={handleClickNavigate}
      sideNavItems={sideNavItems}
      currentSideNavContext={currentSideNavContext}
      // When switching projects, the project and client props are not updated
      {...(projectId == project?.id ? { project, client } : {})}
    />
  );
};

export default SideMenu;
