import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const cmsService = createApi({
  reducerPath: "cmsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["Cms_Unauthorised", "Cms_Authorised"],
  endpoints: builder => ({
    getUIConfigUnAuthorised: builder.query<unknown, undefined>({
      query: () => `/api/hostBrands/uiConfig/${window.location.host}`,
      providesTags: ["Cms_Unauthorised"]
    }),
    getUIConfigAuthorised: builder.query<unknown, undefined>({
      query: () => `/api/hostBrands/uiConfig/${window.location.host}`,
      providesTags: ["Cms_Authorised"]
    })
  })
});

export const {
  useGetUIConfigUnAuthorisedQuery,
  useGetUIConfigAuthorisedQuery,
  util: cmsUtil
} = cmsService;
