import { useCallback, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";

import { systemConstants } from "@shared/constants";
import usePubSub from "@shared/hooks/usePubSub";
import {
  actionItemServiceUtil,
  useLazyGetActionItemsWithFilterQuery
} from "@shared/services";

export function useProjectQueries({ id: projectId }: { id?: string | number }) {
  const dispatch = useDispatch();
  const [fetchQueriesWithFilter, { data: queries, isLoading, error }] =
    useLazyGetActionItemsWithFilterQuery();
  const didMount = useRef(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refreshQueriesState = usePubSub() as { [key: string]: any };

  useEffect(() => {
    if (!didMount.current) {
      refreshQueriesState.subscribe(
        systemConstants.project.events.projects.refreshProjectQueries
      );
      didMount.current = true;
    }
  }, [refreshQueriesState]);

  useEffect(() => {
    if (projectId) {
      fetchQueriesWithFilter({ projectId }, true);
    }
  }, [fetchQueriesWithFilter, projectId]);

  useEffect(() => {
    if (!projectId || refreshQueriesState.value?.projectId !== +projectId) {
      return;
    }
    dispatch(actionItemServiceUtil.invalidateTags(["Query"]));
    // Added timestamp dependency to prevent infinite loop
  }, [
    dispatch,
    projectId,
    refreshQueriesState.value?.projectId,
    refreshQueriesState.value?.timeStamp
  ]);

  const invalidateQueryTag = useCallback(() => {
    dispatch(actionItemServiceUtil.invalidateTags(["Query"]));
  }, [dispatch]);

  const fetchQueries = useCallback(
    (filters = {}) => {
      if (projectId) {
        fetchQueriesWithFilter(
          {
            projectId,
            filters: Object.keys(filters)?.length ? filters : undefined
          },
          true
        );
      }
    },
    [fetchQueriesWithFilter, projectId]
  );

  return { queries, isLoading, error, fetchQueries, invalidateQueryTag };
}
