import React from "react";

import { useTranslation } from "react-i18next";

import Form from "@components/atoms/Form";

const EntitiesField = props => {
  const { entities, field, queryType } = props;
  const { t } = useTranslation();

  if (!entities?.length) {
    return <></>;
  }

  if (entities.length === 1) {
    return (
      <Form.Multiselect
        key={field.key}
        name="entities"
        required={field.required}
        disabled={field.required}
        label={t(`requests:requests.configured.fields.${field.key}.label`, {
          context: queryType?.key
        })}
        defaultValue={entities}
        items={entities}
      />
    );
  }

  return (
    <Form.Multiselect
      key={field.key}
      name="entities"
      required={field.required}
      label={t(`requests:requests.configured.fields.${field.key}.label`, {
        context: queryType?.key
      })}
      items={entities}
    />
  );
};

export default EntitiesField;
