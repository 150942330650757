import React, { MutableRefObject, useMemo, useRef } from "react";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Box, Inline } from "@fermions";

import { Icon, IconFillStyle, IconSize } from "@atoms/Icon";

import { CheckboxInput } from "@molecules/inputs";

import { FermionProps } from "@components/fermions/fermionsConfig";

import {
  DropdownItemId,
  DropdownItemType,
  DropdownTheme
} from "../DropdownHelper";
import "./DropdownItem.scss";

interface DropdownItemProps extends DropdownItemType {
  id?: DropdownItemId;
  onClick?: FermionProps["onClick"];
  onMouseEnter?: FermionProps["onMouseEnter"];
  onMouseLeave?: FermionProps["onMouseLeave"];
  isSelected?: boolean | "indeterminate";
  isOpen?: boolean;
  withCheckbox?: boolean;
  theme?: DropdownTheme;
  getSubDropdownList?: (
    ref: MutableRefObject<HTMLDivElement | null | undefined>
  ) => React.JSX.Element;
  className?: string;
  tagPill?: React.JSX.Element;
}

const DropdownItem = ({
  id,
  value,
  name,
  icons,
  image,
  subItems = [],
  hasSubItems,
  withCheckbox,
  isSelected,
  isOpen,
  theme = DropdownTheme.LIGHT,
  onClick,
  onMouseEnter,
  onMouseLeave,
  getSubDropdownList,
  isActive,
  isHover,
  isDisabled,
  className,
  tagPill
}: DropdownItemProps) => {
  const selectedClass = useMemo(
    () =>
      returnStringIfTrue(
        isSelected === true || isActive,
        "ot-dropdown-item--selected"
      ),
    [isSelected, isActive]
  );

  const forceHoverClass = useMemo(
    () => returnStringIfTrue(isOpen || isHover, "ot-dropdown-item--hover"),
    [isOpen, isHover]
  );

  const subItemsIcon = useMemo(
    () =>
      subItems?.length > 0 || hasSubItems ? (
        <Icon name="chevron_right" />
      ) : (
        <></>
      ),
    [subItems, hasSubItems]
  );

  const iconsDisplay = useMemo(() => {
    if (!icons?.length) {
      return <></>;
    }

    return icons.map(icon => {
      if (!icon.icon) {
        return <></>;
      }
      const iconColor = (() => {
        if (icon.colorStyle) {
          return icon.colorStyle;
        }
        if (icon.state === "active") {
          return "accent";
        }
        if (icon.state === "light") {
          return "white";
        }
        return "muted";
      })();
      return (
        <Icon
          key={`dropdown-icon-${icon.icon}--${icon.fillStyle}`}
          name={icon.icon}
          color={iconColor}
          fillStyle={icon.fillStyle ?? IconFillStyle.FILLED}
          size={IconSize.S}
        />
      );
    });
  }, [icons]);

  const imageOrIconsDisplay = useMemo(() => {
    if (image) {
      return (
        <img
          className="ot-dropdown-item__image"
          src={image.src}
          alt={image.alt}
        />
      );
    }
    return iconsDisplay;
  }, [iconsDisplay, image]);

  const dropdownItemRef: MutableRefObject<HTMLDivElement | null | undefined> =
    useRef();

  return (
    <Inline
      className={["ot-dropdown-item__container", className]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Inline
        id={`ot-dropdown-item-${id}`}
        className={classNames([
          "ot-dropdown-item",
          `ot-dropdown-item--${theme}`,
          selectedClass,
          forceHoverClass
        ])}
        onClick={onClick}
        ref={dropdownItemRef}
        data-testid={`ot-dropdown-item--${id}`}
        title={name ?? value}
        onInput={() => {}}
      >
        <Inline className="ot-dropdown-item__left" alignment="left">
          {withCheckbox && (
            <CheckboxInput
              value={isSelected ?? false}
              onChange={onClick}
              disabled={isDisabled}
            />
          )}
          <Box className="ot-dropdown-item__label">{name ?? value}</Box>
        </Inline>
        <Inline className="ot-dropdown-item__right">
          {imageOrIconsDisplay}
          {tagPill}
          {subItemsIcon}
        </Inline>
      </Inline>
      {getSubDropdownList?.(dropdownItemRef)}
    </Inline>
  );
};

export default DropdownItem;
