import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";

import { useFixedWithinWindow } from "@shared/hooks/useFixedWithinWindow";

import ions from "@ions";

import { classNames } from "@app/helpers/componentHelpers";

import { Box, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";
import { Pill, PillSize, PillVariant } from "@atoms/Pill";

import SearchBar from "@components/molecules/SearchBar";

import Loading from "../Loading";
import {
  DropdownActionType,
  DropdownCalculationType,
  DropdownItemId,
  DropdownItemType,
  DropdownListParent,
  DropdownTheme,
  Positioning,
  calculateStyle,
  extractDropdownItemId,
  isSomeSubItemSelected,
  sortDropdownItems
} from "./DropdownHelper";
import DropdownItem from "./DropdownItem/DropdownItem";
import "./DropdownList.scss";

export interface DropdownListProps {
  isOpen?: boolean;
  selectedValues?: DropdownItemType[];
  setSelectedValues?: (selectedValues: DropdownItemType[]) => void;
  items?: DropdownItemType[];
  showSearch?: boolean;
  searchPlaceholder?: string;
  isMultiSelect?: boolean;
  className?: string;
  theme?: DropdownTheme;
  parentRef?: DropdownListParent;
  positioning?: Positioning;
  calculation?: DropdownCalculationType;
  minWidthOverride?: number;
  sortComparator?: (a: DropdownItemType, b: DropdownItemType) => number;
  onClickItem?: (item: DropdownItemType) => void;
  onMouseEnterItem?: (item: DropdownItemType) => void;
  onMouseLeaveItem?: (item: DropdownItemType) => void;
  onScroll?: () => void;
  style?: React.CSSProperties;
  isLoading?: boolean;
  actions?: DropdownActionType[];
  showSelectAll?: boolean;
}

export const DropdownList = ({
  isOpen = true,
  selectedValues = [],
  setSelectedValues,
  items = [],
  showSearch = true,
  showSelectAll = true,
  searchPlaceholder,
  isMultiSelect,
  className,
  theme = DropdownTheme.LIGHT,
  parentRef,
  positioning = Positioning.BOTTOM,
  calculation = DropdownCalculationType.FIXED,
  minWidthOverride,
  sortComparator,
  onClickItem,
  onMouseEnterItem,
  onMouseLeaveItem,
  onScroll,
  style,
  isLoading,
  actions = []
}: DropdownListProps) => {
  const { t } = useTranslation();

  const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});
  const [filteredItems, setFilteredItems] = useState(
    sortDropdownItems(items, sortComparator)
  );
  const [searchValue, setSearchValue] = useState("");
  const [openDropdownItemId, setOpenDropdownItemId] =
    useState<DropdownItemId>();

  const dropdownListRef = useRef();

  const calculateDropdownStyle = useCallback(() => {
    setDropdownStyle(
      calculateStyle(
        parentRef,
        positioning,
        minWidthOverride,
        dropdownListRef,
        calculation
      )
    );
  }, [parentRef, positioning, minWidthOverride, calculation]);

  const { styleWithinWindow } = useFixedWithinWindow({
    style: { ...style, ...dropdownStyle },
    elementRef: dropdownListRef,
    windowPadding: 0,
    forceNoCalculate: !isOpen
  });

  useEffect(() => {
    if (isOpen) {
      calculateDropdownStyle();
      window.addEventListener("resize", calculateDropdownStyle);
      window.addEventListener("mousewheel", calculateDropdownStyle);
    } else {
      window.removeEventListener("mousewheel", calculateDropdownStyle);
      window.removeEventListener("resize", calculateDropdownStyle);
    }

    return function () {
      window.removeEventListener("mousewheel", calculateDropdownStyle);
      window.removeEventListener("resize", calculateDropdownStyle);
    };
  }, [calculateDropdownStyle, isOpen]);

  const searchItem = useCallback(
    (item: string) => {
      return item?.toString().toLowerCase().includes(searchValue.toLowerCase());
    },
    [searchValue]
  );

  useEffect(() => {
    if (!searchValue?.length) {
      setFilteredItems(sortDropdownItems(items, sortComparator));
      return;
    }
    setFilteredItems(
      sortDropdownItems(
        items?.filter(
          item =>
            searchItem(String(item.name ?? item.label ?? item.value ?? "")) ||
            (Array.isArray(item.tag)
              ? item.tag.filter((t: string) => t).some(searchItem)
              : searchItem(item.tag)),
          sortComparator
        )
      )
    );
  }, [items, searchItem, searchValue?.length, sortComparator]);

  useEffect(() => {
    if (!isOpen) {
      setSearchValue("");
    }
  }, [isOpen]);

  const isItemSelected = useCallback(
    (item: DropdownItemType) => {
      if (isMultiSelect && items.length === selectedValues.length) {
        return true;
      }
      return isSomeSubItemSelected(item, selectedValues);
    },
    [isMultiSelect, items.length, selectedValues]
  );

  const getSubDropdownList = useCallback(
    (
      item: DropdownItemType,
      subParent: DropdownListParent
    ): React.JSX.Element => {
      if (!item?.subItems?.length || openDropdownItemId !== item.value) {
        return <></>;
      }

      return (
        <DropdownList
          key={`list-${item.id}`}
          items={item.subItems}
          className="dropdown-list--sub"
          theme={theme}
          showSearch={showSearch}
          searchPlaceholder={searchPlaceholder}
          parentRef={subParent}
          positioning={Positioning.RIGHT}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          onClickItem={onClickItem}
          sortComparator={sortComparator}
        />
      );
    },
    [
      openDropdownItemId,
      theme,
      showSearch,
      searchPlaceholder,
      selectedValues,
      setSelectedValues,
      onClickItem,
      sortComparator
    ]
  );

  const handleOnClick = useCallback(
    (item: DropdownItemType) => {
      if (item.subItems?.length || item.hasSubItems || item.isDisabled) {
        return;
      } // no click events on items with subItems
      if (onClickItem) {
        onClickItem?.(item);
        return;
      }
      if (!isMultiSelect) {
        setSelectedValues?.([item]);
      } else {
        const itemIdentifier = extractDropdownItemId(item);
        if (
          selectedValues.some(
            selectedItem =>
              extractDropdownItemId(selectedItem) === itemIdentifier
          )
        ) {
          setSelectedValues?.(
            selectedValues.filter(
              selectedItem =>
                extractDropdownItemId(selectedItem) !== itemIdentifier
            )
          );
        } else {
          setSelectedValues?.([...selectedValues, item]);
        }
      }
      item.onClick?.();
    },
    [isMultiSelect, onClickItem, selectedValues, setSelectedValues]
  );

  const handleMouseEvent = useCallback(
    (item, type: "enter" | "leave") => {
      if (type === "enter" && onMouseEnterItem) {
        onMouseEnterItem?.(item);
        return;
      }

      if (type === "leave" && onMouseLeaveItem) {
        onMouseLeaveItem?.(item);
        return;
      }

      if (item.subItems?.length || item.hasSubItems) {
        setOpenDropdownItemId(
          type === "enter" ? extractDropdownItemId(item) : undefined
        );
      }
      // no mouse enter / leave event on items without subItems
    },
    [onMouseEnterItem, onMouseLeaveItem]
  );

  const handleClickSelectAll = useCallback(() => {
    if (selectedValues?.length === items?.length) {
      setSelectedValues?.([]);
    } else {
      setSelectedValues?.(items);
    }
  }, [selectedValues?.length, items, setSelectedValues]);

  useEffect(() => {
    if (!isMultiSelect && selectedValues?.length > 1) {
      console.warn(
        "DropdownList: Cannot select more than one item when not multiselect"
      );
    } else if (isMultiSelect && theme === DropdownTheme.DARK) {
      console.warn("DropdownList: Cannot use dark theme with multiselect");
    }
  }, [isMultiSelect, selectedValues, theme]);

  const pillStyle = useMemo(() => {
    const dropdownIons =
      ions.components?.molecules?.inputs?.dropdown?.[`context_${theme}`];
    const variant = dropdownIons?.pill_variant;
    const fillStyle = dropdownIons?.pill_fill_style;

    return {
      variant,
      fillStyle
    };
  }, [theme]);

  const getTagPill = useCallback(
    (item): PillVariant => {
      if (!item.tag) {
        return <></>;
      }
      if (Array.isArray(item.tag)) {
        return (
          <>
            {item.tag
              .filter((t: string) => t)
              .map((tag: string) => (
                <Pill
                  {...pillStyle}
                  label={tag}
                  size={PillSize.SMALL}
                  key={tag}
                />
              ))}
          </>
        );
      }
      return (
        <Pill
          {...pillStyle}
          label={item.tag.toUpperCase()}
          size={PillSize.SMALL}
        />
      );
    },
    [pillStyle]
  );

  if (
    !isOpen ||
    (!items.length && !actions?.length) ||
    (process.env.NODE_ENV !== "test" &&
      calculation === DropdownCalculationType.FIXED &&
      parentRef?.current &&
      !styleWithinWindow?.position)
  ) {
    return <></>;
  }

  const renderSelectAll = () => {
    if (!showSelectAll) {
      return <></>;
    }
    if (isMultiSelect && !items.some(i => i.isDisabled)) {
      return (
        <DropdownItem
          key="select-all"
          name={t("common:ui.forms.multiselect.selectAll")}
          value={-1}
          withCheckbox={isMultiSelect}
          isSelected={
            selectedValues?.length > 0 && selectedValues?.length < items?.length
              ? "indeterminate"
              : selectedValues?.length === items?.length
          }
          theme={theme}
          onClick={handleClickSelectAll}
        />
      );
    }
  };

  return (
    <Stack
      className={classNames([
        "dropdown-list",
        `dropdown-list--${theme}`,
        className ?? ""
      ])}
      ref={dropdownListRef}
      style={styleWithinWindow}
      data-testid="test-dropdown-list"
    >
      {showSearch && (
        <SearchBar
          key="dropdown-list-search"
          value={searchValue}
          onChange={setSearchValue}
          border="bottom"
          type={theme}
          placeholder={searchPlaceholder}
        />
      )}
      {isLoading ? (
        <Box alignment="left" padding="200">
          <Loading type="inherit" />
        </Box>
      ) : (
        <Stack
          className="dropdown-list__items"
          onScroll={onScroll}
          key="dropdown-list-items"
        >
          {renderSelectAll()}
          {filteredItems.map(item => {
            if (item.subItems && item.subItems?.length > 0 && isMultiSelect) {
              console.warn("DropdownList: Cannot have subItems in multiselect");
            }
            const itemId = extractDropdownItemId(item);
            return (
              <DropdownItem
                {...item}
                id={itemId}
                key={`dropdown-item-${itemId}`}
                isDisabled={item.isDisabled}
                withCheckbox={isMultiSelect}
                isSelected={isItemSelected(item)}
                isOpen={openDropdownItemId === itemId || item.isActive}
                theme={theme}
                onClick={event => {
                  event?.stopPropagation?.();
                  handleOnClick(item);
                }}
                onMouseEnter={() => handleMouseEvent(item, "enter")}
                onMouseLeave={() => handleMouseEvent(item, "leave")}
                getSubDropdownList={ref => getSubDropdownList(item, ref)}
                tagPill={getTagPill(item)}
              />
            );
          })}
        </Stack>
      )}
      {actions?.map(action => {
        return (
          <Box
            className={["dropdown-action", `dropdown-action--${theme}`]}
            key={`dropdown-action-${action.key}`}
            onClick={action.onClick}
            width="100"
            alignment="left"
            onMouseEnter={() => {
              handleMouseEvent(action, "enter");
              setOpenDropdownItemId(undefined);
            }}
            onMouseLeave={() => handleMouseEvent(action, "leave")}
          >
            <Button
              label={action.name}
              iconName={action.iconName}
              variant={
                theme === DropdownTheme.LIGHT
                  ? ButtonVariant.TEXT_PRIMARY
                  : ButtonVariant.TEXT_INVERT
              }
            />
          </Box>
        );
      })}
    </Stack>
  );
};
