import React from "react";

import { useTranslation } from "react-i18next";

import "./Loading.scss";

const Loading = ({
  message,
  type = "primary",
  minHeight
}: {
  message?: string;
  type?: "primary" | "secondary" | "inherit";
  minHeight?: string;
}) => {
  const { t } = useTranslation();
  const inlineStyle = {};
  if (minHeight) {
    inlineStyle.style = {
      minHeight: minHeight
    };
  }
  return (
    <div className="loading" {...inlineStyle}>
      {/* TODO: add back spinner */}
      {/* <div className="loading__container">
        <div className="loader" />
      </div> */}
      {/* TODO: remove message always there when spinner is back and working */}
      {/* {message && ( */}
      <h2 className={`loading__text loading__text--${type}`}>
        {/* TODO: remove default when spinner is back and working */}
        {message ?? t("common:loading")}
      </h2>
      {/* )} */}
    </div>
  );
};

export default Loading;
