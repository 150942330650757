import React, { useCallback, useEffect, useState } from "react";

import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { formattedCodes } from "@shared/helpers";

import { Stack } from "@fermions";

import { FontColor, FontSize, Text } from "@atoms/Text";

import { MessageBox, MessageBoxType } from "@molecules/MessageBox";

import Form from "@components/atoms/Form";

const UserFormContents = props => {
  const { formConfig, user, isExternalUsersEnabled, isHostUserUpdated } = props;
  const initialUserEntityRestrictedStatus = user?.role?.isEntityRestricted;
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const selectedRoleItem = watch("role");
  const selectedProjects = watch("projects");
  const [
    isUserEntityRestrictedStatusChanged,
    setIsUserEntityRestrictedStatusChanged
  ] = useState(false);
  const [roleEntityRestrictedMessage, setRoleEntityRestrictedMessage] =
    useState("");
  const [projectMessage, setProjectMessage] = useState("");

  const projectMessageConstructor = projects => {
    if (!projects || projects.length === 0) {
      return "";
    }
    const key = "common:ui.user.field.projectMessage";
    return t(key, {
      projects: projects
        ?.map((project: { name: string }) => project.name)
        .join(", "),
      interpolation: { escapeValue: false }
    });
  };

  useEffect(() => {
    if (selectedProjects) {
      const newEntitiesEnabledProjects = selectedProjects.filter(
        project => !project.added && project.isEntitiesEnabled
      );
      if (
        selectedRoleItem?.isEntityRestricted &&
        newEntitiesEnabledProjects.length
      ) {
        const message = projectMessageConstructor(newEntitiesEnabledProjects);
        setProjectMessage(message);
      } else {
        setProjectMessage("");
      }
    }
  }, [selectedProjects, selectedRoleItem?.isEntityRestricted]);

  useEffect(() => {
    if (!user?.externalUser && isExternalUsersEnabled && isHostUserUpdated) {
      setValue("externalUser.username", "");
    }
  }, [user?.externalUser]);

  useEffect(() => {
    if (user?.hostId || initialUserEntityRestrictedStatus === undefined) {
      return;
    }
    if (
      selectedRoleItem?.isEntityRestricted !== initialUserEntityRestrictedStatus
    ) {
      setIsUserEntityRestrictedStatusChanged(true);
      initialUserEntityRestrictedStatus
        ? setRoleEntityRestrictedMessage(
            t("common:user.configured.fields.role.unrestrictedMessage")
          )
        : setRoleEntityRestrictedMessage(
            t("common:user.configured.fields.role.restrictedMessage")
          );
    } else {
      setIsUserEntityRestrictedStatusChanged(false);
      setRoleEntityRestrictedMessage("");
    }
  }, [t, selectedRoleItem]);

  const renderForm = useCallback(
    field => {
      const label =
        field.key && t(`common:user.configured.fields.${field.key}.label`);
      switch (field?.type) {
        case "text":
        case "number":
          return (
            <Form.TextField
              key={field.key}
              name={field.path ?? field.key}
              label={label}
              required={field.required}
              disabled={field.disabled ?? false}
              defaultValue={get(user, field.path ?? field.key) ?? ""}
            />
          );
        case "code":
          const value =
            get(user, field.key) ?? systemConstants.defaultCountryCode;
          return (
            <Form.Dropdown
              defaultValue={{
                id: 0,
                name: value,
                code: value
              }}
              key={field.key}
              name={field.key}
              label={label}
              required={field.required}
              disabled={field.disabled ?? false}
              items={formattedCodes.filter(
                codeObject => codeObject.code === value
              )}
              transformSelected={value => value.code}
            />
          );
        case "dropdown":
          const userValue = get(user, field.path ?? field.key);
          const defaultValue = field.items?.find(
            item => item.value === userValue
          );
          return (
            <Stack gap="050" contentsWidth="100" width="100">
              <Form.Dropdown
                key={field.key}
                name={field.key}
                label={label}
                required={field.required}
                defaultValue={defaultValue}
                disabled={field.disabled ?? false}
                items={field.items}
              />
              {field.key === "role" && selectedRoleItem?.isEntityRestricted && (
                <Text
                  text={t("common:user.configured.fields.role.explanation")}
                  color={FontColor.TERTIARY}
                  size={FontSize.M}
                />
              )}
              {isUserEntityRestrictedStatusChanged && (
                <MessageBox
                  type={MessageBoxType.WARNING}
                  message={roleEntityRestrictedMessage}
                />
              )}
            </Stack>
          );
        case "radio":
          const items = field.items.map(item => ({
            value: item,
            label: t(`common:user.configured.fields.${field.key}.${item}.label`)
          }));
          return (
            <Form.RadioGroup
              defaultValue={get(user, field.path, field.defaultValue)}
              name={field.path ?? field.key}
              label={
                field.key &&
                t(`common:user.configured.fields.${field.key}.label`)
              }
              required={false}
              disabled={false}
              items={items}
            />
          );
        case "multiselect":
          return (
            <Stack gap="050" contentsWidth="100" width="100">
              <Form.Multiselect
                label={label}
                key={field.key}
                name={field.key}
                required={field.required}
                disabled={field.disabled ?? false}
                items={field.items ?? []}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
              />
              {field.key === "projects" && !!projectMessage && (
                <Text
                  text={projectMessage}
                  color={FontColor.TERTIARY}
                  size={FontSize.M}
                />
              )}
            </Stack>
          );
        default:
          return <></>;
      }
    },
    [
      t,
      user,
      selectedRoleItem,
      isUserEntityRestrictedStatusChanged,
      roleEntityRestrictedMessage,
      projectMessage
    ]
  );

  return (
    <Stack gap="300" width="100">
      {formConfig.map(field => {
        if (field.type === "custom") {
          return (
            <React.Fragment key={field.key}>
              {field.showLabel && (
                <div className="user-form__label">
                  {field.key &&
                    t(`common:user.configured.fields.${field.key}.label`)}
                </div>
              )}
              <div className="user-form__inline" key={field.key}>
                {field.fields?.map(renderForm)}
              </div>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={field.key}>{renderForm(field)}</React.Fragment>
        );
      })}
    </Stack>
  );
};

export default UserFormContents;
