import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";

export function useMenuActions({ menuItems, onMenuItemClick }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const dropDownRef = useRef();
  const { t } = useTranslation();
  const menuItemsTransformed = useMemo(
    () =>
      menuItems?.map((item, index) => ({
        ...item,
        value: item?.value ?? item?.id ?? item?.name ?? item?.key ?? index,
        name: t(item.label ?? item.name)
      })),
    [menuItems, t]
  );

  const menuItemClickHandler = useCallback(
    menuItem => {
      setIsOpen(false);
      onMenuItemClick?.(menuItem);
    },
    [onMenuItemClick]
  );

  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      setIsOpen(current => !current);
    },
    [setIsOpen]
  );

  // Hide the menu when button menu is not visible (i.e. when it is scrolled out of view)
  useEffect(() => {
    const current = ref.current;

    if (!current) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        if (!entries[0].isIntersecting) {
          // hide the floating menu
          setIsOpen(false);
        }
      },
      {
        root: null,
        threshold: 0.5,
        rootMargin: "0px"
      }
    );

    observer.observe(current);
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [ref.current]);

  useOnClickOutside(ref, event => {
    event.stopPropagation();
    setIsOpen(false);
  });

  return {
    menuItemsTransformed,
    menuItemClickHandler,
    handleClick,
    isOpen,
    setIsOpen,
    ref,
    dropDownRef
  };
}
