import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@atoms/Button";
import { Icon, IconSize } from "@atoms/Icon";
import { FontSize, Text } from "@atoms/Text";

import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./NotFound.scss";

const NotFound = () => {
  const { t } = useTranslation();
  const content = {
    errorCode: t("ui.notFoundPage.errorCode"),
    errorDescription: t("ui.notFoundPage.errorDescription"),
    description: t("ui.notFoundPage.description"),
    buttonLabel: t("ui.notFoundPage.buttonLabel")
  };
  const navigate = useNavigate();
  const previousPath = JSON.parse(sessionStorage.getItem("previousPath"));

  const { pathname } =
    previousPath?.baseURL === window.location.hostname
      ? previousPath
      : { pathname: "/" };

  const goBack = () => {
    navigate(pathname);
    sessionStorage.removeItem("lastPageWas404");
  };

  useEffect(() => {
    sessionStorage.setItem("lastPageWas404", "true");
  }, []);

  const body = () => (
    <section className="notFound__main">
      <Icon
        className="notFound__main__icon"
        name="sentiment_dissatisfied"
        size={IconSize.INHERIT}
      />
      <Text text={content.errorCode} size={FontSize.X4L} />
      <Text text={content.errorDescription} size={FontSize.X2L} />
      <Text text={content.description} />
      <Button label={content.buttonLabel} size={IconSize.XS} onClick={goBack} />
    </section>
  );

  return (
    <PageTemplate
      header={{ title: content.errorDescription, sticky: true }}
      body={{ primary: body() }}
      skipSavePath={true}
    ></PageTemplate>
  );
};

export default NotFound;
