import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { menuItems } from "@shared/helpers/menuItems";
import {
  useActionItemFilters,
  useAuthUser,
  useCurrentProject,
  useFeatures,
  useGetClientByIdQuery,
  useGetClientsForMenuQuery,
  useLogout
} from "@shared/hooks";
import useAccessControl from "@shared/hooks/useAccessControl";
import { useGetClientProjectsForMenuQuery } from "@shared/services/clientProjectsService";

import { useAdminNavItems, useDefaultLandingPageLink } from "@app/hooks";
import { useAppNavItems } from "@app/hooks/useAppNavItems.tsx";

import TopNavigationTemplate from "@components/templates/TopNavigationTemplate";

const { getTopMenuItems, getProfileMenuItems } = menuItems;
const Heading = props => {
  const { subNavLeftPosition, setSubNavActive, handleNavigate, isolationMode } =
    props;
  const { isEnabled } = useFeatures();
  const { t } = useTranslation();
  const location = useLocation();
  const { user, userProfile } = useAuthUser();

  const { data: clientsForMenu, isLoading: isClientsLoading } =
    useGetClientsForMenuQuery(null, {
      skip: !user.isHostUser
    });
  const { data: projectsForMenu } = useGetClientProjectsForMenuQuery(
    user.clientId,
    { skip: user.isHostUser }
  );
  const { logout } = useLogout();
  const [landingPageLink, setLandingPageLink] = useState("");
  const { currentProject } = useCurrentProject();
  const { adminNavItems } = useAdminNavItems();
  const { appNavItems } = useAppNavItems();
  const { filterByPermissionFn } = useAccessControl();

  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();

  useGetClientByIdQuery(currentProject?.client?.id, {
    skip: !currentProject?.client
  });

  const { onChangeProject } = useActionItemFilters();

  useEffect(() => {
    onChangeProject(currentProject?.id);
  }, [currentProject, onChangeProject]);

  useEffect(() => {
    if (user) {
      setLandingPageLink(getDefaultLandingPageLink(user));
    }
  }, [user, getDefaultLandingPageLink]);

  const handleSelectProjectFilter = useCallback(
    project => {
      onChangeProject();
      handleNavigate(`/projects/${project.id}`);
    },
    [handleNavigate, onChangeProject]
  );

  const navItems = useMemo(() => {
    return getTopMenuItems({
      user,
      projectsForMenu,
      clientsForMenu,
      isClientsLoading,
      i18nText: t
    })
      .filter(filterByPermissionFn)
      .filter(
        ({ enabledFeatures }) =>
          !enabledFeatures || enabledFeatures.map(isEnabled).every(Boolean)
      );
  }, [
    user,
    projectsForMenu,
    clientsForMenu,
    isClientsLoading,
    t,
    filterByPermissionFn,
    isEnabled
  ]);

  return (
    <TopNavigationTemplate
      landingPageLink={landingPageLink}
      handleSelectFilter={handleSelectProjectFilter}
      menuItems={getProfileMenuItems({ handleLogout: logout, i18nText: t })}
      navItems={navItems}
      adminNavItems={adminNavItems}
      user={userProfile}
      isolationMode={isolationMode}
      subNavLeftPosition={subNavLeftPosition}
      setSubNavActive={setSubNavActive}
      handleNavigate={handleNavigate}
      currentPath={location.pathname}
      appNavItems={appNavItems}
    />
  );
};

Heading.defaultProps = {
  isolationMode: false
};

Heading.propTypes = {
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleNavigate: PropTypes.func,
  isolationMode: PropTypes.bool,
  sideNavigation: PropTypes.object
};

export default Heading;
