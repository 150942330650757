import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { useUpdateProjectDocumentMutation } from "@shared/services/projectDocumentService";

import Form from "@components/atoms/Form/Form";

import ModalForm from "../../molecules/ModalForm/ModalForm";
import "./EditDocumentTags.scss";

const EditDocumentTags = props => {
  const { document, t, availableTags } = props;
  const [multiselectTags, setMultiselectTags] = useState([]);
  const [updateProjectDocument] = useUpdateProjectDocumentMutation();

  useEffect(() => {
    if (availableTags) {
      const tagsForMultiselect = availableTags.map(tag => ({
        name: tag.name,
        value: tag.id
      }));
      setMultiselectTags(tagsForMultiselect);
    }
  }, [availableTags]);

  const handleUpdateTags = ({ tags: updatedTags = [] }) => {
    const updatedTagIds = new Set(updatedTags.map(t => t.value));
    const documentTagIds = new Set(document.tags.map(t => t.id));
    const tagsRemoved = [...documentTagIds].filter(x => !updatedTagIds.has(x));
    const tagsAdded = [...updatedTagIds].filter(x => !documentTagIds.has(x));

    updateProjectDocument({
      documentId: document.id,
      doc: {
        ...document,
        tagActions: {
          add: tagsAdded,
          remove: tagsRemoved
        }
      }
    });
    props.onUpdate();
  };

  const initialTags = useMemo(() => {
    if (!document?.tags?.length) {
      return [];
    }

    return document.tags.map(t => ({ id: t.id, name: t.name, value: t.id }));
  }, [document]);

  return (
    <ModalForm
      boxClassName="document-add-tags"
      title={t("common:ui.editTags.title")}
      handleCancel={props.onCancel}
      handleSubmit={handleUpdateTags}
      submitLabel={t("common:ui.forms.update.label")}
      defaultValues={{
        tags: initialTags
      }}
    >
      <Form.Multiselect
        name="tags"
        label={t("stringFormat.capitalize", {
          key: "tags_other"
        })}
        items={multiselectTags}
      />
    </ModalForm>
  );
};

EditDocumentTags.defaultProps = {};

EditDocumentTags.propTypes = {
  document: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default withTranslation()(EditDocumentTags);
