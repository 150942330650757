import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useTooltip } from "@shared/hooks/useTooltip";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Inline, Stack } from "@fermions";

import {
  Icon,
  IconColor,
  IconDesignStyle,
  IconFillStyle,
  IconSize
} from "@atoms/Icon";

import OTTooltip from "../OTTooltip";
import "./Attachment.scss";

export enum AttachmentType {
  WEBSHEET = "websheet",
  DOCUMENT = "document"
}

interface AttachmentProps {
  type: AttachmentType;
  onClick: () => void;
  file: { name: string; locked?: boolean };
  action?: (file: { name: string }) => void;
  disabled?: boolean;
}

export const Attachment = ({
  type,
  onClick,
  file,
  action,
  disabled
}: AttachmentProps) => {
  const { t } = useTranslation();

  const { showTooltip, elementProps } = useTooltip();
  const label = file.name ?? "";

  const attachmentTypeIcon = useMemo(() => {
    let icon = { name: "view_module", fillStyle: IconFillStyle.OUTLINED };
    if (type === AttachmentType.DOCUMENT) {
      icon = { name: "description", fillStyle: IconFillStyle.OUTLINED };
    }

    return (
      <Icon
        {...icon}
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        fillStyle={IconFillStyle.OUTLINED}
        color={IconColor.INHERIT}
        size={IconSize.S}
      />
    );
  }, [type]);

  const attachmentActionIcon = useMemo(() => {
    let icon = { name: "open_in_new", fillStyle: IconFillStyle.OUTLINED };
    if (type === AttachmentType.DOCUMENT) {
      icon = { name: "download", fillStyle: IconFillStyle.FILLED };
    }
    if (disabled) {
      icon = { name: "lock", fillStyle: IconFillStyle.OUTLINED };
    }

    if (file?.locked) {
      return <></>;
    }

    return (
      <Icon
        {...icon}
        designStyle={IconDesignStyle.MATERIAL_ICONS}
        className={classNames([
          returnStringIfTrue(disabled, "attachment__action-icon--locked"),
          returnStringIfTrue(!disabled, "attachment__action-icon")
        ])}
        color={IconColor.INHERIT}
        hoverElement={
          disabled
            ? t(
                "common:ui.projectSummary.messageNotAllEntitiesAccessToDocument"
              )
            : undefined
        }
      />
    );
  }, [type, disabled, file?.locked, t]);

  return (
    <Stack className="attachment__container" gap="000">
      <Inline
        className={[
          "attachment",
          returnStringIfTrue(disabled, "attachment--disabled")
        ]}
        alignment="center"
        {...(!disabled ? { onClick } : {})}
      >
        {attachmentTypeIcon}
        <div className="attachment__label" {...elementProps}>
          {label}
        </div>
        {attachmentActionIcon}
        {showTooltip && <OTTooltip visible={showTooltip} content={label} />}
      </Inline>
      {action && action(file)}
    </Stack>
  );
};
