import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { classNames } from "@app/helpers/componentHelpers";

import { InputLabel } from "@components/molecules/inputs/InputTemplate";

import "./OTTextInput.scss";

// TODO: remove - replace with TextInput from @molecules/inputs

const TextInput = React.forwardRef((props, fwdRef) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const getErrorClass = () => {
    return error ? "error" : "";
  };

  const getDisabledClass = () => {
    return props.disabled ? "disabled" : "";
  };

  const getError = () => {
    if (error && error.length > 0) {
      return (
        <div className="ot-textinput-group__errmsg">
          <i className="ot-textinput-group__errmsg-icon material-icons">
            error
          </i>
          {error}
        </div>
      );
    }
    return <></>;
  };

  const handleChange = event => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleBlur = event => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleFocus = event => {
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  };

  const getDefaultMaxLength = () => {
    switch (props.type) {
      case "password":
        return 1024;
      case "text":
        return 255;
      default:
        return 524288;
    }
  };

  const getThemeClass = () => {
    return `ot-textinput-group--theme-ot`;
  };

  return (
    <div
      className={classNames(["ot-textinput-group", getThemeClass()])}
      style={props.style || {}}
    >
      {props.label && (
        <InputLabel
          label={props.label}
          required={props.required}
          disabled={props.disabled}
          description={props.labelDescription}
          hideLabel={!props.visible}
        />
      )}
      <input
        aria-label={props.label}
        className={classNames([
          "ot-textinput-group__textinput",
          getErrorClass(),
          getDisabledClass()
        ])}
        disabled={props.disabled}
        value={props.value || ""}
        maxLength={props.maxLength || getDefaultMaxLength()}
        pattern={props.pattern}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={fwdRef}
        autoFocus={props.autoFocus}
        {...(!props.visible ? { type: "hidden" } : {})}
        {...(props.datalist?.list ? { list: props.datalist.name } : {})}
      />
      {props.datalist?.list && (
        <datalist id={props.datalist.name}>
          {props.datalist.list.map((v, i) => (
            <option key={`${props.datalist.name}-option-${i}`} value={v} />
          ))}
        </datalist>
      )}
      {getError()}
    </div>
  );
});

TextInput.defaultProps = {
  visible: true
};

TextInput.propTypes = {
  label: PropTypes.string,
  labelDescription: PropTypes.string,
  value: PropTypes.string.isRequired,
  datalist: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string)
  }),
  error: PropTypes.string,
  required: PropTypes.bool,
  forceLabelFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  visible: PropTypes.bool,
  style: PropTypes.object
};

export default TextInput;
