import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { dateAndTimeFormatter } from "@shared/helpers/dateHelper";
import { useGetHostObject, useLocaleDate } from "@shared/hooks";

import { useDataTable } from "@app/hooks/useDataTable";
import { RoleActionFn, RoleTableData } from "@app/types";

import { Pill, PillSize } from "@atoms/Pill";

import TextToolTip from "@molecules/TextToolTip";

import DataTable from "@components/molecules/DataTable";

type Props = {
  data: RoleTableData;
  handleActionClick: RoleActionFn;
};

const RolesTable = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hostObject } = useGetHostObject();
  const { data, handleActionClick } = props;

  const { createColumn, caseInsensitiveSortType, createColumnForDropdownMenu } =
    useDataTable();
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();

  const handleDropdownItemClick = useCallback(
    ({ menuItem, cell }) =>
      handleActionClick({
        menuItem,
        roleId: cell.row.original.id
      }),
    [handleActionClick]
  );

  const renderDescriptionCol = useCallback(
    ({
      cell
    }: {
      cell: {
        value: string;
        row: {
          id: number;
        };
      };
    }) => {
      return (
        <TextToolTip
          text={cell.value}
          className={`data-table-cell-${cell.row.id}-description`}
          maxLines={3}
          truncate={true}
        />
      );
    },
    []
  );

  const handleRowClick = data => navigate(`/admin/roles/${data}`);

  const columns = useMemo(() => {
    const timeZone = hostObject?.host.properties.timeZone;

    const formatDateTime = (date: string) =>
      dateAndTimeFormatter(date, locale, shortFormat, timeZone) ?? "";

    return [
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.roleName.label"),
        accessor: "name",
        freeze: {
          position: "left",
          isEdge: false
        },
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.type.label"),
        accessor: "type",
        disableSortBy: false,
        Cell: ({ value }: { value: string }) => (
          <Pill
            label={t("roles:role.type", { context: value })}
            size={PillSize.SMALL}
          />
        ),
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.description.label"),
        accessor: "description",
        disableSortBy: true,
        Cell: renderDescriptionCol
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.createdBy.label"),
        accessor: "createdBy",
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.lastUpdated.label"),
        accessor: "lastUpdated",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
        disableSortBy: false
      }),
      createColumnForDropdownMenu({
        accessor: "roleActions",
        onClickHandler: handleDropdownItemClick,
        className: "visible-on-hover"
      })
    ];
  }, [
    caseInsensitiveSortType,
    createColumn,
    createColumnForDropdownMenu,
    handleDropdownItemClick,
    hostObject?.host.properties.timeZone,
    locale,
    shortFormat,
    t
  ]);

  return (
    <DataTable columns={columns} onRowClick={handleRowClick} data={data} />
  );
};

export default RolesTable;
