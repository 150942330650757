import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { systemConstants } from "@shared/constants";
import { safeUtcDate } from "@shared/helpers/dateFormatUtils";
import { addOneYear } from "@shared/helpers/dateHelper";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import { clientProjectsService } from "@shared/services/clientProjectsService";
import { useCopyProjectMutation } from "@shared/services/projectService";

import { getErrorMessage } from "@app/helpers/error";

import { Inline, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";
import { Text } from "@atoms/Text";

import { MessageBox, MessageBoxType } from "@molecules/MessageBox";

import Form from "@components/atoms/Form";
import SelectProjectYearField from "@components/molecules/SelectProjectYearField";
import FormPageTemplate from "@components/templates/FormPageTemplate";

const CopyProjectForm = ({ project, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const { startDate, plannedCompletionDate, configuration } = project ?? {};
  const entitiesEnabled = configuration?.entities?.enabled ?? false;
  const projectYearEnabled =
    configuration?.project?.yearField?.enabled ?? false;
  const [copyProject, { data: copiedProject, error, isSuccess }] =
    useCopyProjectMutation();
  const [copiedStartDate, setCopiedStartDate] = useState();
  const [copiedEndDate, setCopiedEndDate] = useState();
  const dispatch = useDispatch();

  const getCopyItems = useCallback(() => {
    const copyItems = [
      {
        value: "milestones",
        name: t("stringFormat.capitalize", {
          key: "common:milestone_other"
        })
      },
      {
        value: "tags",
        name: t("stringFormat.capitalize", {
          key: "common:tags_other"
        })
      },
      {
        value: "users",
        name: t("stringFormat.capitalize", {
          key: "common:users"
        })
      }
    ];
    if (entitiesEnabled) {
      copyItems.push({
        value: "entities",
        name: t("stringFormat.capitalize", {
          key: "common:entities_other"
        }),
        disabled: true
      });
    }
    return copyItems;
  }, [entitiesEnabled, t]);

  useEffect(() => {
    if (isSuccess && copiedProject?.id) {
      dispatch(
        clientProjectsService.util.invalidateTags(["ClientProjectForMenu"])
      );
      navigate(`${routeConstants.projects}/${copiedProject?.id}`);
    }
  }, [copiedProject?.id, isSuccess, navigate]);

  const handleSubmit = e => {
    const {
      name,
      entities,
      year,
      plannedCompletionDate,
      startDate,
      milestones,
      tags,
      users,
      saveAsDraft
    } = e;
    const copyItems = {
      milestones,
      tags,
      users,
      entities
    };
    const newProject = {
      name,
      plannedCompletionDate: safeUtcDate(plannedCompletionDate),
      startDate: safeUtcDate(startDate),
      engagementId: project.engagementId,
      year: year?.value,
      status: saveAsDraft ? systemConstants.project.status.draft : undefined
    };

    const formattedCopyFields = Object.entries(copyItems).reduce(
      (acc, [key, value]) => {
        if (value === true) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const payload = {
      project: newProject,
      copyFields: formattedCopyFields,
      prevProjectId: project.id
    };
    copyProject({ payload });
  };

  const initialYears = useMemo(() => {
    return {
      start: addOneYear(startDate),
      end: addOneYear(plannedCompletionDate)
    };
  }, [plannedCompletionDate, startDate]);

  const renderStartDate = useMemo(() => {
    if (!initialYears.start) {
      return;
    }
    return (
      <Form.DateField
        name={"startDate"}
        required={true}
        label={milestoneUtilities.renderStartLabel(configuration, t)}
        defaultValue={initialYears.start}
        maxDate={copiedEndDate}
        onChangeDate={setCopiedStartDate}
      />
    );
  }, [configuration, copiedEndDate, initialYears.start, t]);

  const renderEndDate = useMemo(() => {
    if (!initialYears.end) {
      return;
    }
    return (
      <Form.DateField
        name={"plannedCompletionDate"}
        required={true}
        label={milestoneUtilities.renderEndLabel(configuration, t)}
        defaultValue={initialYears.end}
        minDate={
          copiedStartDate ? new Date(copiedStartDate) : addOneYear(startDate)
        }
        onChangeDate={setCopiedEndDate}
      />
    );
  }, [configuration, copiedStartDate, initialYears.end, startDate, t]);

  const renderCheckboxes = useMemo(() => {
    return (
      <Stack gap="100" width="100" contentsWidth="100">
        <Text text={t("common:ui.project.copyFields.copyItems")} />
        {getCopyItems().map(item => (
          <Form.Checkbox
            key={item.value}
            name={item.value}
            label={item.name}
            defaultValue={true}
            disabled={item.disabled}
          />
        ))}
      </Stack>
    );
  }, [getCopyItems]);

  const yupSchema = useMemo(() => {
    const yupShape = {
      name: yup.string().required(t("common:ui.projects.name.error")),
      startDate: yup
        .string()
        .nullable()
        .required(t("common:ui.projects.startDate.error")),
      plannedCompletionDate: yup
        .string()
        .nullable()
        .required(t("common:ui.projects.endDate.error.defined"))
    };

    if (projectYearEnabled) {
      yupShape.year = yup
        .object()
        .shape({
          name: yup.string().required(),
          value: yup.string().required()
        })
        .required(t("common:ui.projects.year.error"));
    }

    return yup.object().shape(yupShape);
  }, [projectYearEnabled, t]);

  const errorMessage = useMemo(() => getErrorMessage(error, t), [error, t]);

  const formContents = useMemo(
    () => (
      <Stack gap="300" style={{ position: "relative" }}>
        {errorMessage && <ErrorBox message={errorMessage} />}
        <Inline gap="300" alignment="center" wrap>
          <Form.TextField
            required={true}
            label={t("common:ui.projects.name.label")}
            name={"name"}
            style={{ flex: 2 }}
          ></Form.TextField>
          <Form.Checkbox
            name="saveAsDraft"
            label={t("common:ui.projects.saveAsDraft.label")}
            defaultValue={false}
            style={{ flex: 1 }}
          />
        </Inline>
        {projectYearEnabled && (
          <SelectProjectYearField
            name="year"
            label={t("common:ui.projects.year.label")}
            required={true}
            defaultValue={project?.year ?? currentYear}
          />
        )}
        {renderStartDate}
        {renderEndDate}
        {configuration !== undefined && <>{renderCheckboxes}</>}
      </Stack>
    ),
    [
      configuration,
      currentYear,
      entitiesEnabled,
      errorMessage,
      getCopyItems,
      project?.year,
      projectYearEnabled,
      renderEndDate,
      renderStartDate,
      t
    ]
  );

  const renderPageActions = () => {
    return (
      <>
        <MessageBox
          type={MessageBoxType.INFO}
          message={t("common:ui.project.copy.info")}
        />
        <Button
          variant={ButtonVariant.TEXT}
          label={t("common:ui.forms.cancel.label")}
          onClick={() => navigate(routeConstants.clientDashboard)}
        />
        <Button
          type="submit"
          variant={ButtonVariant.PRIMARY}
          label={t("common:ui.forms.submit.label")}
        />
      </>
    );
  };

  return (
    <FormPageTemplate
      title={t("common:ui.project.title_copy")}
      sticky
      form={{
        handleSubmit,
        yupSchema,
        contents: formContents,
        renderPageActions
      }}
      other={{ project }}
      isLoading={isLoading}
    />
  );
};

CopyProjectForm.propTypes = {
  isLoading: PropTypes.bool,
  project: PropTypes.object
};

export default CopyProjectForm;
