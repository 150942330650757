import { manageQueryResponsesConstants } from "../constants";

const initialState = {
  loading: false,
  queryResponses: {},
  addedQueryResponse: {},
  isAdded: false,
  isUpdated: false,
  error: ""
};

export function manageQueryResponses(state = initialState, action = {}) {
  switch (action.type) {
    case manageQueryResponsesConstants.GET_QUERY_RESPONSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageQueryResponsesConstants.GET_QUERY_RESPONSES_SUCCESS:
      return {
        ...state,
        loading: false,
        queryResponses: {
          ...state.queryResponses,
          [action.queryId]: action.queryResponses
        },
        error: ""
      };
    case manageQueryResponsesConstants.GET_QUERY_RESPONSES_FAILURE:
      return {
        ...state,
        loading: false,
        queryResponses: [],
        error: action.error.key ?? action.error.message
      };
    case manageQueryResponsesConstants.ADD_QUERY_RESPONSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageQueryResponsesConstants.ADD_QUERY_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        addedQueryResponse: action.response,
        isAdded: action.isAdded,
        error: ""
      };
    case manageQueryResponsesConstants.ADD_QUERY_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        isAdded: false,
        error: action.error.key ?? action.error.message
      };
    case manageQueryResponsesConstants.UPDATE_QUERY_RESPONSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageQueryResponsesConstants.UPDATE_QUERY_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.isUpdated,
        error: ""
      };
    case manageQueryResponsesConstants.UPDATE_QUERY_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        error: action.error.key ?? action.error.message
      };
    case manageQueryResponsesConstants.CLEAR_ADDED_QUERY:
      return {
        ...state,
        isAdded: false
      };
    case manageQueryResponsesConstants.CLEAR_UPDATED_QUERY:
      return {
        ...state,
        isUpdated: false
      };
    case manageQueryResponsesConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageQueryResponsesConstants.RESET:
      return {
        ...state,
        error: "",
        isUpdated: false,
        isAdded: false,
        loading: false
      };
    default:
      return state;
  }
}
