import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants";
import { useLazyGetWorkflowStepQuery } from "@shared/hooks";

import ions from "@ions";

import { getWorkflowActionsForQuery } from "@app/helpers/actionItemTypes.ts";
import {
  getWorkflowStep,
  workflowActionName,
  workflowStepName
} from "@app/helpers/actionItems.js";
import { returnStringIfTrue } from "@app/helpers/componentHelpers";
import { useLookupProjectUser } from "@app/hooks/useLookupProjectUser";
import { SwimlaneItem } from "@app/types";

import { Inline, Stack } from "@fermions";

import Avatar from "@atoms/Avatar/Avatar";
import { Icon } from "@atoms/Icon";
import { IconWithText } from "@atoms/IconWithText/IconWithText";
import { Pill, PillSize } from "@atoms/Pill";
import { StatusBar } from "@atoms/StatusBar";

import { ActionDropdown } from "@molecules/ActionDropdown/ActionDropdown";

import ActionConfirmation from "@organisms/QueryActions/ActionConfirmation";

import { WorkflowConfirmation } from "@templates/KanbanTemplate/KanbanTemplate";

import { DropdownItemType } from "../DropdownList/DropdownHelper";
import EntitiesListIconCell from "../EntitiesListIconCell";
import "./ActionItemPanel.scss";

export const ActionItemPanel = ({
  item,
  isDragging = false,
  menuItemClickHandler
}: {
  item: SwimlaneItem;
  isDragging: boolean;
  menuItemClickHandler: (item: {
    menuItem: DropdownItemType;
    item: SwimlaneItem;
    destinationStatus?: string;
    confirmed?: boolean;
  }) => void;
  entities: Array<{
    name: string;
    externalId: string;
  }>;
}) => {
  const { t } = useTranslation();

  const query = item.data;
  const { getProjectUser } = useLookupProjectUser(query?.projectId);

  const [fetchWorkflowStep, { data: workFlowSteps }] =
    useLazyGetWorkflowStepQuery();
  const [confirmationContent, setConfirmationContent] =
    useState<WorkflowConfirmation>();
  const [actionItems, setActionItems] = useState<
    DropdownItemType[] | undefined
  >([]);

  const getTagsToDisplay = tags => {
    const maxTags = 2;
    if (!tags?.length) {
      return;
    }
    if (tags.length <= maxTags) {
      return { displayTags: tags };
    }

    const orderedTags = tags.sort((a, b) => a.length - b.length);
    return {
      displayTags: orderedTags.slice(0, maxTags),
      otherTags: orderedTags.slice(maxTags),
      allTags: orderedTags
    };
  };

  const renderTags = useCallback(
    tags => {
      const tagsDisplay = getTagsToDisplay(tags);
      return (
        tagsDisplay &&
        tagsDisplay.displayTags?.length > 0 && (
          <Inline className="action-item-panel__tags">
            {tagsDisplay.displayTags.map((tag: string) => (
              <Pill key={tag} label={tag} size={PillSize.SMALL} />
            ))}
            {tagsDisplay.otherTags?.length > 0 && (
              <IconWithText
                text={t(
                  "requests:requests.configured.fields.labels.additionalLabel",
                  {
                    numOthers: tagsDisplay.otherTags.length
                  }
                )}
                hoverText={tagsDisplay.allTags.join(" • ")}
                hoverTextPrefix={t(
                  "requests:requests.configured.fields.labels.label"
                )}
              />
            )}
          </Inline>
        )
      );
    },
    [t]
  );

  const renderEntities = useCallback(entities => {
    const relevantEntities = Object.values(entities || {});
    if (!relevantEntities.length) {
      return;
    }
    return <EntitiesListIconCell entities={relevantEntities} />;
  }, []);

  const renderError = useCallback(
    () => (
      <Icon
        className="flag flag-REJECTED"
        name="warning"
        fillStyle="filled"
        hoverElement={t("requests:requests.configured.status.ERROR.label")}
      />
    ),
    [t]
  );

  const renderFlag = useCallback(
    flag => {
      switch (flag) {
        case "REJECTED":
          return (
            <Icon
              className="flag flag-REJECTED"
              name="cancel"
              fillStyle="filled"
              hoverElement={t("requests:requests.configured.flags_REJECTED")}
            />
          );
        case "APPROVED":
          return (
            <Icon
              className="flag flag-APPROVED"
              name="check_circle"
              fillStyle="filled"
              hoverElement={t("requests:requests.configured.flags_APPROVED")}
            />
          );
      }
    },
    [t]
  );

  const renderActionItemTypeSpecificContent = useCallback(() => {
    switch (query.actionItemType?.configuration?.type) {
      case systemConstants.actionItemTypes.conversation:
      case systemConstants.actionItemTypes.smartForm: {
        if (!query.workflowStep) {
          return <></>;
        }
        return (
          <Pill
            size={PillSize.SMALL}
            label={workflowStepName({
              t,
              workflowStep: getWorkflowStep({
                actionItemTypeConfig: query.actionItemType?.configuration,
                workflowStep: query.workflowStep
              })
            })}
          />
        );
      }
      case systemConstants.actionItemTypes.websheet:
      default:
        return <></>;
    }
  }, [query.actionItemType?.configuration, query.workflowStep, t]);

  useEffect(() => {
    const isWorkflow = !!query?.actionItemType?.configuration?.workflow;
    if (isWorkflow) {
      const workflowActionsList = workFlowSteps?.nextActions.map(action => {
        return {
          name: workflowActionName({ workflowAction: action, t }),
          key: action.key,
          destinationStatus: action.nextStep?.queryStatus,
          beforeUserTriggers: action.config?.beforeUserTriggers?.filter(
            trigger => trigger.type === "confirmationDialog"
          )
        };
      });
      setActionItems(
        getWorkflowActionsForQuery({
          actionItemType: query.actionItemType,
          userRoleType: query.userRoleType,
          queryStatus: query.status,
          workflowActions: workflowActionsList,
          t
        })
      );
    } else {
      setActionItems(query.actions);
    }
  }, [
    workFlowSteps,
    query.actionItemType,
    query.actions,
    query.status,
    query.userRoleType,
    t
  ]);

  const handleCloseModal = () => {
    setConfirmationContent(undefined);
  };

  const handleActionConfirmation = () => {
    handleCloseModal();
    if (confirmationContent) {
      menuItemClickHandler({
        menuItem: {
          action: confirmationContent.confirmKey,
          name: confirmationContent.title
        },
        item,
        destinationStatus: confirmationContent.destinationStatus,
        confirmed: true
      });
    }
  };
  const getWorkflowConfirmationModal = () => {
    if (!confirmationContent) {
      return;
    }
    return (
      <Popup
        visibility={true}
        handleOutsideClick={handleCloseModal}
        width="50rem"
      >
        <ActionConfirmation
          title={confirmationContent.title}
          message={confirmationContent.message}
          handleSubmit={handleActionConfirmation}
          handleCancel={handleCloseModal}
          error=""
        />
      </Popup>
    );
  };

  const onMenuItemClick = useCallback(
    ({ item, menuItem }) => {
      if (menuItem.beforeUserTriggers?.length) {
        setConfirmationContent({
          title: menuItem.beforeUserTriggers[0].title,
          message: menuItem.beforeUserTriggers[0].message,
          translate: menuItem.beforeUserTriggers[0].translate,
          confirmKey: menuItem.key,
          destinationStatus: menuItem.destinationStatus
        });
      } else {
        menuItemClickHandler({
          menuItem,
          destinationStatus: menuItem.destinationStatus,
          item
        });
      }
    },
    [menuItemClickHandler]
  );

  const useFetchWorkflowStep = useCallback(() => {
    if (
      query.configType !== systemConstants.actionItemTypes.websheet &&
      query.workflowStep
    ) {
      fetchWorkflowStep({ queryId: item.id });
    }
  }, [fetchWorkflowStep, item.id, query?.configType, query?.workflowStep]);

  const renderActionDropdown = useCallback(() => {
    if (!query.actions.length) {
      return <></>;
    }
    return (
      <ActionDropdown
        data-testid="test__moreActionBtn"
        menuItems={actionItems}
        onMenuItemClick={(menuItem: DropdownItemType) =>
          onMenuItemClick({ menuItem, item })
        }
        onOpenMenu={useFetchWorkflowStep}
      />
    );
  }, [
    actionItems,
    item,
    onMenuItemClick,
    query.actions.length,
    useFetchWorkflowStep
  ]);

  const onItemClick = (event: React.MouseEvent) => {
    if (confirmationContent) {
      event.stopPropagation();
    }
    item.onClick?.();
  };

  return (
    <Inline
      className={[
        "action-item-panel",
        returnStringIfTrue(isDragging, "action-item-panel--dragging"),
        returnStringIfTrue(item.isLoading, "action-item-panel--loading")
      ]}
      width="100"
      onClick={onItemClick}
    >
      {query.indicator?.key && <StatusBar type={query.indicator?.key} />}
      <Stack className="action-item-panel__content" width="100">
        {renderActionDropdown()}
        <Stack className="action-item-panel__content__container" height="100">
          <Stack
            className={[
              "action-item-panel__top",
              "action-item-panel__content__container"
            ]}
            height="100"
          >
            <Inline
              className="action-item-panel__content__container"
              alignment="left"
            >
              <IconWithText
                iconName="tag"
                text={query.refNo}
                hoverTextPrefix={t("requests:requests.refLabel")}
                className="action-item-panel__refNo"
              />
            </Inline>
            <IconWithText
              className="action-item-panel__description"
              text={query.description}
              hoverTextPrefix={t(
                "requests:requests.configured.fields.description.label"
              )}
            />
            {renderTags(query.tags)}
          </Stack>
          <Stack
            className={[
              "action-item-panel__bottom",
              "action-item-panel__content__container"
            ]}
            style={{ justifyContent: "space-between" }}
          >
            <Inline
              className="action-item-panel__content__container"
              alignment="left"
            >
              {query.requiredBy && (
                <IconWithText
                  iconName="calendar_today"
                  text={query.requiredBy}
                  hoverTextPrefix={t("requests:requests.dueDateLabel")}
                />
              )}
            </Inline>
            <Inline
              gap="100"
              alignment={query.internalOnly ? "left" : "right"}
              style={{
                justifyContent: query.internalOnly ? "space-between" : ""
              }}
            >
              {query.internalOnly && (
                <Pill
                  size={PillSize.SMALL}
                  variant={ions.components?.atoms?.draft_pill?.pill_variant}
                  fillStyle={
                    ions.components?.atoms?.draft_pill?.pill_fill_style
                  }
                  label={t("requests:requests.internalOnly.label")}
                />
              )}
              <Inline className="action-item-panel__content__bottom-container">
                {renderActionItemTypeSpecificContent()}
                <IconWithText
                  text={query.actionItemType?.name}
                  iconOnly
                  iconName={query.queryTypeIcon}
                  filled={false}
                  hoverTextPrefix={t("requests:requests.typeLabel")}
                />
                {query.entities && renderEntities(query.entities)}
                {query.status ===
                  systemConstants.project.queries.status.error && renderError()}
                {query.flag && renderFlag(query.flag)}
                {query.assignedTo && (
                  <Avatar
                    user={getProjectUser(query.assignedTo)}
                    size="x-small"
                    showHover
                    hoverPrefix={t(
                      "requests:requests.configured.fields.assignedTo.pastTenseLabel"
                    )}
                  />
                )}
              </Inline>
            </Inline>
          </Stack>
        </Stack>
      </Stack>
      {getWorkflowConfirmationModal()}
    </Inline>
  );
};
