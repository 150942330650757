import React from "react";

import { useUIConfig } from "@shared/hooks/useUIConfig";

import UIConfigContext from "@app/helpers/UIConfigContext";

const UIConfigProvider = props => {
  const { uiConfig } = useUIConfig();

  return (
    <UIConfigContext.Provider value={uiConfig}>
      {props.children}
    </UIConfigContext.Provider>
  );
};

export default UIConfigProvider;
