import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";

import { Icon, IconDesignStyle, IconFillStyle } from "@atoms/Icon";

import { ActionDropdown } from "@molecules/ActionDropdown";
import { DataTableDropdownMultiselect } from "@molecules/DataTableDropdownMultiselect";
import { DataTableDropdownSelect } from "@molecules/DataTableDropdownSelect";

import CircleIndicator from "@components/atoms/CircleIndicator";
import { DataTableCheckbox } from "@components/molecules/DataTableCheckbox";
import { sortWithAll } from "@components/molecules/DropdownList/DropdownHelper";
import Loading from "@components/molecules/Loading";

// Collection of common utilities to be used in conjunction with DataTable
export function useDataTable(listOfItems) {
  const { t } = useTranslation();

  // accessor(item) => returns the property to compare against 'id'
  const findItem = useCallback(
    (id, accessor) =>
      listOfItems.find(item =>
        accessor ? accessor(item) === id : id === item.id
      ),
    [listOfItems]
  );

  const createColumn = useCallback(
    ({ Header = "", accessor, className, handleSortChange, ...others }) => {
      return {
        Header,
        accessor,
        className: className || accessor,
        onSortedChange: handleSortChange ?? (() => {}),
        ...others
      };
    },
    []
  );

  const createColumnForStatus = useCallback(
    ({ Header = "", accessor, ...others }) => {
      return {
        Header,
        accessor,
        className: "status",
        width: 50,
        fixedWidth: true,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value ? (
                <CircleIndicator colorScheme={cell.value} />
              ) : (
                <></>
              )}
            </>
          );
        },
        ...others
      };
    },
    []
  );

  const createColumnForDataTableCheckbox = useCallback(
    ({ accessor, ...others }) => {
      return {
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <DataTableCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        accessor,
        className: "check-box",
        width: 50,
        Cell: ({ row }) => (
          <div>
            <DataTableCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        ...others
      };
    },
    []
  );

  /**
   * @param {string} accessor - accessor to the actions menu
   * @param {function} onClickHandler - function to handle the click event on the menu item - receives object with menuItem and cell
   * @param {function} displayCell - function to determine whether to display the cell
   * @param {function} onOpenMenu - function to execute when the menu is opened
   * @param {object} workFlowSteps - workflow actions
   * @param {object} others - other props to be passed to the column
   * @returns {object} - returns the column object
   * @description - creates a column for the kebab menu with actions - data should be an array of objects with the following shape:
   * {
   *  label: string
   *  name: string
   *  key: string
   * }
   */
  const createColumnForDropdownMenu = useCallback(
    ({
      accessor: accessorToActionsMenu,
      onClickHandler: menuItemClickHandler,
      displayCell = () => true,
      customActionDropdown,
      ...others
    }) => {
      return {
        accessor: accessorToActionsMenu,
        className: "actions",
        width: 50,
        fixedWidth: true,
        Cell: ({ cell }) => {
          if (!cell.value?.length || !displayCell(cell.row.id)) {
            return <></>;
          }
          const defaultProps = {
            className: `${classNames([
              returnStringIfTrue(
                cell?.row?.original?.locked,
                "locked-action-menu"
              )
            ])}`,
            "data-testid": "test__moreActionBtn",
            menuItems: cell.value,
            onMenuItemClick: menuItem =>
              menuItemClickHandler({ menuItem, cell })
          };

          if (customActionDropdown) {
            return customActionDropdown({
              defaultProps,
              cell,
              displayCell,
              menuItemClickHandler
            });
          }
          return <ActionDropdown {...defaultProps} />;
        },
        ...others
      };
    },
    []
  );

  // onClickHandler: ({ cell })
  const createColumnForSingleAction = useCallback(
    ({ accessor: accessorToAction, onClickHandler, ...others }) => {
      return {
        accessor: accessorToAction || "action",
        className: "single-action",
        clickableCell: onClickHandler,
        ...others
      };
    },
    []
  );

  const createColumnForSingleActionIcon = useCallback(
    ({
      accessor: accessorToIconName,
      onClickHandler,
      displayCell = () => true,
      title,
      value,
      ...others
    }) => {
      const extraProps = {
        title
      };
      return {
        accessor: accessorToIconName || "action",
        className: "single-action",
        clickableCell: onClickHandler,
        width: 50,
        fixedWidth: true,
        Cell: ({ cell }) =>
          displayCell(cell.row.id) && (
            <i {...extraProps} className="material-icons">
              {cell.value ?? value}
            </i>
          ),
        ...others
      };
    },
    []
  );

  // This is a custom filter UI for selecting a unique option from a list
  const SelectColumnFilter = useCallback(
    (label, allOptionLabel, filterExtras = [], onChangeHandler) => {
      return ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        // Calculate the options for filtering using the preFilteredRows
        const optionsSet = new Set([...filterExtras]);
        preFilteredRows.forEach(row => {
          optionsSet.add(row.values[id]);
        });
        const options = [...optionsSet.values()];

        return (
          <>
            <DataTableDropdownSelect
              label={label}
              items={[
                { label: allOptionLabel, name: "All", value: undefined },
                ...options.map(o => ({ name: o, value: o }))
              ]}
              onChange={onChangeHandler ?? setFilter}
              value={filterValue}
              sortComparator={sortWithAll}
            />
          </>
        );
      };
    },
    []
  );

  const SelectMultiselectColumnFilter = useCallback(
    (label, filterExtras = [], onChangeHandler = undefined) => {
      return ({
        column: { filterValue, setFilter, preFilteredRows, id, accessValue }
      }) => {
        // Calculate the options for filtering using the preFilteredRows
        const optionsSet = new Set([...filterExtras]);
        preFilteredRows.forEach(row => {
          if (accessValue) {
            optionsSet.add(accessValue(row.values[id]));
          } else {
            optionsSet.add(row.values[id]);
          }
        });
        const options = [...optionsSet.values()].filter(o => o);
        return (
          <DataTableDropdownMultiselect
            label={label}
            items={[
              ...options.map(o => ({
                name: o,
                value: o,
                isChecked: o.isChecked
              }))
            ]}
            value={filterValue}
            onChange={onChangeHandler ?? setFilter}
          />
        );
      };
    },
    []
  );

  const createColumnForFilter = useCallback(
    ({
      accessor,
      className,
      filterLabel,
      allOptionLabel,
      filterExtras = [],
      onChangeHandler,
      ...others
    }) => {
      return {
        Header: "",
        accessor,
        className,
        Filter: SelectColumnFilter(
          filterLabel,
          allOptionLabel,
          filterExtras,
          onChangeHandler
        ),
        filter: "includes",
        disableFilters: false,
        ...others
      };
    },
    [SelectColumnFilter]
  );

  const createColumnForMultiselectFilter = useCallback(
    ({
      accessor,
      className,
      filterLabel,
      filterExtras = [],
      onChangeHandler,
      ...others
    }) => {
      return {
        Header: "",
        accessor,
        className,
        Filter: SelectMultiselectColumnFilter(
          filterLabel,
          filterExtras,
          onChangeHandler
        ),
        filter: "includesSome",
        disableFilters: false,
        ...others
      };
    },
    [SelectMultiselectColumnFilter]
  );

  const createColumnForDownloading = useCallback(
    ({ accessor, className, onClickHandler, downloadingRowIds, ...others }) => {
      return {
        accessor,
        clickableCell: onClickHandler,
        className,
        Cell: ({ cell }) => {
          if (cell?.row?.original?.locked) {
            return (
              <Icon
                name={"lock"}
                className="locked-action-icon"
                designStyle={IconDesignStyle.MATERIAL_SYMBOLS}
                fillStyle={IconFillStyle.OUTLINED}
                hoverElement={t(
                  "common:ui.projectSummary.messageNotAllEntitiesAccessToDocument"
                )}
              />
            );
          }
          if (downloadingRowIds.find(id => id === cell.row.original.id)) {
            return <Loading />;
          }
          return (
            <Icon
              name="download"
              designStyle={IconDesignStyle.MATERIAL_ICONS}
              fillStyle={IconFillStyle.FILLED}
            />
          );
        },
        ...others
      };
    },
    []
  );

  const caseInsensitiveSortType = useCallback((rowA, rowB, columnId) => {
    const safeCol = row => row.values[columnId] || "";
    return safeCol(rowA).localeCompare?.(safeCol(rowB), {
      sensitivity: "base"
    });
  }, []);

  const caseInsensitiveEmptyLastSortType = useCallback(
    (rowA, rowB, columnId) => {
      if (!rowA.values[columnId]) {
        return 1;
      }
      if (!rowB.values[columnId]) {
        return -1;
      }
      return caseInsensitiveSortType(rowA, rowB, columnId);
    },
    [caseInsensitiveSortType]
  );

  return {
    findItem,
    createColumn,
    createColumnForDropdownMenu,
    createColumnForDownloading,
    createColumnForSingleAction,
    createColumnForSingleActionIcon,
    createColumnForStatus,
    createColumnForFilter,
    createColumnForDataTableCheckbox,
    createColumnForMultiselectFilter,
    caseInsensitiveSortType,
    caseInsensitiveEmptyLastSortType
  };
}
