import React, { useEffect, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import Timeline, {
  TimelineMarkers,
  TodayMarker
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { systemConstants } from "../../constants";
import abstraction from "../../scss/exportables.module.scss";
import "./projectTimeline.scss";

const ProjectTimeline = props => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const groups = [
    {
      id: 1,
      key: "BEHIND",
      title: t("common:ui.project.status", { context: "BEHIND" })
    },
    {
      id: 2,
      key: "ON TRACK",
      title: t("common:ui.project.status", { context: "ON_TRACK" })
    },
    {
      id: 3,
      key: "UPCOMING",
      title: t("common:ui.project.status", { context: "UPCOMING" })
    },
    {
      id: 4,
      key: "COMPLETED",
      title: t("common:ui.project.status", { context: "COMPLETED" })
    }
  ];

  const [timelineProjects, setTimelineProjects] = useState([]);

  const [defaultTimeStart, setDefaultTimeStart] = useState(
    props.defaultTimeStart ? props.defaultTimeStart : moment()
  );
  const [defaultTimeEnd, setDefaultTimeEnd] = useState(
    props.defaultTimeEnd ? props.defaultTimeEnd : moment().add(180, "day")
  );

  useEffect(() => {
    setDefaultTimeStart(
      props.defaultTimeStart ? props.defaultTimeStart : moment()
    );
  }, [props.defaultTimeStart]);

  useEffect(() => {
    setDefaultTimeEnd(props.defaultTimeEnd ? props.defaultTimeEnd : moment());
  }, [props.defaultTimeEnd]);

  useEffect(() => {
    if (props.projects) {
      const projects = props.projects
        .filter(
          project => project.status !== systemConstants.project.status.archived
        )
        .map(project => {
          const timelineProject = { ...project };
          timelineProject.originalProject = project;
          const timeLineGroup = groups?.find(
            group => group.key === project.status
          );
          timelineProject.group = timeLineGroup && timeLineGroup.id;
          timelineProject.start_time = moment(project.startDate);
          timelineProject.end_time = moment(project.plannedCompletionDate);
          timelineProject.title = project.name;
          timelineProject.fontColor = abstraction.colorWhite1;
          switch (timelineProject.group) {
            case 1:
              timelineProject.color = abstraction.statusBehind;
              break;

            case 2:
              timelineProject.color = abstraction.statusOnTrack;
              break;

            case 3:
              timelineProject.color = abstraction.statusUpcoming;
              timelineProject.fontColor = abstraction.colorBlack1;
              break;

            case 4:
              timelineProject.color = abstraction.statusCompleted;
              break;

            default:
              timelineProject.color = abstraction.statusUpcoming;
              break;
          }

          timelineProject.itemProps = {
            // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
            "data-custom-attribute": "Random content",
            "aria-hidden": true,
            onDoubleClick: () => handleProjectBarClick(project),
            style: {
              background: timelineProject.color,
              borderRadius: 1,
              color: timelineProject.fontColor
            }
          };
          return timelineProject;
        });
      setTimelineProjects(projects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projects]);

  const getProjectObject = project => {
    return {
      id: project.id,
      name: project.name,
      status: project.status,
      startDate: project.startDate,
      plannedCompletionDate: project.plannedCompletionDate,
      actualCompletionDate: project.actualCompletionDate,
      projectManagerId: project.projectManagerId,
      projectManager: project.projectManager,
      milestones: project.milestones,
      upcomingMilestone: project.upcomingMilestone,
      unreadMessages: project.unreadMessages,
      incompleteTodos: project.incompleteTodos
    };
  };

  const handleProjectBarClick = project => {
    navigate(routeConstants.project.dashboard, {
      state: {
        project: getProjectObject(project)
      }
    });
  };

  return (
    <div>
      <Timeline
        groups={groups}
        items={timelineProjects}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        itemsSorted
        itemTouchSendsClick={false}
        stackItems
        itemHeightRatio={0.75}
        showCursorLine
        canMove={false}
        canResize={false}
        ranges={[
          {
            id: 2,
            start: moment().add(-1, "day").toDate(),
            end: moment().add(4, "day").toDate(),
            className: "weekend"
          }
        ]}
      >
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) => (
              <div style={{ ...styles, backgroundColor: "red" }} />
            )}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    </div>
  );
};

ProjectTimeline.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
      plannedCompletionDate: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ProjectTimeline;
