import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import { useLookupProjectUser } from "@app/hooks/useLookupProjectUser";

import ConversationMessage from "@components/molecules/ConversationMessage";

import "./ActionItemCommentsThread.scss";

function ActionItemCommentsThread(props) {
  const { projectId, comments, onFileDownload, authUserId } = props;
  const { getProjectUser } = useLookupProjectUser(projectId);
  const [responseSequenceId, setResponseSequenceId] = useState(1);
  const bottomOfConversations = useRef(null);

  useEffect(() => {
    if (comments) {
      setResponseSequenceId(prev => (!prev ? 1 : prev));
      bottomOfConversations?.current?.scrollIntoView();
    }
  }, [comments]);

  useEffect(() => {
    if (responseSequenceId) {
      bottomOfConversations?.current?.scrollIntoView();
    }
  }, [responseSequenceId]);

  return (
    <div className="comment-thread-modal__content">
      <div className="request-conversation__content-message">
        {comments?.map(comment => {
          const currentComment = {
            ...comment,
            user:
              !comment.user.firstName && !comment.user.lastName
                ? {
                    ...comment.user,
                    isAnonymous: true
                  }
                : comment.user
          };
          return (
            <ConversationMessage
              key={currentComment.id}
              align={authUserId === currentComment.userId ? "right" : "left"}
              attachments={currentComment.attachments}
              user={getProjectUser(currentComment.user)}
              message={currentComment.response}
              updatedAt={currentComment.createdAt}
              onFileDownloadClicked={onFileDownload}
            />
          );
        })}
      </div>
      <div ref={bottomOfConversations}></div>
    </div>
  );
}

ActionItemCommentsThread.propTypes = {
  comments: PropTypes.array,
  onFileDownload: PropTypes.func,
  authUserId: PropTypes.number,
  actionItemTypeKey: PropTypes.string
};

export default ActionItemCommentsThread;
