import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate, useRequestPageNavigator } from "@shared/hooks";
import { useTooltip } from "@shared/hooks/useTooltip";

import { getActionIndicator } from "@app/helpers";
import {
  QUERY_TYPE_ICON,
  expectedQueryTypes,
  formatQueryType,
  getActionItemTypeConfiguration,
  getActionItemTypeDisplayName
} from "@app/helpers/actionItems";

import { Box, Inline, Stack } from "@fermions";

import { Icon } from "@atoms/Icon";
import { Pill, PillFillStyle, PillVariant } from "@atoms/Pill";

import Avatar from "@components/atoms/Avatar/Avatar";
import OTTooltip from "@components/atoms/OTTooltip";

import "./TodoListCard.scss";

const ACTION_ITEM_TYPES = systemConstants.actionItemTypes;

const TodoListCard = props => {
  const { query, actionItemTypes } = props;
  const { navigateToRequestPage } = useRequestPageNavigator();
  const hoverTimeout = useRef();
  const { t } = useTranslation();
  const color = query.requiredBy
    ? getActionIndicator(query)?.colorScheme
    : null;
  const { showTooltip, elementProps } = useTooltip();
  const [toDoListExpanded, setToDoListExpanded] = useState(false);
  const { locale, options } = useLocaleDate();

  const actionItemTypeConfig = useMemo(() => {
    return (
      query.queryType?.configuration ??
      getActionItemTypeConfiguration(query.queryType, actionItemTypes)
    );
  }, [actionItemTypes, query?.queryType]);

  const { queryTypes } = useMemo(
    () => expectedQueryTypes(actionItemTypes, t),
    [actionItemTypes, t]
  );

  const handleSelectQuery = useCallback(() => {
    const actionItemType = actionItemTypeConfig?.type ?? query?.configType;
    navigateToRequestPage(query.id, query.projectId, actionItemType, {
      websheetOpensNewWindow: true
    });
  }, [actionItemTypeConfig?.type, navigateToRequestPage, query]);

  const renderDate = date => {
    if (date?.value) {
      return (
        <Inline className="todoList-card__right__bot">
          <span>{`${t(date.key)}:`}</span>
          <span
            className={"todoList-card__date"}
            data-testid="test-todolist-card__date"
          >
            {dateFormatter(Date.parse(date.value), locale, options.longFormat)}
          </span>
        </Inline>
      );
    }
  };

  const renderOverDuePill = () => {
    if (color === "error" && query?.requiredBy) {
      return (
        <div className="todoList-card__overdue-pill">
          <Pill
            label="OVERDUE"
            variant={PillVariant.ERROR}
            fillStyle={PillFillStyle.FILLED}
          />
        </div>
      );
    }
  };

  const toDoListLeft = () => {
    return (
      <Box {...elementProps} className="todoList-card__avatar">
        <Avatar
          size={"small"}
          user={query.requestedBy}
          showHover={query.requestedBy?.isAnonymous}
        />
        <OTTooltip
          content={t("requests:requests.ui.todoCard.createByHoverText", {
            name: query.requestedBy.name
          })}
          visible={!query.requestedBy?.isAnonymous && showTooltip}
        />
      </Box>
    );
  };

  const toDoListRight = () => {
    const description = () => {
      if (query.description) {
        return query.description;
      }

      if (actionItemTypeConfig?.type === ACTION_ITEM_TYPES.smartForm) {
        return getActionItemTypeDisplayName(
          actionItemTypeConfig.configuration?.useCustomName,
          actionItemTypeConfig.name,
          t("requests:requests.configured.name", {
            context: actionItemTypeConfig.key
          })
        );
      }
    };

    return (
      <Stack className="todoList-card__right" gap="025">
        <Box className="todoList-card__project">{query.project.name}</Box>
        <Inline data-testid="test-todolist-card__name">
          <span
            className={`todoList-card__description-text${
              toDoListExpanded ? "" : "--truncated"
            }`}
          >
            {description()}
          </span>
          {(actionItemTypeConfig?.type || query?.configType) && (
            <Icon
              name={
                QUERY_TYPE_ICON[actionItemTypeConfig?.type ?? query.configType]
              }
              className="todoList-card__query-type"
              hoverElement={
                actionItemTypeConfig?.key ? (
                  <>
                    {formatQueryType(actionItemTypeConfig?.key, queryTypes, t)}
                  </>
                ) : undefined
              }
            />
          )}
        </Inline>
        {renderDate({
          key: "requests:requests.dueDateLabel",
          value: query.requiredBy
        })}
        {toDoListExpanded && query?.query && (
          <span className="todoList-card__long-description">
            {toDoListExpanded && query?.query}
          </span>
        )}
        {toDoListExpanded &&
          renderDate({
            key: "requests:requests.createdAtLabel",
            value: query.createdAt
          })}
        {toDoListExpanded && query?.tags && query?.tags.length > 0 && (
          <div className="todoList-card__right__labels">
            <span>Labels: </span>
            <span className="todoList-card__right__labels__list">
              {query?.tags
                .map(tag => {
                  return <span key={tag.id}>{tag.name}</span>;
                })
                .reduce((prev, curr) => [prev, ", ", curr])}
            </span>
          </div>
        )}
      </Stack>
    );
  };
  useEffect(() => {
    return () => clearTimeout(hoverTimeout.current);
  }, []);

  const handleMouseEnter = useCallback(() => {
    hoverTimeout.current = setTimeout(() => {
      setToDoListExpanded(true);
    }, 250);
  }, []);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(hoverTimeout.current);
    setToDoListExpanded(false);
  }, []);

  return (
    <Inline
      className="todoList-card"
      data-testid="test-todolist-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSelectQuery}
      gap="100"
      padding="100"
    >
      {toDoListLeft()}
      {toDoListRight()}
      <Icon name="keyboard_arrow_right" className="todoList-card__icon" />
      {renderOverDuePill()}
    </Inline>
  );
};

TodoListCard.propTypes = {
  query: PropTypes.any,
  actionItemTypes: PropTypes.arrayOf(PropTypes.any)
};

export default TodoListCard;
