import { systemConstants } from "@shared/constants";

import { menuConstants, routeConstants } from "@app/constants";
import { AccessLevel, ResourceName } from "@app/types";

import {
  IconColor,
  IconDesignStyle,
  IconFillStyle,
  IconSize
} from "@atoms/Icon";

const menuItemTypes = menuConstants.menuItemTypes;
const sideNavContext = menuConstants.sideNavContext;
const activeItemConfig = menuConstants.activeItemConfig;

const isItemActive = activePaths => {
  const pathname = window.location.pathname;
  return activePaths.some(path => pathname.includes(path));
};

const safeLinkToInteractiveReportPages = (projectId, pageId) =>
  projectId && pageId ? `/projects/${projectId}/pages/${pageId}` : "";

const subSectionIcon = {
  name: "stop",
  fillStyle: IconFillStyle.FILLED,
  designStyle: IconDesignStyle.MATERIAL_ICONS,
  color: IconColor.OTHER,
  size: IconSize.M
};

const mapIRMenus = project => (menu, id) => ({
  name: menu.name,
  id: `IR-${id}`,
  type:
    menu.pages?.length === 1
      ? menuItemTypes.NAV_LINK
      : menuItemTypes.NAV_SUBMENU,
  link:
    menu.pages?.length === 1
      ? safeLinkToInteractiveReportPages(project?.id, menu.pages[0]?.id)
      : "",
  items:
    menu.pages?.length > 1
      ? menu.pages?.map(page => ({
          name: page?.pageName,
          link: safeLinkToInteractiveReportPages(project?.id, page?.id),
          isActive:
            safeLinkToInteractiveReportPages(project?.id, page?.id) ===
            window.location.pathname,
          context: sideNavContext.PROJECT,
          state: { project },
          icon: {
            name: "stop",
            fillStyle: IconFillStyle.FILLED,
            designStyle: IconDesignStyle.MATERIAL_ICONS,
            color: IconColor.OTHER,
            size: IconSize.XS
          }
        }))
      : [],
  state: { project },
  context: sideNavContext.PROJECT,
  icon: subSectionIcon,
  permission: {
    resource: ResourceName.INTERACTIVE_REPORT,
    accessLevel: AccessLevel.READ
  }
});

export const getRelevantItems = (items, currentSideNavContext = null) => {
  return (
    items?.filter(menuItem => {
      return (
        !menuItem.context || menuItem.context.includes(currentSideNavContext)
      );
    }) ?? []
  ).map(i => {
    if (typeof i.isActive === "undefined") {
      i.isActive = isMenuActive(i);
    }
    return i;
  });
};

const sideNavContextMapping = {
  [sideNavContext.PROJECT]: [
    routeConstants.project,
    routeConstants.request,
    routeConstants.projects,
    routeConstants.uploadInteractiveReport,
    routeConstants.aiReporting
  ],
  [sideNavContext.ALL_PROJECTS]: [
    routeConstants.clientDashboard,
    routeConstants.clientUserLandingPage
  ],
  // Client context is used when we want to display client name in the side nav
  // but the side nav is not related to any specific project
  [sideNavContext.CLIENT]: [
    routeConstants.permanentFiles,
    routeConstants.engagements,
    "/clients"
  ],
  [sideNavContext.ADMIN]: [
    `/admin`,
    routeConstants.manageAudits,
    routeConstants.manageNews,
    routeConstants.admin.manageRequestTypes,
    routeConstants.dataExtraction,
    routeConstants.admin.globalTags
  ],
  [sideNavContext.INSIGHTS]: ["/insights-board"]
};

const getCurrentSideNavContext = currentPath => {
  let context;
  Object.entries(sideNavContextMapping).forEach(([key, routes]) => {
    routes.forEach(route => {
      if (
        (typeof route === "string" && currentPath?.includes(route)) ||
        (typeof route === "object" &&
          Object.values(route)?.some(requestPath =>
            currentPath?.includes(requestPath)
          ))
      ) {
        context = key;
      }
    });
  });
  return context;
};

const isMenuActive = ({ link }) => window.location.pathname === link;
const getClientMenuItems = ({ user, clientId, i18nText }) => {
  if (!user.isLoggedIn || !clientId) {
    return [];
  }
  const clientMenuItems = [
    {
      name: i18nText("common:ui.sideNavigation.permanentFile"),
      type: menuItemTypes.NAV_LINK,
      icon: {
        name: "description",
        fillStyle: IconFillStyle.OUTLINED,
        designStyle: IconDesignStyle.MATERIAL_ICONS,
        color: IconColor.INHERIT,
        size: IconSize.M
      },
      link: `/clients/${clientId}/permanent-files`,
      context: [sideNavContext.PROJECT, sideNavContext.CLIENT],
      position: "bottom"
    }
  ];
  return clientMenuItems;
};

const features = systemConstants.features;

const getAdminMenuItems = ({ clientId, i18nText }) => [
  {
    name: i18nText("admin:ui.navigation.heading.userManagement.label"),
    type: menuItemTypes.NAV_SECTION_TITLE,
    context: sideNavContext.ADMIN
  },
  {
    name: i18nText("admin:ui.navigation.manageUsers.label", {
      context: "HOST"
    }),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    link: routeConstants.manageUsers,
    permission: {
      resource: ResourceName.HOST_USERS,
      accessLevel: AccessLevel.READ
    },
    isActive: isItemActive(activeItemConfig.manageUsers),
    icon: {
      name: "person"
    }
  },
  {
    name: i18nText("admin:ui.navigation.manageUsers.label", {
      context: "CLIENT"
    }),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    link: `/admin/clients/${clientId}/users`,
    permission: {
      resource: ResourceName.CLIENT_USERS,
      accessLevel: AccessLevel.READ,
      type: "CLIENT"
    },
    isActive: isItemActive(activeItemConfig.manageClients),
    icon: {
      name: "person"
    }
  },
  {
    name: i18nText("admin:ui.navigation.manageRoles.label"),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    icon: {
      name: "manage_accounts"
    },
    link: routeConstants.admin.manageUserRoles,
    permission: {
      resource: ResourceName.ROLES,
      accessLevel: AccessLevel.READ
    },
    isActive: isItemActive(activeItemConfig.manageRoles),
    enabledFeatures: [systemConstants.features.roles]
  },
  {
    name: i18nText("admin:ui.navigation.heading.clientManagement.label"),
    type: menuItemTypes.NAV_SECTION_TITLE,
    context: sideNavContext.ADMIN,
    permission: {
      resource: ResourceName.CLIENTS,
      accessLevel: AccessLevel.READ
    }
  },
  {
    name: i18nText("admin:ui.navigation.manageClients.label"),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    link: routeConstants.manageClients,
    permission: {
      resource: ResourceName.CLIENTS,
      accessLevel: AccessLevel.READ
    },
    isActive: isItemActive(activeItemConfig.manageClients),
    icon: {
      name: "apartment"
    }
  },
  {
    name: i18nText("admin:ui.navigation.heading.other.label"),
    type: menuItemTypes.NAV_SECTION_TITLE,
    context: sideNavContext.ADMIN,
    permission: {
      resource: ResourceName.CLIENTS,
      accessLevel: AccessLevel.READ
    }
  },
  {
    name: i18nText("common:ui.sideNavigation.admin.auditReport"),
    link: routeConstants.admin.manageAudits,
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    permission: {
      resource: ResourceName.AUDIT_REPORT,
      accessLevel: AccessLevel.READ
    },
    icon: {
      name: "report"
    }
  },
  {
    name: i18nText("common:ui.sideNavigation.admin.globalTags"),
    link: routeConstants.admin.globalTags,
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    icon: {
      name: "sell"
    },
    enabledFeatures: [features.globalTags]
  },
  {
    name: i18nText("common:ui.sideNavigation.admin.dataExtraction"),
    link: routeConstants.dataExtraction,
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    icon: {
      name: "summarize"
    },
    permission: {
      resource: ResourceName.DATA_EXTRACTION,
      accessLevel: AccessLevel.READ
    },
    enabledFeatures: [features.dataExtraction]
  },
  {
    name: i18nText("common:ui.sideNavigation.admin.manageNews"),
    link: routeConstants.manageNews,
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    icon: {
      name: "newspaper"
    },
    permission: {
      resource: ResourceName.MANAGE_NEWS_AND_IMAGE,
      accessLevel: AccessLevel.READ
    },
    enabledFeatures: [features.manageNews]
  },
  {
    name: i18nText("common:ui.sideNavigation.admin.manageRequestTypes"),
    link: routeConstants.admin.manageRequestTypes,
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    isActive: isItemActive(activeItemConfig.manageRequestTypes),
    icon: {
      name: "pending_actions"
    },
    permission: {
      resource: ResourceName.REQUEST_TYPES,
      accessLevel: AccessLevel.READ
    }
  }
];

/**
 *
 * @param {Object} options
 * @param {Object} options.user
 * @param {number} options.clientId
 * @param {string} [options.projectId] //from useParams, and used for `navigate`.
 * @param {Object} options.project
 * @param {Object} options.interactiveReportMenusData
 * @param {*} options.i18nText
 * @param {String} [options.currentSideNavContext]
 * @param {Object} [options.pathname]
 * @returns
 */

const getSideMenuItems = ({
  user,
  clientId,
  projectId,
  project,
  interactiveReportMenusData,
  i18nText,
  currentSideNavContext
}) => {
  if (!user.isLoggedIn) {
    return [];
  }
  const interactiveReportMenuItems =
    interactiveReportMenusData?.map(mapIRMenus(project)) ?? [];
  const sideNav = [
    {
      name: i18nText("common:ui.dashboard.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      icon: {
        custom: "dashboard"
      },
      link: `${routeConstants.projects}/${projectId}`,
      context: sideNavContext.PROJECT
    },
    {
      name: i18nText("requests:requests.ui.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      icon: {
        name: "pending_actions",
        fillStyle: IconFillStyle.FILLED,
        designStyle: IconDesignStyle.MATERIAL_ICONS,
        color: IconColor.INHERIT
      },
      link: `/projects/${projectId}/queries`,
      context: sideNavContext.PROJECT,
      isActive: isItemActive(activeItemConfig.queries),
      permission: {
        resource: ResourceName.REQUESTS,
        accessLevel: AccessLevel.READ
      }
    },
    {
      name: i18nText("risks:risks.ui.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      icon: {
        name: "bubble_chart",
        fillStyle: IconFillStyle.FILLED,
        designStyle: IconDesignStyle.MATERIAL_ICONS,
        color: IconColor.INHERIT
      },
      enabledFeatures: [systemConstants.features.projectRisks],
      link: `/projects/${projectId}/risks`,
      context: sideNavContext.PROJECT,
      permission: {
        resource: ResourceName.RISKS,
        accessLevel: AccessLevel.READ
      }
    },
    {
      name: i18nText("common:ui.documents.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      icon: {
        name: "draft",
        designStyle: "material-symbols",
        color: IconColor.INHERIT,
        fillStyle: IconFillStyle.OUTLINED
      },
      link: `/projects/${projectId}/data-repository`,
      state: { project },
      context: sideNavContext.PROJECT,
      permission: {
        resource: ResourceName.PROJECT_DOCUMENTS,
        accessLevel: AccessLevel.READ
      }
    },
    {
      name: i18nText("interactiveReport:interactiveReport.ui.navigation.title"),
      type: menuItemTypes.NAV_SECTION_TITLE,
      icon: {
        name: "bar_chart",
        designStyle: IconDesignStyle.MATERIAL_ICONS,
        color: IconColor.INHERIT,
        fillStyle: IconFillStyle.FILLED
      },
      enabledFeatures: [systemConstants.features.interactiveReport],
      context: sideNavContext.PROJECT,
      permission: {
        resource: ResourceName.INTERACTIVE_REPORT,
        accessLevel: AccessLevel.READ
      }
    },
    ...(project ? interactiveReportMenuItems : []),
    {
      name: i18nText(
        "interactiveReport:interactiveReport.ui.navigation.uploadTitle"
      ),
      type: menuItemTypes.NAV_LINK,
      hostOnly: true,
      icon: {
        name: "upload",
        designStyle: IconDesignStyle.MATERIAL_ICONS,
        color: IconColor.INHERIT
      },
      link: `/projects/${projectId}/upload-interactive-report`,
      enabledFeatures: [systemConstants.features.interactiveReport],
      context: sideNavContext.PROJECT,
      permission: {
        resource: ResourceName.INTERACTIVE_REPORT,
        accessLevel: AccessLevel.CREATE
      }
    },
    ...(interactiveReportMenuItems?.length > 0
      ? [
          {
            name: i18nText(
              "interactiveReport:interactiveReport.ui.navigation.aiReporting"
            ),
            type: menuItemTypes.NAV_LINK,
            hostOnly: true,
            icon: {
              name: "psychology",
              designStyle: IconDesignStyle.MATERIAL_ICONS,
              color: IconColor.INHERIT
            },
            link: `/projects/${projectId}/ai-reporting`,
            enabledFeatures: [
              systemConstants.features.interactiveReport,
              systemConstants.features.aiReporting
            ],
            context: sideNavContext.PROJECT,
            permission: {
              resource: ResourceName.INTERACTIVE_REPORT,
              accessLevel: AccessLevel.READ
            }
          }
        ]
      : []),
    {
      type: menuItemTypes.NAV_SECTION_END,
      enabledFeatures: [systemConstants.features.interactiveReport],
      context: sideNavContext.PROJECT,
      permission: {
        resource: ResourceName.INTERACTIVE_REPORT,
        accessLevel: AccessLevel.READ
      }
    },
    {
      name: i18nText("common:ui.projectSummary.header.projectSettings.label"),
      type: menuItemTypes.NAV_LINK,
      permission: project?.isMember
        ? {
            resource: ResourceName.PROJECTS_WITH_MEMBERSHIP,
            accessLevel: AccessLevel.UPDATE
          }
        : {
            resource: ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
            accessLevel: AccessLevel.UPDATE
          },
      icon: {
        name: "settings",
        designStyle: IconDesignStyle.MATERIAL_ICONS,
        color: IconColor.INHERIT,
        fillStyle: IconFillStyle.FILLED
      },
      link: `/projects/${projectId}/edit`,
      context: sideNavContext.PROJECT
    },
    ...getAdminMenuItems({ clientId: user.clientId, i18nText }), //clientId will only be needed by client type user for accessing the manage client users page directly
    ...getClientMenuItems({ user, clientId, i18nText })
  ];

  const navigationItems = getRelevantItems(sideNav, currentSideNavContext);
  return navigationItems;
};
const getTopMenuItems = ({
  user,
  i18nText,
  clientsForMenu,
  isClientsLoading,
  projectsForMenu
}) => {
  if (!user.isLoggedIn) {
    return [];
  }
  const topItems = [
    {
      name: i18nText("common:ui.home.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      link: routeConstants.clientDashboard,
      isActive: isItemActive(activeItemConfig.home)
    },
    {
      name: i18nText("common:ui.engagement.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      hostOnly: true,
      link: routeConstants.manageClientEngagements,
      enabledFeatures: [systemConstants.features.engagements],
      isActive: isItemActive(activeItemConfig.engagement)
    },
    {
      name: i18nText("common:ui.projects.navigation.title"),
      type: menuItemTypes.PROJECT_MULTILEVEL_DROPDOWN,
      hostOnly: true,
      isActive: isItemActive(activeItemConfig.projects),
      placeholder: i18nText("common:ui.forms.searchByClient.label"),
      items:
        clientsForMenu?.map(client => ({
          name: client.name,
          id: client.id,
          type: menuItemTypes.NAV_LINK
        })) ?? [],
      isLoading: isClientsLoading
    },
    {
      name: i18nText("common:ui.projects.navigation.title"),
      type: menuItemTypes.NAV_SEARCHABLE_DROPDOWN,
      clientOnly: true,
      isActive: isItemActive(activeItemConfig.projects),
      placeholder: i18nText("common:ui.forms.searchProjects.label"),
      items:
        projectsForMenu?.map(project => ({
          name: project.name,
          id: project.id,
          year: project.year,
          type: menuItemTypes.NAV_LINK
        })) ?? []
    },
    {
      name: i18nText("common:insights.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: {
        resource: ResourceName.INSIGHTS,
        accessLevel: AccessLevel.READ
      },
      isActive: isItemActive(activeItemConfig.insightsBoard),
      enabledFeatures: [systemConstants.features.insights],
      link: routeConstants.insightsBoard
    }
  ];
  const topNav = getRelevantItems(topItems);
  return topNav;
};

const getProfileMenuItems = ({ handleLogout, i18nText }) => [
  {
    name: i18nText("common:ui.sideNavigation.manageProfile"),
    path: routeConstants.profile,
    icon: "account_circle"
  },
  {
    name: i18nText("common:ui.sideNavigation.logout"),
    path: routeConstants.logout,
    icon: "exit_to_app",
    onClick: handleLogout
  }
];

export const menuItems = {
  getTopMenuItems,
  getProfileMenuItems,
  getCurrentSideNavContext,
  getSideMenuItems
};
