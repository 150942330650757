import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { utilities } from "@shared/helpers/utilities";
import { useGuardedNavigate } from "@shared/hooks/useGuardedNavigate";
import { useModalContent } from "@shared/hooks/useModalContent";
import { useSecondaryNav } from "@shared/hooks/useSecondaryNav";
import { useUserProfile } from "@shared/hooks/useUserProfile";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import {
  classNames as classNamesFunc,
  returnStringIfTrue
} from "@app/helpers/componentHelpers";
import { useLastKnownPath } from "@app/hooks/useLastKnowPath";

import { Stack } from "@fermions";

import Breadcrumbs from "@components/atoms/Breadcrumbs/Breadcrumbs";
import AskHelp from "@components/molecules/AskHelp/AskHelp";
import Loading from "@components/molecules/Loading/Loading";
import ApplicationNotification from "@components/organisms/ApplicationNotification";
import Footer from "@components/organisms/Footer";
import Heading from "@components/organisms/Heading/Heading";
import SideMenu from "@components/organisms/SideMenu/SideMenu";

import PageBanner from "./PageBanner";
import PageHeader from "./PageHeader";
import PageModal from "./PageModal";
import "./PageTemplate.scss";
import variables from "./exportables.module.scss";
import {
  defaultProps,
  getBodySecondaryStyle,
  propTypes
} from "./pageTemplateHelper";

/**
 *
 * @param   {Object}  props
 *
 * @param   {Object}  props.header
 * @param   {string}  props.header.title Heading text for the page
 * @param   {boolean} [props.header.hasTitleTooltip] If title has tooltip
 * @param   {JSX.Element|string}  [props.header.subtitle] Subheading text for the page
 * @param   {JSX.Element} [props.header.subtitleActionIcon] Clickable icon next to the subtitle
 * @param   {boolean} [props.header.sticky] If heading is sticky on scroll
 * @param   {JSX.Element} [props.header.icon] Icon to display in heading
 * @param   {JSX.Element} [props.header.actions] Heading actions (displays at top right of heading)
 * @param   {JSX.Element} [props.header.content] Heading content (displays in container under heading)
 * @param   {Object[]}  [props.header.breadcrumbs]
 * @param   {boolean}  [props.header.alwaysShowBreadcrumbs]
 * @param   {string}  [props.header.tagline]
 * @param   {string}  [props.header.context]
 *
 * @param   {Object}  props.body
 * @param   {JSX.Element} [props.body.header] Items to appear on top of the primary/secondary body
 * @param   {JSX.Element} props.body.primary Items to appear in primary body
 * @param   {JSX.Element} [props.body.secondary] Items to appear in secondary body
 * @param   {string} [props.body.secondaryWidth] Width of secondary - default to not specified
 * @param   {string} [props.body.secondaryMaxWidth] Max width of secondary - default to not specified
 * @param   {string} [props.body.secondaryMinWidth] Min width of secondary - default to not specified
 * @param   {boolean} [props.body.secondaryOnRight] If body secondary appears on right - default false
 *
 * @param   {Object}  props.other
 * @param   {string} [props.other.error] If content has an error (displays at top of body)
 * @param   {string} [props.other.whiteSpaceStyle] style for error message, default is normal, use pre-line for auto change line
 * @param   {string | boolean} [props.other.loading] If content is loading (replaces body with loading message until other.loading is null)
 * @param   {string} [props.other.help] If content has no data
 * @param   {string} [props.other.pageReplacementMessage] e.g. "No report generated" - replaces the body content
 * @param   {number}  [props.other.smallPageSize] Size which the secondary body element moves from left/right to top of page (default 1400)
 * @param   {boolean}  [props.other.isolationMode] If page is isolated from navigation
 * @param   {boolean}  [props.other.hideFooter] If footer is hidden
 * @param   {boolean}  [props.other.fullScreenContent] If content takes up all available space
 * @param   {Object}  [props.other.project] If there is a project in the context of this page
 * @param   {Object}  [props.other.client] If there is a client in the context of this page
 * @param   {Object}  [props.other.secondaryNavDisabled] If secondary Nav is disabled for the page
 *
 * @param   {Object}  [props.classNames]
 * @param   {string} [props.classNames.page] Class for page template
 * @param   {string} [props.classNames.banner] Class for banner if any
 *
 * @param   {Object}  [props.sidePanel]
 * @param   {boolean} [props.sidePanel.open] If side panel is open
 * @param   {JSX.Element} [props.sidePanel.content] Items to appear in side panel - recommended to pass in <SidePanelContentTemplate />
 * @param   {string} [props.sidePanel.width] Width of side panel for e.g. "35vw" (optional) - default set in theme variables
 * @param   {boolean} [props.sidePanel.hasSlider] If side panel has slider
 *
 * @param   {Object}  [props.modal]
 * @param   {boolean} [props.modal.open] If modal is open
 * @param   {JSX.Element} [props.modal.content] Items to appear in modal - recommended to use <ModalContentTemplate />
 * @param   {boolean} [props.skipSavePath] If true, the last known path will not be saved
 * @param   {React.UIEventHandler} [props.onPageScroll] Callback function to be called when page is scrolled
 *
 * @component
 * @example
 * const project = ...;
 * return (
 *   <PageTemplate
 *      header={{ title: 'Report', sticky: true}}
 *      body={{ primary: (<Button/>) }}
 *    />
 * )
 */

const SECONDARY_SIDEBAR_MIN_WIDTH = 400;

// Updated page template to be used for all
const PageTemplate = ({
  header,
  body,
  other,
  classNames,
  sidePanel,
  modal,
  skipSavePath,
  onPageScroll
}) => {
  useLastKnownPath(skipSavePath);
  const { i18n } = useTranslation();
  const location = useLocation();
  const windowSize = useWindowSize();
  const { userProfile } = useUserProfile();
  const { setUnsaved, getUnsavedPopup, handleNavigate } = useGuardedNavigate();
  const { width: secondaryNavWidth } = useSecondaryNav();
  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = useModalContent();
  const [subNavActive, setSubNavActive] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [previousSidePanelWidth, setPreviousSidePanelWidth] = useState(
    SECONDARY_SIDEBAR_MIN_WIDTH + "px"
  );
  const [pageContentReplacement, setPageContentReplacement] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const sidePanelRef = useRef(null);
  const bodyContentStyle = useMemo(() => {
    return {
      paddingTop: subNavActive ? variables.navigationBarHeight : 0
    };
    // Below line is to ensure the body content style updates with subNavActive update and header.title update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryNavWidth, header.title, subNavActive, other.isolationMode]);

  const bodySecondaryStyle = useMemo(() => {
    return getBodySecondaryStyle(
      windowSize.width,
      other.smallPageSize,
      body.secondaryWidth,
      body.secondaryMaxWidth,
      body.secondaryMinWidth,
      variables.pageHeaderHeight,
      variables.navigationBarHeight,
      variables.bodyPadding
    );
  }, [
    windowSize.width,
    other.smallPageSize,
    body.secondaryWidth,
    body.secondaryMaxWidth,
    body.secondaryMinWidth
  ]);
  if (!sidePanel.width) {
    sidePanel.width = variables.sidePanelWidth;
  }

  const handleClearNotificationMessage = useCallback(() => {
    utilities.clearExternalLinkObjectFromSessionLastKnownPath();
  }, []);
  useEffect(() => {
    setUnsaved(other.unsaved || false);
  }, [other.unsaved, setUnsaved]);

  const transferPxToNum = useCallback(px => {
    return Number(px.substring(0, px.length - 2));
  }, []);
  const transferPxToVw = useCallback(
    px => {
      return (transferPxToNum(px) / innerWidth) * 100 + "vw";
    },
    [transferPxToNum]
  );

  const updateWidths = useCallback(() => {
    if (!sidePanel.open) {
      sidePanelRef.current.style.width = `0px`;
    } else {
      sidePanelRef.current.style.width = previousSidePanelWidth;
    }
  }, [previousSidePanelWidth, sidePanel.open]);

  // This prevents body from scrolling when using page template as scrolling is handled in either body or side panel
  useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = overflow;
    };
  }, []);

  useEffect(() => {
    if (other.loading) {
      setPageContentReplacement(
        <Loading
          message={typeof other.loading === "string" ? other.loading : ""}
        />
      );
    } else if (other.help?.length > 0) {
      setPageContentReplacement(<AskHelp message={other.help} />);
    } else if (other.pageReplacementMessage?.length > 0) {
      setPageContentReplacement(
        <AskHelp message={other.pageReplacementMessage} iconName={""} />
      );
    } else {
      setPageContentReplacement(null);
    }
  }, [other.loading, other.help, other.pageReplacementMessage]);

  useEffect(() => {
    updateWidths();
  }, [updateWidths, sidePanel.open]);

  useEffect(() => {
    const languagePreference =
      userProfile?.properties?.languagePreferences?.UILanguage;
    if (languagePreference && i18n.language !== languagePreference) {
      i18n.changeLanguage(languagePreference);
    }
  }, [userProfile, i18n, i18n.language]);

  useEffect(() => {
    if (location?.state?.externalLinkObject?.message) {
      setNotificationMessage(location.state.externalLinkObject.message);
    }
  }, [location?.state]);

  const handleMouseDown = e => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseMove = useCallback(
    e => {
      e.preventDefault();
      const secondNavWidthVal = secondaryNavWidth.substring(
        0,
        secondaryNavWidth.length - 2
      );
      const leftNavEnable = !other.isolationMode && !other.secondaryNavDisabled;
      if (
        !isDragging ||
        window.innerWidth - e.x <= SECONDARY_SIDEBAR_MIN_WIDTH ||
        e.x <= (leftNavEnable ? secondNavWidthVal : 0)
      ) {
        return;
      }
      const newWidth = innerWidth - e.x;
      sidePanelRef.current.style.width = `${newWidth}px`;
    },
    [
      isDragging,
      other.isolationMode,
      other.secondaryNavDisabled,
      secondaryNavWidth
    ]
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setPreviousSidePanelWidth(
        transferPxToVw(sidePanelRef.current.style.width)
      );
    }
    setIsDragging(false);
  }, [isDragging, transferPxToVw]);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener("mousemove", handleMouseMove);
    container?.addEventListener("mouseup", handleMouseUp);
    return () => {
      container?.removeEventListener("mousemove", handleMouseMove);
      container?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    // Page Template
    <div
      id="page-template"
      data-testid="test-page-template"
      className={`${returnStringIfTrue(
        classNames?.page,
        classNames?.page
      )} page-template ${modal.open ? "page-template--modal-open" : ""}`}
      style={{
        flex: "auto",
        justifyContent: "space-between",
        width: "100vw"
      }}
    >
      {/* Header and Top Navigation */}
      {/* .main-page__header added for background image to apply */}
      <div
        id="page-template__header"
        className={`page-template__header main-page__header main-page__header${returnStringIfTrue(
          other.isolationMode,
          "--isolation"
        )}`}
      >
        <Heading
          subNavLeftPosition={secondaryNavWidth}
          setSubNavActive={setSubNavActive}
          isolationMode={other.isolationMode}
          handleNavigate={handleNavigate}
        />
      </div>
      {/* Body */}
      <div
        ref={containerRef}
        className={`page-template__body ${isDragging ? "dragging" : ""}`}
      >
        {/* Side Nav */}
        {!other.isolationMode && !other.secondaryNavDisabled && (
          <div
            className={classNamesFunc(["page-template__body__side-nav"])}
            style={{ width: secondaryNavWidth }}
          >
            <SideMenu
              handleNavigate={handleNavigate}
              project={other.project}
              client={other.client}
              width={secondaryNavWidth}
            />
          </div>
        )}
        {/* Content */}
        <Stack
          width="fill"
          style={{
            flexGrow: 1,
            flexShrink: 1,
            minWidth: 0,
            transition: "width 0s"
          }}
        >
          <PageBanner
            registerModal={registerModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
          {/* Breadcrumbs */}
          {(header.breadcrumbs?.length > 0 || header.alwaysShowBreadcrumbs) && (
            <Breadcrumbs
              breadcrumbs={header.breadcrumbs}
              currentPageTitle={header.title}
            />
          )}
          {header.sticky && <PageHeader header={header} />}
          <div
            id="page-template__body__content"
            className={`page-template__body__content ${returnStringIfTrue(
              other.isolationMode,
              "page-template__body__content--isolation"
            )}`}
            style={bodyContentStyle}
            onScroll={onPageScroll}
          >
            {/* Heading */}
            {!header.sticky && <PageHeader header={header} />}
            {/* Error message */}
            {other.error && (
              <div className={`page-template__body__content__error-message`}>
                <ErrorBox
                  type="component"
                  message={other.error}
                  whiteSpaceStyle={other.whiteSpaceStyle}
                />
              </div>
            )}
            {body.header && (
              <div className="page-template__body__content--body-header">
                {body.header}
              </div>
            )}
            {/* Inner */}
            <div
              id="page-template__body__content__inner"
              className={`page-template__body__content__inner${returnStringIfTrue(
                (windowSize.width || window.innerWidth) < other?.smallPageSize,
                "--small-screen"
              )} ${returnStringIfTrue(
                body.secondaryOnRight,
                "page-template__body__content__inner--secondary-on-right"
              )} ${returnStringIfTrue(
                other.fullScreenContent,
                "page-template__body__content__inner--full-screen"
              )}`}
            >
              {/* Secondary content */}
              {body.secondary && (
                <div
                  className={`page-template__body__content__inner__secondary`}
                  style={bodySecondaryStyle}
                >
                  <div
                    className={`page-template__body__content__inner__secondary__content`}
                  >
                    {body.secondary}
                  </div>
                </div>
              )}
              {/* Divider if needed */}
              {body.secondary && (
                <div
                  className={`page-template__body__content__inner__divider`}
                ></div>
              )}
              {/* Primary content */}
              {pageContentReplacement !== null ? (
                pageContentReplacement && <>{pageContentReplacement}</>
              ) : (
                <div className={`page-template__body__content__inner__primary`}>
                  {body.primary}
                </div>
              )}
            </div>
            {/* Footer */}
            {!other.fullScreenContent && !other.hideFooter && (
              <div className={`page-template__body__content__footer`}>
                <Footer />
              </div>
            )}
          </div>
        </Stack>

        {/* Side panel */}
        <div
          ref={sidePanelRef}
          className={`page-template__body__side-panel${returnStringIfTrue(
            sidePanel?.open,
            "--expanded"
          )}`}
          style={{
            paddingTop: subNavActive ? variables.navigationBarHeight : 0,
            flexShrink: 0,
            flexGrow: 1,
            transition: "width 0s",
            marginLeft: "auto"
          }}
        >
          {sidePanel?.open && sidePanel?.hasSlider && (
            <div
              className={`page-template__body__side-panel--expanded__draggable-div ${isDragging ? "__is-dragging" : ""}`}
              onMouseDown={handleMouseDown}
            ></div>
          )}
          {sidePanel?.open && sidePanel.content}
        </div>
        {/* Notification */}
        <ApplicationNotification
          type="error"
          title="Error"
          message={notificationMessage}
          clearMessage={handleClearNotificationMessage}
        />
        {/* Modal */}
        <PageModal content={modal?.content} open={modal?.open} />
        {modalOpen && (
          <PageModal content={getModalContent()} open={modalOpen} />
        )}
        {getUnsavedPopup()}
      </div>
    </div>
  );
};

PageTemplate.defaultProps = defaultProps;
PageTemplate.propTypes = propTypes;

export default PageTemplate;
