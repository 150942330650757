import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import { classNames } from "@app/helpers/componentHelpers";

import { InputLabel } from "@components/molecules/inputs/InputTemplate";

import "./OTTextAreaInput.scss";

const TextAreaInput = React.forwardRef((props, fwdRef) => {
  const {
    forceLabelFocus,
    label,
    labelDescription,
    maxLength,
    error,
    value,
    placeholder,
    disabled,
    required,
    onFocus,
    onChange,
    onBlur,
    autoFocus
  } = props;
  const [focus, setFocus] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  const getError = () => {
    if (errorMsg?.length > 0) {
      return (
        <div className="textarea-group__errmsg">
          <i className="textarea-group__errmsg-icon material-icons">error</i>
          <span>{errorMsg}</span>
        </div>
      );
    }
    return <></>;
  };

  const getErrorClass = useCallback(
    () => (errorMsg ? "error" : ""),
    [errorMsg]
  );

  const getFocusClass = useCallback(
    () => (focus || forceLabelFocus ? "focus" : ""),
    [focus, forceLabelFocus]
  );

  const getInputtedClass = useCallback(
    () => (value ? "inputted" : ""),
    [value]
  );

  const getDisabledClass = useCallback(
    () => (disabled ? "disabled" : ""),
    [disabled]
  );

  const handleOnFocus = useCallback(
    event => {
      setFocus(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const handleChange = useCallback(
    event => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(
    event => {
      setFocus(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const getThemeClass = () => {
    return `ot-textarea-group--theme-ot`;
  };

  return (
    <div
      className={classNames([
        "textarea-group",
        getErrorClass(),
        getFocusClass(),
        getDisabledClass(),
        getInputtedClass(),
        getThemeClass()
      ])}
    >
      {label && (
        <InputLabel
          label={label}
          required={required}
          disabled={disabled}
          description={labelDescription}
          externalLabelProps={{
            htmlFor: "textarea-group-default"
          }}
        />
      )}
      <textarea
        className="textarea-group__textarea"
        id="textarea-group-default"
        value={value || ""}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        autoFocus={autoFocus}
        onFocus={handleOnFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={fwdRef}
      ></textarea>
      {getError()}
    </div>
  );
});

TextAreaInput.propTypes = {
  label: PropTypes.string,
  labelDescription: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  forceLabelFocus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default TextAreaInput;
