import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const footerService = createApi({
  reducerPath: "footerApi",
  baseQuery: fetchBaseQuery({ baseUrl: systemConstants.serverURL }),
  tagTypes: ["api/build"],
  endpoints: builder => ({
    getFooterTemplate: builder.query({
      query: ({ url }) => ({
        url,
        responseHandler: response => response.text()
      })
    }),
    getServerBuild: builder.query({
      query: () => ({ url: "/api/build" }),
      providesTags: ["api/build"]
    })
  })
});

export const { useLazyGetFooterTemplateQuery, useGetServerBuildQuery } =
  footerService;
