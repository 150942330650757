import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import _ from "lodash";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import {
  useActionItemFilters,
  useAuthUser,
  useGetProjectMembers,
  useUIConfig
} from "@shared/hooks";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import { expectedActionIndicators } from "@app/helpers";
import { Project, ProjectMembers, User } from "@app/types";

import { formatActionItemTypes } from "./requestFilterUtilities";

const filterTypes = systemConstants.dashboard.actionItemsFilterType;

const getHostTeam = (members: ProjectMembers) => [
  ...(members?.hostUsers ?? [])
];
const getClientTeam = (members: ProjectMembers) => [
  ...(members?.clientUsers ?? [])
];

const lowerCaseSort = (a: string, b: string) => a.localeCompare(b);

const filterValueEquals = (filterVal: string[], memberList: User[]) => {
  if (filterVal.length !== memberList.length) {
    return false;
  }
  filterVal.sort(lowerCaseSort);
  const m = memberList.map(member => member.name).sort(lowerCaseSort);
  return filterVal.every((val, index) => val === m[index]);
};

const UserFilterTypes = {
  None: 1,
  MyActions: 2,
  ClientTeamActions: 3,
  HostTeamActions: 4
};

export function useRequestFilter(project: Project) {
  const { user } = useAuthUser();
  const { uiConfig, isUIConfigLoading } = useUIConfig();
  const actionIndicators = expectedActionIndicators();
  const { members, membersLoading } = useGetProjectMembers(project);

  const isUserEntityRestricted = user.xRole?.isEntityRestricted;

  const engagementTypeId = project?.engagement?.engagementTypeId;
  const { data: actionItemTypes, isLoading: actionItemTypesLoading } =
    useGetActionItemTypesQuery(
      {
        engagementTypeId,
        projectId: project?.id
      },
      { skip: !project?.id || !engagementTypeId || !uiConfig }
    );

  const [currentUserFilter, setCurrentUserFilter] = useState(
    UserFilterTypes.None
  );
  // This is to prevent the table header rerender after selecting assigned to/ user filter
  const currentUserFilterRef = useRef(currentUserFilter);

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    currentUserFilterRef.current = currentUserFilter;
  }, [currentUserFilter]);

  useEffect(() => {
    if (uiConfig?.queryFilters) {
      setFilterData(prevState => {
        //We don't expect the config to change after it's loaded, so we only want to set the filter data once
        if (prevState?.length > 0) {
          return prevState;
        }

        const queryFilters = structuredClone(uiConfig.queryFilters);
        const formattedFilters = queryFilters.map(filter => {
          const nonTranslatableFilterKeys = [
            "entities",
            "assignedTo",
            "requestedBy",
            "queryType"
          ];
          filter.translate = true;
          filter.label = `requests:requests.filter.query.${filter.key}.label`;
          filter.placeholder = `requests:requests.filter.query.${filter.key}.placeholder`;
          filter.options = filter.options?.map(option => {
            return {
              name: option.name ?? option,
              value: option.id ?? option.name ?? option,
              label: `requests:requests.filter.query.${filter.key}.${option}`,
              translate: !nonTranslatableFilterKeys.includes(filter.key)
            };
          });
          return filter;
        });
        return formattedFilters;
      });
    }
  }, [uiConfig.queryFilters]);

  //Set all user related filters based on project members list
  useEffect(() => {
    if (members) {
      setFilterData(prevState => {
        if (!prevState?.length) {
          return prevState;
        }
        const userOptions = members.hostUsers
          .concat(members.clientUsers)
          .map(user => {
            return {
              name: user.name,
              label: user.name,
              value: user.id,
              translate: false
            };
          });
        const updatedState = structuredClone(prevState);
        const userFilter = updatedState.find(
          filter => filter.key === "assignedTo"
        );
        if (userFilter) {
          userFilter.options = userOptions;
        }

        const lastActorFilter = updatedState.find(
          filter => filter.key === "lastActor"
        );
        if (lastActorFilter) {
          lastActorFilter.options = userOptions;
          _.remove(updatedState, filter => filter.key === "requestedBy");
        } else if (updatedState.find(filter => filter.key === "requestedBy")) {
          const requestedByFilter = updatedState.find(
            filter => filter.key === "requestedBy"
          );
          if (requestedByFilter) {
            requestedByFilter.options = userOptions;
          }
        }

        return updatedState;
      });
    }
  }, [members]);

  //Set query type filter options
  useEffect(() => {
    if (actionItemTypes) {
      setFilterData(prevState => {
        if (!prevState?.length) {
          return prevState;
        }
        const updatedState = structuredClone(prevState);
        const queryTypeFilter = updatedState.find(
          filter => filter.key === "queryType"
        );
        if (queryTypeFilter) {
          queryTypeFilter.options = formatActionItemTypes({
            actionItemTypes,
            isUserEntityRestricted
          });
        }
        return updatedState;
      });
    }
  }, [actionItemTypes, isUserEntityRestricted]);

  //Set entity filter options
  useEffect(() => {
    if (project?.entities?.length > 0) {
      setFilterData(prevState => {
        if (!prevState?.length) {
          return prevState;
        }
        const updatedState = structuredClone(prevState);
        const entityFilter = updatedState.find(
          filter => filter.key === "entities"
        );
        if (entityFilter) {
          entityFilter.options = project.entities.map(entity => {
            return {
              name: entity.name,
              label: entity.name,
              value: entity.externalId,
              translate: false
            };
          });
        }
        return updatedState;
      });
    }
  }, [project?.entities]);

  const {
    onChangeRequestFilter,
    onChangeAssignedToUsers,
    onChangeUserFilterType,
    resetActionitemTableFilter,
    requestFilter,
    userFilterType,
    assignedToUsers,
    tableFilters,
    onChangeTableFilters
  } = useActionItemFilters();

  const userIsOnlyUserInTeam = useCallback(
    teamType => {
      if (teamType === "host") {
        return (
          members.hostUsers.length === 1 && members.hostUsers[0].id === user.id
        );
      } else if (teamType === "client") {
        return (
          members.clientUsers.length === 1 &&
          members.clientUsers[0].id === user.id
        );
      }
    },
    [members.hostUsers, members.clientUsers, user.id]
  );

  const getMyUser = useCallback(() => {
    const userInfo = utilities.stripMethodsFromObject(user);
    return [userInfo];
  }, [user]);

  useEffect(() => {
    if (userFilterType) {
      setCurrentUserFilter(structuredClone(userFilterType));
    }
  }, [userFilterType]);

  const filterUsers = useMemo(() => assignedToUsers, [assignedToUsers]);

  const actionItemsFilter = useMemo(() => {
    return (
      structuredClone(requestFilter) ??
      ({} as { dueDate?: string; status?: string })
    );
  }, [requestFilter]);

  const onChangeStatusFilter = useCallback(
    indicator => {
      const updatedFilter = structuredClone(actionItemsFilter);
      if (indicator.key == actionItemsFilter.status) {
        delete updatedFilter.status;
      } else {
        updatedFilter.status = indicator.key;
      }
      onChangeRequestFilter(updatedFilter);
    },
    [actionItemsFilter, onChangeRequestFilter]
  );

  const onChangeLabelsFilter = useCallback(
    labels => onChangeTableFilters(labels, "labels"),
    [onChangeTableFilters]
  );

  const onChangeWorkflowFilter = useCallback(
    workflowSteps => onChangeTableFilters(workflowSteps, "workflowStep"),
    [onChangeTableFilters]
  );

  const onChangeQueryTypeFilter = useCallback(
    queryType => onChangeTableFilters(queryType, "queryType"),
    [onChangeTableFilters]
  );

  const onChangeCreatedByFilter = useCallback(
    createdBy => onChangeTableFilters(createdBy, "createdBy"),
    [onChangeTableFilters]
  );

  const onChangeDueDateFilter = useCallback(
    indicator => {
      const updatedFilter = structuredClone(actionItemsFilter);
      if (indicator.key == actionItemsFilter.dueDate) {
        delete updatedFilter.dueDate;
      } else {
        updatedFilter.dueDate = indicator.key;
      }
      onChangeRequestFilter(updatedFilter);
    },
    [actionItemsFilter, onChangeRequestFilter]
  );

  const onClickMyActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.MyActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.MyActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getMyUser());
  }, [
    currentUserFilter,
    getMyUser,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onClickClientTeamActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.ClientTeamActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.ClientTeamActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getClientTeam(members));
  }, [
    currentUserFilter,
    members,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onClickHostTeamActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.HostTeamActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.HostTeamActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getHostTeam(members));
  }, [
    currentUserFilter,
    members,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onAssignToFilterValueChanged = useCallback(
    (selectedUsers: { value: string }[]) => {
      const filterValue = selectedUsers.map(u => u.value);
      onChangeAssignedToUsers(selectedUsers);
      if (filterValueEquals(filterValue, [user])) {
        if (
          (userIsOnlyUserInTeam("client") &&
            currentUserFilterRef.current ===
              UserFilterTypes.ClientTeamActions) ||
          (userIsOnlyUserInTeam("host") &&
            currentUserFilterRef.current === UserFilterTypes.HostTeamActions)
        ) {
          return;
        }

        if (currentUserFilterRef.current !== UserFilterTypes.MyActions) {
          onChangeUserFilterType(UserFilterTypes.MyActions);
          return;
        }
      }
      if (filterValueEquals(filterValue, members.hostUsers)) {
        if (
          userIsOnlyUserInTeam("host") &&
          currentUserFilterRef.current === UserFilterTypes.MyActions
        ) {
          return;
        }

        if (currentUserFilterRef.current !== UserFilterTypes.HostTeamActions) {
          onChangeUserFilterType(UserFilterTypes.HostTeamActions);
          return;
        }
      }
      if (filterValueEquals(filterValue, members.clientUsers)) {
        if (
          userIsOnlyUserInTeam("client") &&
          currentUserFilterRef.current === UserFilterTypes.MyActions
        ) {
          return;
        }
        if (
          currentUserFilterRef.current !== UserFilterTypes.ClientTeamActions
        ) {
          onChangeUserFilterType(UserFilterTypes.ClientTeamActions);
          return;
        }
      }
      if (currentUserFilterRef.current !== UserFilterTypes.None) {
        onChangeUserFilterType(UserFilterTypes.None);
      }
    },
    [
      members.clientUsers,
      members.hostUsers,
      onChangeAssignedToUsers,
      onChangeUserFilterType,
      user,
      userIsOnlyUserInTeam
    ]
  );

  const setOldFilters = useCallback(
    (filters: { type: string; members: ProjectMembers }) => {
      const updatedFilter = structuredClone(actionItemsFilter);
      resetActionitemTableFilter();
      const userTypes = user?.isHostUser
        ? UserFilterTypes.HostTeamActions
        : UserFilterTypes.ClientTeamActions;
      const filteredUsers = user?.isHostUser
        ? getHostTeam(filters.members)
        : getClientTeam(filters.members);
      switch (filters.type) {
        case filterTypes.myPastDueItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.overdue.key
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamsPastDueItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.overdue.key
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myUpcomingItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.upcoming.key
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamsUpcomingItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.upcoming.key
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myActiveItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: null,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamActiveItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: null,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myOpenItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig?.indicators?.open?.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myRespondedItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig?.indicators?.responded?.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamOpenItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig?.indicators?.open?.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myTeamRespondedItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig?.indicators?.responded?.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myClosedItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig?.indicators?.closed?.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamClosedItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig?.indicators?.closed?.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        default:
          setCurrentUserFilter(UserFilterTypes.None);
      }
    },
    [
      actionIndicators.overdue.key,
      actionIndicators.upcoming.key,
      actionItemsFilter,
      getMyUser,
      onChangeAssignedToUsers,
      onChangeRequestFilter,
      onChangeUserFilterType,
      resetActionitemTableFilter,
      uiConfig?.indicators?.closed?.key,
      uiConfig?.indicators?.open?.key,
      uiConfig?.indicators?.responded?.key,
      user?.isHostUser
    ]
  );

  return {
    actionItemsFilter,
    currentUserFilter,
    filterUsers,
    onAssignToFilterValueChanged,
    onChangeCreatedByFilter,
    onChangeDueDateFilter,
    onChangeLabelsFilter,
    onChangeWorkflowFilter,
    onChangeQueryTypeFilter,
    onChangeStatusFilter,
    onClickClientTeamActionsFilter,
    onClickHostTeamActionsFilter,
    onClickMyActionsFilter,
    setOldFilters,
    tableFilters,
    filterData,
    isLoadingFilters:
      isUIConfigLoading || membersLoading || actionItemTypesLoading
  };
}
