import { useSelector } from "react-redux";

import {
  useGetUIConfigAuthorisedQuery,
  useGetUIConfigUnAuthorisedQuery
} from "@shared/services";

export function useUIConfig() {
  const authentication = useSelector(_state => _state.authentication);

  const {
    isLoading: isUnAuthorisedUIConfigLoading,
    data: unAuthorisedUIConfig
  } = useGetUIConfigUnAuthorisedQuery(
    {},
    {
      skip: authentication.loggedIn
    }
  );
  const { isLoading: isAuthorisedUIConfigLoading, data: authorisedUIConfig } =
    useGetUIConfigAuthorisedQuery(
      {},
      {
        skip: !authentication.loggedIn
      }
    );

  if (authentication?.loggedIn) {
    return {
      uiConfig: authorisedUIConfig ?? {},
      isUIConfigLoading: isAuthorisedUIConfigLoading
    };
  }
  return {
    uiConfig: unAuthorisedUIConfig ?? {},
    isUIConfigLoading: isUnAuthorisedUIConfigLoading
  };
}
