import React, { useEffect, useState } from "react";

import { validate } from "email-validator";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { routeConstants } from "@constants";

import { useHostLanguage, useStaticAssets } from "@shared/hooks";

import Title from "@atoms/Title/Title.tsx";

import Loading from "@components/molecules/Loading/Loading";

import { authActions } from "../../../actions";
import ErrorBox from "../../errorBox/ErrorBox";
import AuthContainer from "../authContainer/AuthContainer";
import "./ForgotPassword.scss";
import { getRecaptchaToken } from "./utilities/getRecaptchaToken";

const ForgotPassword = () => {
  const authentication = useSelector(state => state.authentication);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [email, setEmail] = useState(null);
  const [errors, setError] = useState(null);
  const { hostLanguage } = useHostLanguage();
  const [formState, setFormState] = useState({
    isSubmitted: false,
    isValid: false
  });
  const { getUrl, setFavIcon } = useStaticAssets();

  const siteKey = authentication.host?.host.properties.captcha.siteKey;

  useEffect(() => {
    if (!authentication.host) {
      dispatch(authActions.getHostWithBrandDetails());
    }
    if (authentication.host) {
      document.title = authentication.host.name;
      setFavIcon();
    }
    return () => dispatch(authActions.resetForgotPassword());
    // eslint-disable-next-line
  }, [authentication.host, setFavIcon]);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    setSubmitSuccess(null);
    if (authentication.error) {
      setSubmitSuccess(false);
    }
    if (authentication.forgotPassWord) {
      setSubmitSuccess(true);
    }
    setFormState({
      ...formState,
      isSubmitted: true
    });
    // eslint-disable-next-line
  }, [authentication]);

  const onSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(authActions.clearError());
    if (!email || !validate(email)) {
      setError({ email: "invalidFormat" });
      setFormState({
        ...formState,
        isSubmitted: true,
        isValid: false
      });
      return;
    }

    const { token } = await getRecaptchaToken(siteKey);
    if (!token) {
      return;
    }

    dispatch(authActions.forgotPassword(email, token));
  };

  const handleInputChange = event => {
    setError(null);
    setFormState({
      ...formState,
      isSubmitted: false,
      isValid: true
    });
    if (event.target.value) {
      setEmail(event.target.value);
    } else {
      setEmail(null);
    }
  };

  if (
    !authentication.host ||
    (hostLanguage && hostLanguage !== i18n.language)
  ) {
    return (
      <div className="page-overlay">
        <Loading />
      </div>
    );
  }

  return (
    <AuthContainer>
      <div className="auth-container__host-header">
        <Title
          className="auth-container__host-header-name forgot-password-title"
          title={t("common:authentication.forgotPassword.title")}
        />
        <img
          src={getUrl("authLogo.png")}
          alt="Logo"
          className="auth-container__host-header-logo"
        />
      </div>

      {formState.isSubmitted && submitSuccess === true ? (
        <div
          data-testid="forgot-password-success"
          className="auth-container__success"
        >
          <i className="material-icons auth-container__success-icon">email</i>
          <p>
            <Trans
              i18nKey={"common:authentication.forgotPassword.sentConfirmation"}
              email={email}
            >
              We have sent you an email to <strong>{{ email }}</strong>, please
              follow the instructions in the email to reset your password.
            </Trans>
          </p>
          <Link to={routeConstants.login} className="auth-container__link">
            <i className="material-icons">keyboard_arrow_left</i>{" "}
            {t("common:authentication.forgotPassword.backToLogin")}
          </Link>
        </div>
      ) : (
        <form
          onSubmit={onSubmit}
          className="forgot-password-form"
          data-testid="forgot-password-form"
        >
          {authentication.loading && (
            <div className="forgot-password-form__spinner">
              <Loading />
            </div>
          )}
          {formState.isSubmitted && submitSuccess === false ? (
            <ErrorBox
              type="component"
              message={
                authentication.error?.message ??
                "There was a problem sending you the reset password email, please try again later."
              }
              locizeContext={authentication.error?.locizeContext}
            ></ErrorBox>
          ) : (
            ""
          )}
          <p>{t("common:authentication.forgotPassword.message")}</p>

          <div className="form-group forgot-password__formfields">
            <label htmlFor="email" className="form-label">
              {t("common:authentication.forgotPassword.email")}
            </label>
            <input
              type="text"
              className={"form-control" + (errors?.email ? " is-invalid" : "")}
              name="email"
              data-testid="forgot-password-email"
              placeholder={t(
                "common:authentication.forgotPassword.emailPrompt"
              )}
              autoFocus={true}
              autoComplete="off"
              onChange={handleInputChange}
            />
            <label htmlFor="email" className="form-label-captcha">
              {t("common:authentication.forgotPassword.captchaPolicy")}
              <a href="https://policies.google.com/privacy">
                {t("common:authentication.forgotPassword.captchaPolicyLink1")}
              </a>
              {t("common:authentication.forgotPassword.captchaPolicyJoin1")}
              <a href="https://policies.google.com/terms">
                {t("common:authentication.forgotPassword.captchaPolicyLink2")}
              </a>
              {t("common:authentication.forgotPassword.captchaPolicyJoin2")}
            </label>
            {errors?.email && (
              <span
                data-testid="forgot-password-email-error"
                className="field-error-message"
              >
                {t("common:authentication.forgotPassword.emailError")}
              </span>
            )}
          </div>
          <div className="auth-container__actions">
            <Link
              to={routeConstants.login}
              className="auth-container__link pull-left"
              data-testid="forgot-password-login-link"
            >
              <i className="material-icons">keyboard_arrow_left</i>{" "}
              {t("common:authentication.forgotPassword.backToLogin")}
            </Link>
            <button
              className="g-recaptcha btn primary ot-button ot-button--variant-primary ot-button--size-default"
              type="submit"
              data-testid="forgot-password-submit"
              disabled={!formState.isValid || authentication.loading}
            >
              {t("common:authentication.forgotPassword.resetPassword")}
            </button>
          </div>
        </form>
      )}
    </AuthContainer>
  );
};

export default ForgotPassword;
