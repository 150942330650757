import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { roleNameDisplay } from "@shared/helpers/roleHelper";
import { useAuthUser, useFeatures, useGetClientByIdQuery } from "@shared/hooks";
import { useClientUserProfile } from "@shared/hooks/useClientUserProfile";
import { useAddClientUserMutation } from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";

import UserForm from "@components/organisms/UserForm/UserForm";

import "./AddClientUser.scss";

const AddClientUser = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { data: client, isError } = useGetClientByIdQuery(clientId);
  const [
    addClientUser,
    { isSuccess: isClientUserAdded, isLoading, error: addError }
  ] = useAddClientUserMutation();
  const { isExternalClientUsersEnabled } = useFeatures();

  const navigate = useNavigate();
  const { user } = useAuthUser();
  const {
    roles,
    clientUserFields,
    projectsList,
    error: roleError
  } = useClientUserProfile({
    client
  });

  const error = useMemo(() => addError || roleError, [addError, roleError]);

  useEffect(() => {
    if (isClientUserAdded) {
      navigate(`/admin/clients/${clientId}/users`);
    }
  }, [clientId, isClientUserAdded, navigate]);

  const breadcrumbs = () => {
    const result = [];
    if (user.isHostUser) {
      result.push({
        linkTo: routeConstants.manageClients,
        label: t("common:ui.manageClients.title")
      });
    }
    result.push({
      linkTo: `/admin/clients/${clientId}/users`,
      label: !user.isHostUser
        ? t("common:ui.manageUsers.title", { context: "CLIENT" })
        : client &&
          t("common:ui.manageUsers.titleWithClientName", {
            clientName: client.name,
            context: "CLIENT"
          })
    });

    return result;
  };
  const addConfigKey = key =>
    clientUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        role: "role.name",
        type: "dropdown",
        required: true,
        items: roles?.map(role => ({
          ...role,
          name: roleNameDisplay({ role, t }),
          value: role.name
        })),
        translate: true
      };
    }
    return {};
  };

  useEffect(() => {
    if (!clientId || isError) {
      navigate(routeConstants.notFound);
    }
  }, [clientId, isError, navigate]);

  const handleSubmit = user => {
    if (!user.roleId) {
      user.roleId = roles?.[0]?.id;
    }
    user.clientId = client.id;
    addClientUser({ user });
  };
  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true
    },
    addConfigKey("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      items: projectsList,
      defaultValue: [],
      placeholder: t("common:user.configured.fields.projects.recommended")
    },
    ...(isExternalClientUsersEnabled
      ? [addConfigKey("EXTERNAL_USERNAME")]
      : []),
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];

  return (
    <UserForm
      title={
        !user.isHostUser
          ? t("common:ui.manageUsers.addTitle_team")
          : t("common:ui.manageUsers.addTitle_client")
      }
      breadcrumbs={breadcrumbs()}
      formConfig={formConfig}
      handleSubmit={handleSubmit}
      submitLabel={t("common:ui.manageUsers.addSubmit", { context: "CLIENT" })}
      loading={isLoading}
      other={{
        error: error ? getErrorMessage(error, t) : ""
      }}
    />
  );
};

export default AddClientUser;
