import React, { useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import TextInput from "@components/atoms/TextInput";

import "./TextField.scss";

function TextField(props) {
  const {
    formState: { errors },
    getValues,
    setValue,
    control
  } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (!currentValue || (currentValue.length === 0 && props.defaultValue)) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: props.required }}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <TextInput
            label={props.label}
            disabled={props.disabled}
            visible={props.visible}
            ref={ref}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            value={getValues(props.name) || ""}
            required={props.required}
            datalist={props.datalist}
            placeholder={props.placeholder}
            onChange={newVal => {
              onChange(newVal);
              props.onChange?.(newVal);
            }}
            onFocus={props.onFocus}
            onBlur={
              props.onBlur
                ? () => {
                    onBlur();
                    props.onBlur();
                  }
                : onBlur
            }
            maxLength={props.maxLength}
            style={props.style || {}}
          />
        );
      }}
    />
  );
}

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  datalist: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string)
  }),
  visible: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  style: PropTypes.object
};

export default TextField;
