import React, { useCallback, useEffect, useState } from "react";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants";
import { useLazyGetWorkflowStepQuery } from "@shared/hooks";

import { workflowActionName } from "@app/helpers";

import { ActionDropdown } from "@molecules/ActionDropdown";

import ActionConfirmation from "@organisms/QueryActions/ActionConfirmation";

const ActionItemsTableDropdownMenu = ({
  cell,
  displayCell = () => true,
  menuItemClickHandler,
  defaultProps,
  t
}) => {
  const [menuList, setMenuList] = useState([...cell.value]);
  const queryId = cell.row.original.id;

  const [isRefetch, setIsRefetch] = useState(false);
  const [fetchWorkflowStep, { data: workflowSteps }] =
    useLazyGetWorkflowStepQuery();
  const [confirmationContent, setConfirmationContent] = useState({
    title: null,
    message: null,
    translate: null,
    confirmKey: null
  });

  const resetIsFetch = useCallback(() => {
    setIsRefetch(true);
  }, [setIsRefetch]);

  const useFetchWorkflowStep = useCallback(
    (queryId, queryType, workflowStep) => {
      return () => {
        if (
          queryType !== systemConstants.actionItemTypes.websheet &&
          workflowStep &&
          !isRefetch
        ) {
          fetchWorkflowStep({ queryId });
          resetIsFetch();
        }
      };
    },
    [fetchWorkflowStep, isRefetch, resetIsFetch]
  );

  useEffect(() => {
    let updatedMenuList = [...(cell.value || [])];

    if (workflowSteps) {
      const newMenuList = workflowSteps.nextActions.map(item => ({
        name: workflowActionName({ workflowAction: item, t }),
        key: item.key,
        action: item.key,
        beforeUserTriggers: item.config.beforeUserTriggers
      }));
      updatedMenuList = [...updatedMenuList, ...newMenuList];
    }

    setMenuList(updatedMenuList);
  }, [cell.value, queryId, workflowSteps]);

  const handleCloseModal = () => {
    setConfirmationContent({
      title: null,
      message: null,
      translate: null,
      confirmKey: null
    });
  };

  const handleActionConfirmation = () => {
    handleCloseModal();
    setIsRefetch(false);
    if (confirmationContent && confirmationContent.confirmKey) {
      menuItemClickHandler({
        menuItem: { action: confirmationContent.confirmKey },
        cell,
        confirmed: true
      });
    }
  };

  const getWorkflowConfirmationModal = () => {
    if (
      !confirmationContent ||
      !confirmationContent.title ||
      !confirmationContent.message
    ) {
      return;
    }
    return (
      <Popup
        visibility={true}
        handleOutsideClick={handleCloseModal}
        width="50rem"
      >
        <ActionConfirmation
          title={confirmationContent.title}
          message={confirmationContent.message}
          handleSubmit={handleActionConfirmation}
          handleCancel={handleCloseModal}
          error=""
        />
      </Popup>
    );
  };

  const onOpenMenu = useFetchWorkflowStep(
    queryId,
    cell.row.original.configType,
    cell.row.original.workflowStep
  );

  const onMenuItemClick = useCallback(
    ({ menuItem, cell }) => {
      if (menuItem.beforeUserTriggers) {
        setConfirmationContent({
          title: menuItem.beforeUserTriggers?.[0]?.title,
          message: menuItem.beforeUserTriggers[0].message,
          translate: menuItem.beforeUserTriggers[0].translate,
          confirmKey: menuItem.key
        });
      } else if (menuItem.action) {
        menuItemClickHandler({ menuItem, cell });
        setIsRefetch(false);
      }
    },
    [menuItemClickHandler]
  );

  return (
    <>
      {cell.value?.length && displayCell(cell.row.id) ? (
        <ActionDropdown
          {...defaultProps}
          menuItems={menuList}
          onMenuItemClick={menuItem => onMenuItemClick({ menuItem, cell })}
          onOpenMenu={onOpenMenu}
        />
      ) : (
        <></>
      )}
      {getWorkflowConfirmationModal()}
    </>
  );
};

export default ActionItemsTableDropdownMenu;
