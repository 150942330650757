import { useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  setMenuItemExpanded,
  setSecondaryNav
} from "@shared/features/secondaryNav/secondaryNavSlice";

import variables from "@components/templates/PageTemplate/exportables.module.scss";
import { defaultProps } from "@components/templates/PageTemplate/pageTemplateHelper";

const calcWidth = (flag: boolean) =>
  flag ? variables.sideNavWidthExpanded : variables.sideNavWidth;
const isolationMode = defaultProps.other.isolationMode;

const setMenuItemExpandedAction = setMenuItemExpanded;

export function useSecondaryNav() {
  const dispatch = useDispatch();
  const secondaryNavSlice = useSelector(state => state.secondaryNavSlice);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { context, isExpanded, isProjectExpanded, ...menuItems } =
    secondaryNavSlice;

  const width = useMemo(() => {
    if (isolationMode) {
      return "0";
    }
    return calcWidth(isExpanded);
  }, [isExpanded]);

  const setExpanded = useCallback(
    ({ context, isExpanded }) => {
      dispatch(setSecondaryNav({ context, isExpanded }));
    },
    [dispatch]
  );

  const toggleExpanded = useCallback(
    () => setExpanded({ context, isExpanded: !isExpanded }),
    [isExpanded, setExpanded, context]
  );

  const setMenuItemExpanded = useCallback(
    ({ id, isExpanded }) => {
      dispatch(setMenuItemExpandedAction({ id, isExpanded }));
    },
    [dispatch]
  );

  const getMenuItemExpanded = (id: string) => menuItems[id];

  return {
    width,
    toggleExpanded,
    isExpanded,
    setExpanded,
    expandedContext: context,
    setMenuItemExpanded,
    getMenuItemExpanded
  };
}
