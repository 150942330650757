import React, { useCallback, useEffect } from "react";

import PropTypes from "prop-types";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useClientDocumentDownloads,
  useClientDocumentRevisions,
  useToasts
} from "@shared/hooks";
import {
  useDeleteClientDocumentMutation,
  useGetArchivedClientDocumentsQuery,
  useGetClientDocumentsQuery,
  useRestoreClientDocumentMutation,
  useUpdateClientDocumentPropertiesMutation
} from "@shared/services/documentService";

import Popup from "@shared-components/popup/Popup";

import { documentActions } from "@app/helpers/documents";

import WarningModal from "@components/molecules/WarningModal/WarningModal";
import DeleteDocument from "@components/organisms/DeleteDocument";
import ReviewRevisionHistory from "@components/organisms/DocumentRevisionHistory";
import UpdateClientDocumentProperties from "@components/organisms/UpdateClientDocumentPropertiesModal";

const STATUS = systemConstants.project.document.status;
const ClientDocumentsTableActions = props => {
  const {
    clientId,
    action: { action, document },
    onFinished
  } = props;

  const { refetch: refetchDocuments } = useGetClientDocumentsQuery(
    { clientId },
    { skip: !clientId }
  );

  const { refetch: refetchArchivedDocuments } =
    useGetArchivedClientDocumentsQuery({ clientId }, { skip: !clientId });

  const [updateClientDocumentProperties, { isSuccess: updatedDocument }] =
    useUpdateClientDocumentPropertiesMutation();
  const [deleteClientDocument, { isSuccess: deletedDocument }] =
    useDeleteClientDocumentMutation();
  const [restoreClientDocument, { isSuccess: documentRestored }] =
    useRestoreClientDocumentMutation();
  const {
    fetchDocumentRevisions,
    updateDocumentRevisionToActive,
    revisions,
    activeRevisionId,
    setActiveRevisionId
  } = useClientDocumentRevisions(clientId, document);
  const { fetchDocument, downloadedDocuments } = useClientDocumentDownloads();
  const { showDocumentNameModifiedToast } = useToasts();
  const handleActionFinished = useCallback(() => {
    onFinished();
  }, [onFinished]);

  useEffect(() => {
    if (deletedDocument) {
      refetchArchivedDocuments();
      handleActionFinished();
    }
  }, [
    deletedDocument,
    handleActionFinished,
    refetchArchivedDocuments,
    refetchDocuments
  ]);

  useEffect(() => {
    if (documentRestored) {
      refetchDocuments();
      refetchArchivedDocuments();
      handleActionFinished();
    }
  }, [
    documentRestored,
    handleActionFinished,
    refetchArchivedDocuments,
    refetchDocuments
  ]);

  useEffect(() => {
    if (updatedDocument) {
      refetchDocuments();
      refetchArchivedDocuments();
      handleActionFinished();
    }
  }, [
    handleActionFinished,
    refetchArchivedDocuments,
    refetchDocuments,
    updatedDocument
  ]);

  const handleDeleteDocument = useCallback(() => {
    if (document.status === STATUS.archived) {
      deleteClientDocument({ clientId, documentId: document.id });
    } else {
      const updatedDocument = { ...document, status: STATUS.archived };
      updateClientDocumentProperties({ clientId, document: updatedDocument });
    }
  }, [
    clientId,
    deleteClientDocument,
    document,
    updateClientDocumentProperties
  ]);

  useEffect(() => {
    if (action === documentActions.restoreDocument) {
      restoreClientDocument({ clientId, document })
        .unwrap()
        .then(data => {
          if (data?.success && data.document?.name !== document.name) {
            showDocumentNameModifiedToast({
              originalName: document.name,
              newName: data.document.name
            });
          }
        });
    }
  }, [action, clientId, document, restoreClientDocument]);

  return (
    <>
      <Popup
        visibility={action === documentActions.deleteDocument}
        handleOutsideClick={false}
        width="60rem"
      >
        <DeleteDocument
          document={document}
          handleDelete={handleDeleteDocument}
          onDelete={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.reviewRevisionHistory}
        handleOutsideClick={false}
        width="80rem"
      >
        <ReviewRevisionHistory
          document={document}
          revisionOf="projectDocument"
          onCancel={handleActionFinished}
          onSubmit={handleActionFinished}
          fetchDocumentRevisions={fetchDocumentRevisions}
          updateDocumentRevisionToActive={updateDocumentRevisionToActive}
          revisions={revisions}
          activeRevisionId={activeRevisionId}
          setActiveRevisionId={setActiveRevisionId}
          fetchDocument={fetchDocument}
          downloadedDocuments={downloadedDocuments}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.updateDocumentProperties}
        handleOutsideClick={false}
        width="60rem"
      >
        <UpdateClientDocumentProperties
          clientId={clientId}
          document={document}
          onUpdated={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.uploadDocument}
        handleOutsideClick={false}
        width="60rem"
      >
        <WarningModal
          message={"coming soon"}
          title={"Upload New Revision"}
          onClickYes={handleActionFinished}
          onClickCancel={handleActionFinished}
        />
        {/* UploadDocumentRevision requires a lot more work to adjust so stubbed out for now. maybe easier to reimplement? */}
        {/* <UploadDocumentRevision
        document={document}
        projectFolder={projectFolder}
        onUpload={handleActionFinished}
        onCancel={handleActionFinished}
      /> */}
      </Popup>
    </>
  );
};

ClientDocumentsTableActions.defaultProps = {};

ClientDocumentsTableActions.propTypes = {
  clientId: PropTypes.number,
  action: PropTypes.shape({
    action: PropTypes.string.isRequired,
    document: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    project: PropTypes.shape({
      id: PropTypes.number
    }),
    projectFolder: PropTypes.shape({
      id: PropTypes.any
    })
  }),
  onFinished: PropTypes.func.isRequired
};

export default ClientDocumentsTableActions;
