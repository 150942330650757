import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { useAuthUser } from "@shared/hooks";

import { FontColor, Text } from "@atoms/Text";

import ClickableMessage from "@components/atoms/ClickableMessage/ClickableMessage";
import Form from "@components/atoms/Form";

const ProjectUserForm = props => {
  const { projectMembers, users, entities, entitiesEnabled, editMode } = props;
  const { user: authUser } = useAuthUser();
  const { t } = useTranslation();
  const { watch, resetField } = useFormContext();
  const navigate = useNavigate();

  const watchFormValues = watch();

  useEffect(() => {
    if (watchFormValues.memberOf) {
      resetField("member");
      resetField("entities");
    }
  }, [resetField, watchFormValues.memberOf]);

  const memberOptions = useMemo(() => {
    const hostOrClientUsers = watchFormValues.memberOf?.users ?? [];

    return structuredClone(hostOrClientUsers);
  }, [watchFormValues.memberOf?.users]);

  const memberOfOptions = useMemo(
    () =>
      Object.values(users)
        .filter(o => o)
        .map(optionObject => ({
          isClient: optionObject.isClient ?? false,
          users: optionObject.users,
          name: optionObject.name,
          tag: optionObject.isClient
            ? t("common:ui.user.client_other")
            : t("common:ui.user.host_other")
        })),
    [t, users]
  );

  const entitiesOptions = useMemo(
    () =>
      entities?.map(e => ({
        name: e.name,
        value: e.externalId
      })) ?? [],
    [entities]
  );

  const handleNoClientUserCallToAction = useCallback(() => {
    if (!users.client) {
      navigate(routeConstants.manageClients);
    } else {
      const urlConst = routeConstants.admin.manageClientUsers;
      const url = urlConst.replace("{{clientId}}", users.client.id);
      navigate(url);
    }
  }, [navigate, users.client]);

  const membersField = useMemo(() => {
    if (memberOptions?.length) {
      return (
        <Form.Dropdown
          name="member"
          label={t("common:ui.projectUserForm.fields.member.label")}
          items={memberOptions}
          required={true}
          disabled={editMode}
        />
      );
    }
    //check to ensure hostUsers isn't the field that is empty
    if (projectMembers.hostUsers?.length) {
      return (
        <ClickableMessage
          onClick={handleNoClientUserCallToAction}
          i18nKey={"common:ui.projectUserForm.noUser"}
        />
      );
    }
    return <></>;
  }, [
    editMode,
    handleNoClientUserCallToAction,
    memberOptions,
    projectMembers.hostUsers?.length,
    t
  ]);

  const showEntitiesField = useMemo(
    () =>
      entitiesEnabled &&
      entitiesOptions?.length > 0 &&
      memberOptions.length &&
      (watchFormValues.member?.isEntityRestricted ||
        (memberOptions.length === 1 && memberOptions[0].isEntityRestricted)),
    [
      entitiesEnabled,
      entitiesOptions?.length,
      watchFormValues.member,
      memberOptions
    ]
  );

  const showRestrictedUserAlert = useMemo(
    () => !entitiesEnabled && watchFormValues.member?.isEntityRestricted,
    [entitiesEnabled, watchFormValues.member]
  );

  const defaultEntityValue = useMemo(() => {
    if (
      watchFormValues.member?.isEntityRestricted &&
      entitiesOptions?.length === 1
    ) {
      return entitiesOptions;
    }
    return [];
  }, [watchFormValues.member?.isEntityRestricted, entitiesOptions]);

  return (
    <>
      {authUser.isHostUser && (
        <Form.Dropdown
          name="memberOf"
          label={t("common:ui.projectUserForm.fields.memberOf.label")}
          items={memberOfOptions}
          required={true}
          disabled={editMode}
        />
      )}
      {membersField}
      {showRestrictedUserAlert && (
        <Text
          text={t("common:ui.projects.entities.addRestrictedUser.information")}
          color={FontColor.SECONDARY}
        />
      )}
      {showEntitiesField ? (
        <Form.ConditionalField
          fieldName="memberOf"
          valueComparer={data => data?.isClient}
        >
          {!editMode && (
            <Text
              text={t(
                "common:ui.projects.entities.addRestrictedAccess.information"
              )}
              color={FontColor.SECONDARY}
            />
          )}
          <Form.Multiselect
            name="entities"
            label={t("stringFormat.capitalize", {
              key: "common:entities.displayName",
              count: 2
            })}
            items={entitiesOptions}
            defaultValue={defaultEntityValue}
          />
        </Form.ConditionalField>
      ) : (
        <></>
      )}
    </>
  );
};

ProjectUserForm.defaultProps = {
  editMode: false,
  users: {},
  projectMembers: {}
};

ProjectUserForm.propTypes = {
  users: PropTypes.shape({
    host: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      users: PropTypes.array
    }),
    client: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      users: PropTypes.array
    })
  }),
  projectMembers: PropTypes.shape({
    hostUsers: PropTypes.array,
    clientUsers: PropTypes.array
  }),
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      externalId: PropTypes.string
    })
  ),
  entitiesEnabled: PropTypes.bool,
  editMode: PropTypes.bool
};

export default ProjectUserForm;
