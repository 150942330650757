import React, { useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";

import { classNames } from "@app/helpers/componentHelpers";

import { Box } from "@fermions";

import { Button } from "@atoms/Button";

import AskHelp from "@components/molecules/AskHelp";
import Contact from "@components/molecules/Contact";

import Loading from "../Loading/Loading";
import "./ContactList.scss";

const ContactList = props => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [contacts, setContacts] = useState(props.contacts);
  const contactCardRef = useRef(null);
  const contactListRef = useRef(null);

  useOnClickOutside(contactCardRef, () => {
    setSelectedUserId(null);
  });

  useEffect(() => {
    setSelectedUserId(null);
    setContacts(props.contacts);
  }, [props.contacts]);

  useEffect(() => {
    if (selectedUserId) {
      contactCardRef.current.style.marginTop = `${-contactListRef.current
        .parentElement.scrollTop}px`;
    }
  }, [selectedUserId]);

  const handleOnDragEnd = contact => {
    props.handleReorderContacts(contact);
  };

  const noContentTemplate = callToActionMessage => {
    return <AskHelp message={callToActionMessage} />;
  };

  const contactListClassNames = () => {
    const classes = ["contact-list__members"];
    if (contacts?.length === 0) {
      classes.push("contact-list__no-content");
    }
    return classNames(classes);
  };

  const getListStyle = () => ({
    cursor: "ns-resize"
  });
  const contactListBodyTemplate = () => {
    if (!(contacts?.length > 0)) {
      return noContentTemplate(t("common:ui.projects.members.none"));
    }
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="contacts">
          {provided => (
            <ul
              className="contacts"
              {...provided.droppableProps}
              style={getListStyle()}
              ref={provided.innerRef}
            >
              {contacts.map((user, index) => (
                <Draggable
                  key={user.email}
                  draggableId={user.email}
                  index={index}
                  isDragDisabled={!props.enableDrag}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Contact
                        drag={provided}
                        enableDrag={props.enableDrag}
                        isDragging={snapshot.isDragging}
                        user={user}
                        handleUserActionClick={data =>
                          props.handleUserActionClick({
                            action: data.key,
                            user
                          })
                        }
                        menuItems={props.getMenuItems(user)}
                        isEngagementTypeSupportEntities={
                          props.isEngagementTypeSupportEntities
                        }
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const loadingTemplate = () => (
    <Box width="100" height="100" alignment="centers">
      <Loading />
    </Box>
  );

  const createRequestButton = useMemo(() => {
    if (!props.hideCreateRequest) {
      return (
        <div className="action-items-btn">
          <Button
            iconName="add"
            label={t(
              "requests:requests.ui.contactList.actions.sendRequestButtonLabel"
            )}
            onClick={props.onClick}
          />
        </div>
      );
    }
  }, [props.hideCreateRequest, props.onClick, t]);
  return (
    <div className="contact-list">
      {props.isContactsLoading ? (
        loadingTemplate()
      ) : (
        <div className="contact-list__container">
          <div className={contactListClassNames()}>
            {contactListBodyTemplate()}
          </div>
        </div>
      )}
      {createRequestButton}
    </div>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array.isRequired,
  isContactsLoading: PropTypes.bool.isRequired,
  handleReorderContacts: PropTypes.func,
  handleUserActionClick: PropTypes.func,
  onClick: PropTypes.func,
  getMenuItems: PropTypes.func,
  enableDrag: PropTypes.bool,
  hideCreateRequest: PropTypes.bool,
  isEngagementTypeSupportEntities: PropTypes.bool
};

export default ContactList;
