import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const smartformService = createApi({
  reducerPath: "smartformApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    "getAnswers",
    "getDeletedAnswers",
    "saveAnswers",
    "uploadDocument",
    "answerComments",
    "questionsWithComments",
    "getAllConversations",
    "groupedAnswerComments"
  ],
  endpoints: builder => ({
    getAnswers: builder.query({
      query: ({ queryId, questionId }) =>
        `/api/queries/${queryId}/answers${
          questionId ? `?questionId=${questionId}` : ""
        }`,
      providesTags: ["getAnswers"]
    }),
    getAnswerComments: builder.query({
      query: ({ queryId, questionId }) =>
        `/api/queries/${queryId}/answers/${questionId}/comments`,
      providesTags: ["answerComments"]
    }),
    getQuestionWithComments: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/questionsWithComment`,
      providesTags: ["questionsWithComments"]
    }),
    getAllConversations: builder.query({
      query: ({ queryId, questionId }) =>
        `/api/queries/${queryId}/answers/${questionId}/conversations`,
      providesTags: ["getAllConversations"]
    }),
    getGroupedAnswerComment: builder.query({
      query: ({ queryId, questionId, conversationId }) =>
        `/api/queries/${queryId}/answers/${questionId}/comments/${conversationId}`,
      providesTags: ["groupedAnswerComments"]
    }),
    saveAnswer: builder.mutation({
      query: ({ answer, queryId, questionId }) => ({
        url: `/api/queries/${queryId}/answers/${questionId}`,
        method: "POST",
        body: answer
      }),
      invalidatesTags: ["saveAnswers", "getAnswers", "getDeletedAnswers"]
    }),
    markDocument: builder.mutation({
      query: ({ answer, queryId, questionId }) => ({
        url: `/api/queries/${queryId}/answers/${questionId}/mark`,
        method: "POST",
        body: answer
      }),
      invalidatesTags: ["saveAnswers", "getAnswers"]
    }),
    getQuestionDeletedAnswers: builder.query({
      query: ({ queryId, questionId }) =>
        `/api/queries/${queryId}/deletedAnswer/${questionId}`,
      providesTags: ["getDeletedAnswers"]
    }),
    addDeletedAnswer: builder.mutation({
      query: ({ answers, queryId, questionId }) => ({
        url: `/api/queries/${queryId}/deletedAnswer/${questionId}`,
        method: "POST",
        body: answers
      }),
      invalidatesTags: ["getAnswers", "getDeletedAnswers"]
    }),
    restoreDeletedAnswer: builder.mutation({
      query: ({ documentId, queryId, questionId }) => ({
        url: `/api/queries/${queryId}/deletedAnswer/${questionId}/document/${documentId}`,
        method: "PUT"
      }),
      invalidatesTags: ["getDeletedAnswers", "getAnswers"]
    }),
    removeDeletedAnswer: builder.mutation({
      query: ({ documentId, queryId, questionId }) => ({
        url: `/api/queries/${queryId}/deletedAnswer/${questionId}/document/${documentId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["getDeletedAnswers"]
    }),
    sendToHost: builder.mutation({
      query: ({ queryId }) => ({
        url: `/api/queries/${queryId}/sendToHost`,
        method: "POST",
        body: {}
      }),
      invalidatesTags: ["sendToHost"]
    }),
    sendExternalRequest: builder.mutation({
      query: ({ queryId, currentCategory }) => ({
        url: `/api/queries/${queryId}/external`,
        method: "POST",
        body: { currentCategory }
      })
    }),
    sendComment: builder.mutation({
      query: commentData => ({
        url: `/api/queryResponse`,
        method: "POST",
        body: { ...commentData }
      }),
      invalidatesTags: ["getAllConversations", "questionsWithComments"]
    }),
    cleanDocument: builder.mutation({
      query: ({ queryId, documentRevisionId }) => ({
        url: `/api/queries/${queryId}/answers/clean/${documentRevisionId}`,
        method: "POST",
        body: {}
      }),
      invalidatesTags: ["saveAnswers", "getAnswers"]
    })
  })
});

export const {
  useAddDeletedAnswerMutation,
  useCleanDocumentMutation,
  useGetAnswersQuery,
  useGetAnswerCommentsQuery,
  useGetAllConversationsQuery,
  useGetGroupedAnswerCommentQuery,
  useGetQuestionDeletedAnswersQuery,
  useGetQuestionWithCommentsQuery,
  useLazyGetQuestionWithCommentsQuery,
  useLazyGetAnswersQuery,
  useMarkDocumentMutation,
  useRemoveDeletedAnswerMutation,
  useRestoreDeletedAnswerMutation,
  useSaveAnswerMutation,
  useSendToHostMutation,
  useSendExternalRequestMutation,
  useSendCommentMutation
} = smartformService;
