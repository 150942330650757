import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Title from "@components/atoms/Title/Title";
import Loading from "@components/molecules/Loading";

import "./SlidingModalTemplate.scss";

function SlidingModalTemplate(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    let maybeTimerToClear = null;
    if (props.show) {
      setShow(true);
      maybeTimerToClear = setTimeout(() => {
        setIsOpened(true);
      }, 100);
    } else {
      setShow(false);
      setIsOpened(false);
    }

    return () => {
      if (maybeTimerToClear) {
        clearTimeout(maybeTimerToClear);
      }
    };
  }, [props.show]);

  const getShowClass = () => (show ? " show" : "");
  const getOpenClass = () => (isOpened ? " open" : "");

  return (
    <div className="ot-sliding-modal-template">
      {show && <div className="ot-modal-backdrop" onClick={props.onClose} />}
      {show && (
        <div
          className={`ot-modal ot-modal-side ot-modal-side--right ot-modal-side--fade ${getShowClass()} ${getOpenClass()}`}
        >
          <div className="ot-modal-dialog">
            <div className="ot-modal-content">
              <div className="ot-modal-head ot-modal-side__header">
                <Title title={props.title} invert />
                <div className="ot-modal-head__close" onClick={props.onClose}>
                  <i className="material-icons">close</i>
                </div>
              </div>
              <div className="ot-modal-body ot-modal-side__body">
                {props.isLoading ? (
                  <Loading message={t("common:loading")} />
                ) : (
                  props.content
                )}
              </div>
              <div className="ot-modal-footer ot-modal-side__footer">
                {props.footer}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

SlidingModalTemplate.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.node,
  footer: PropTypes.element,
  show: PropTypes.bool
};

export default SlidingModalTemplate;
