import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { utilities } from "@shared/helpers";
import { useGuardedNavigate, useUIConfig, useUserProfile } from "@shared/hooks";
import { useSecondaryNav } from "@shared/hooks/useSecondaryNav";

import { classNames } from "@app/helpers/componentHelpers";
import { useLastKnownPath } from "@app/hooks";

import ApplicationNotification from "@components/organisms/ApplicationNotification";
import Footer from "@components/organisms/Footer";
import Heading from "@components/organisms/Heading";
import SideMenu from "@components/organisms/SideMenu";

import "./MainPageTemplate.scss";

const MainPageTemplate = props => {
  const [notificationMessage, setNotificationMessage] = useState("");
  const { i18n } = useTranslation();
  const { uiConfig, isUIConfigLoading } = useUIConfig();
  const { handleNavigate } = useGuardedNavigate();
  const { width: secondaryNavWidth } = useSecondaryNav();
  useLastKnownPath(props.skipSavePath);
  const location = useLocation();
  const { userProfile } = useUserProfile();

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  useEffect(() => {
    const languagePreference =
      userProfile?.properties?.languagePreferences?.UILanguage;
    if (languagePreference && i18n.language !== languagePreference) {
      i18n.changeLanguage(languagePreference);
    }
  }, [userProfile, i18n, i18n.language]);

  useEffect(() => {
    if (location.state?.externalLinkObject?.message) {
      setNotificationMessage(location.state.externalLinkObject.message);
    }
  }, [location.state]);

  const handleClearNotificationMessage = useCallback(() => {
    utilities.clearExternalLinkObjectFromSessionLastKnownPath();
  }, []);

  return isUIConfigLoading && !uiConfig ? (
    ""
  ) : (
    <div className="main-page">
      {/* Header */}
      <div className={`main-page__header main-page__header--side-bar`}>
        <Heading
          subNavLeftPosition={secondaryNavWidth}
          sideNavigation={<SideMenu />}
          handleNavigate={handleNavigate}
        />
      </div>

      {/* Body */}
      <div className="main-page__body">
        {/* Side nav */}
        <div
          className={classNames([
            "main-page__secondary-navigation__container",
            "main-page__secondary-navigation__positioning"
          ])}
        >
          <SideMenu
            width={secondaryNavWidth}
            handleNavigate={handleNavigate}
            project={props.project}
          />
        </div>
        {/* Inner page container */}
        <div className="main-page__body__container">
          {/* Inner page content */}
          <div
            className={`main-page__body__container__inner-content ${
              props.fullWidth ? "full-width" : "box-width"
            }`}
          >
            <div
              className={`main-page__body__container__inner-content__items main-page__body__container__inner-content__items--side-bar`}
            >
              {props.children}
            </div>
          </div>
          <ApplicationNotification
            type="error"
            title="Error"
            message={notificationMessage}
            clearMessage={handleClearNotificationMessage}
          />

          {/* Footer */}
          <div
            className={`box-width main-page__body__container__footer--side-bar}`}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPageTemplate;
