import { ActionItemType } from "@app/types";

function formatActionItemType(actionItemType: ActionItemType) {
  return {
    name: actionItemType.name,
    label: actionItemType.name,
    value: actionItemType.configuration.key,
    translate: false
  };
}

export function formatActionItemTypes({
  actionItemTypes,
  isUserEntityRestricted
}: {
  actionItemTypes: ActionItemType[];
  isUserEntityRestricted: boolean;
}) {
  if (isUserEntityRestricted) {
    const actionItemTypesWithEntities = actionItemTypes.filter(ait =>
      ait.configuration.fields.some(field => field.key === "entities")
    );
    return actionItemTypesWithEntities.map(formatActionItemType);
  }
  return actionItemTypes.map(formatActionItemType);
}
