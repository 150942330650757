export const manageProjectDocumentsConstants = {
  GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST:
    "GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST",
  GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS:
    "GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS",
  GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE:
    "GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE",

  GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_REQUEST:
    "GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_REQUEST",
  GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_SUCCESS:
    "GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_SUCCESS",
  GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_FAILURE:
    "GET_PROJECT_REQUEST_FOLDERS_DOCUMENTS_FAILURE",

  GET_PROJECT_DOCUMENT_REVISIONS_REQUEST:
    "GET_PROJECT_DOCUMENT_REVISIONS_REQUEST",
  GET_PROJECT_DOCUMENT_REVISIONS_SUCCESS:
    "GET_PROJECT_DOCUMENT_REVISIONS_SUCCESS",
  GET_PROJECT_DOCUMENT_REVISIONS_FAILURE:
    "GET_PROJECT_DOCUMENT_REVISIONS_FAILURE",

  UPDATE_PROJECT_DOCUMENT_PROPERTIES_REQUEST:
    "UPDATE_PROJECT_DOCUMENT_PROPERTIES_REQUEST",
  UPDATE_PROJECT_DOCUMENT_PROPERTIES_SUCCESS:
    "UPDATE_PROJECT_DOCUMENT_PROPERTIES_SUCCESS",
  UPDATE_PROJECT_DOCUMENT_PROPERTIES_FAILURE:
    "UPDATE_PROJECT_DOCUMENT_PROPERTIES_FAILURE",

  UPLOAD_PROJECT_DOCUMENT_REQUEST: "UPLOAD_PROJECT_DOCUMENT_REQUEST",
  UPLOAD_PROJECT_DOCUMENT_SUCCESS: "UPLOAD_PROJECT_DOCUMENT_SUCCESS",
  UPLOAD_PROJECT_DOCUMENT_FAILURE: "UPLOAD_PROJECT_DOCUMENT_FAILURE",

  DELETE_PROJECT_DOCUMENT_REQUEST: "DELETE_PROJECT_DOCUMENT_REQUEST",
  DELETE_PROJECT_DOCUMENT_SUCCESS: "DELETE_PROJECT_DOCUMENT_SUCCESS",
  DELETE_PROJECT_DOCUMENT_FAILURE: "DELETE_PROJECT_DOCUMENT_FAILURE",

  UPLOAD_NEW_PROJECT_DOCUMENT_REQUEST: "UPLOAD_NEW_PROJECT_DOCUMENT_REQUEST",
  UPLOAD_NEW_PROJECT_DOCUMENT_SUCCESS: "UPLOAD_NEW_PROJECT_DOCUMENT_SUCCESS",
  UPLOAD_NEW_PROJECT_DOCUMENT_FAILURE: "UPLOAD_NEW_PROJECT_DOCUMENT_FAILURE",

  GET_PROJECT_DOCUMENT_BY_TAG_REQUEST: "GET_PROJECT_DOCUMENT_BY_TAG_REQUEST",
  GET_PROJECT_DOCUMENT_BY_TAG_SUCCESS: "GET_PROJECT_DOCUMENT_BY_TAG_SUCCESS",
  GET_PROJECT_DOCUMENT_BY_TAG_FAILURE: "GET_PROJECT_DOCUMENT_BY_TAG_FAILURE",

  GET_ALL_TAGS_REQUEST: "GET_ALL_TAGS_REQUEST",
  GET_ALL_TAGS_SUCCESS: "GET_ALL_TAGS_SUCCESS",
  GET_ALL_TAGS_FAILURE: "GET_ALL_TAGS_FAILURE",
  RESET_ALL_TAGS: "RESET_ALL_TAGS",

  ADD_PROJECT_TAG_REQUEST: "ADD_PROJECT_TAG_REQUEST",
  ADD_PROJECT_TAG_SUCCESS: "ADD_PROJECT_TAG_SUCCESS",
  ADD_PROJECT_TAG_FAILURE: "ADD_PROJECT_TAG_FAILURE",
  RESET_ADD_PROJECT_TAG: "RESET_ADD_PROJECT_TAG",

  UPDATE_PROJECT_TAG_REQUEST: "UPDATE_PROJECT_TAG_REQUEST",
  UPDATE_PROJECT_TAG_SUCCESS: "UPDATE_PROJECT_TAG_SUCCESS",
  UPDATE_PROJECT_TAG_FAILURE: "UPDATE_PROJECT_TAG_FAILURE",

  DELETE_PROJECT_TAG_REQUEST: "DELETE_PROJECT_TAG_REQUEST",
  DELETE_PROJECT_TAG_SUCCESS: "DELETE_PROJECT_TAG_SUCCESS",
  DELETE_PROJECT_TAG_FAILURE: "DELETE_PROJECT_TAG_FAILURE",

  RESTORE_PROJECT_DOCUMENT_REQUEST: "RESTORE_PROJECT_DOCUMENT_REQUEST",
  RESTORE_PROJECT_DOCUMENT_SUCCESS: "RESTORE_PROJECT_DOCUMENT_SUCCESS",
  RESTORE_PROJECT_DOCUMENT_FAILURE: "RESTORE_PROJECT_DOCUMENT_FAILURE",
  RESET_REVERTED_DOCUMENT: "RESET_REVERTED_DOCUMENT",

  GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_REQUEST:
    "GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_REQUEST",
  GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS:
    "GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS",
  GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_FAILURE:
    "GET_PROJECT_DOCUMENTS_BY_SEARCH_STRING_FAILURE",

  UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_REQUEST:
    "UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_REQUEST",
  UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_SUCCESS:
    "UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_SUCCESS",
  UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_FAILURE:
    "UPDATE_PROJECT_DOCUMENT_CURRENT_REVISION_FAILURE",

  GET_ARCHIVED_PROJECT_DOCUMENTS_REQUEST:
    "GET_ARCHIVED_PROJECT_DOCUMENTS_REQUEST",
  GET_ARCHIVED_PROJECT_DOCUMENTS_SUCCESS:
    "GET_ARCHIVED_PROJECT_DOCUMENTS_SUCCESS",
  GET_ARCHIVED_PROJECT_DOCUMENTS_FAILURE:
    "GET_ARCHIVED_PROJECT_DOCUMENTS_FAILURE",

  SET_SELECTED_PROJECT_DOCUMENT: "SET_SELECTED_PROJECT_DOCUMENT",
  RESET_SELECTED_PROJECT_DOCUMENT: "RESET_SELECTED_PROJECT_DOCUMENT",
  CLEAR_ERROR: "CLEAR_ERROR_PROJECT_DOCUMENTS",
  RESET_DELETE_PROJECT_TAG: "RESET_DELETE_PROJECT_TAG",
  RESET_CREATED_FOLDER: "RESET_CREATED_FOLDER",
  RESET_UPDATED_DOCUMENT: "RESET_UPDATED_DOCUMENT",
  RESET_UPLOADED_DOCUMENT: "RESET_UPLOADED_DOCUMENT",
  RESET_NEW_UPLOADED_DOCUMENT: "RESET_NEW_UPLOADED_DOCUMENT",
  RESET_DOCUMENT_REVISIONS: "RESET_DOCUMENT_REVISIONS",
  RESET_RESTORED_DOCUMENT: "RESET_RESTORED_DOCUMENT",
  RESET_DELETED_DOCUMENT: "RESET_DELETED_DOCUMENT",
  RESET: "RESET_PROJECT_DOCUMENTS"
};
