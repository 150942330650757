import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import {
  manageDocumentDownloadsActions,
  manageProjectDocumentsActions
} from "@shared/actions";
import {
  useAuthUser,
  useRequestPageNavigator,
  useUIConfig
} from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";
import { useToasts } from "@shared/hooks/useToasts";
import {
  useDeleteProjectTagMutation,
  useGetProjectTagsQuery,
  useUpdateProjectTagMutation
} from "@shared/services";
import {
  projectDocumentUtil,
  useGetArchivedProjectDocumentsQuery,
  useGetProjectDocumentsBySearchStringQuery,
  useGetProjectDocumentsByTagQuery,
  useGetProjectFolderDocumentsQuery,
  useRestoreProjectDocumentMutation
} from "@shared/services/projectDocumentService";

import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants/routeConstants";
import { Project } from "@app/types";

import { Box, Inline, Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import IndexList from "@components/molecules/IndexList";
import Loading from "@components/molecules/Loading/Loading";
import SearchBar from "@components/molecules/SearchBar";
import TagEditor from "@components/molecules/TagEditor";
import DocumentsTable from "@components/organisms/DocumentsTable";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import AddOrCopyProjectTags from "../AddOrCopyProjectTags/AddOrCopyProjectTags";
import ProjectDocumentsTableActions from "../DocumentsTable/ProjectDocumentsTableActions";
import UploadFileBox from "../UploadFileBox/UploadFileBox";
import "./ManageClientProjectDocuments.scss";

const editTagTypes = {
  add: "ADD",
  edit: "EDIT",
  delete: "DELETE"
};

interface ManageClientProjectDocumentsProps {
  project: Project;
  handleDownloadAll: () => void;
  isDownloading: boolean;
  error: string;
}

interface ITag {
  id: number;
  name: string;
  documentId: number;
  tagId: number;
}

interface IDocument {
  id: number;
  status: string;
  name: string;
  projectFolderId: number;
  projectId: number;
  tags: ITag[];
}

interface IFolder {
  id: string | number;
  name: string;
  type: string;
  isRoot: boolean;
  folders: IDocument[];
  documents: IDocument[];
}

const ManageClientProjectDocuments = (
  props: ManageClientProjectDocumentsProps
) => {
  const { project, handleDownloadAll, isDownloading, error } = props;
  const { t } = useTranslation();
  const { navigateToRequestPage } = useRequestPageNavigator();
  const { data: projectTags } = useGetProjectTagsQuery(
    { projectId: project?.id },
    { skip: !project?.id }
  );
  const {
    data: projectDocuments,
    isSuccess: projectDocumentsLoaded,
    isLoading: isLoadingProjectDocuments,
    error: projectDocumentsError
  } = useGetProjectFolderDocumentsQuery(
    { projectId: project?.id },
    {
      skip: !project?.id
    }
  );

  const dispatch = useDispatch();
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const manageDocumentDownloads = useSelector(
    state => state.manageDocumentDownloads
  );

  const [projectFoldersAndDocuments, setProjectFoldersAndDocuments] = useState({
    id: `project_${project.id}`,
    name: project.name,
    type: "project",
    isRoot: true,
    folders: [],
    documents: []
  });
  const [folder, setFolder] = useState({
    id: null,
    folders: [],
    documents: []
  });
  const documentMenuRef = useRef(null);
  const [tag, setTag] = useState(null);
  const [tagEditorShow, setTagEditorShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const { data: searchResults } = useGetProjectDocumentsBySearchStringQuery(
    { projectId: project?.id, searchString },
    {
      skip: !project?.id || !searchString
    }
  );
  const [currentFolder, setCurrentFolder] = useState<IFolder>(
    projectFoldersAndDocuments
  );
  const { data: projectDocumentsByTag } = useGetProjectDocumentsByTagQuery(
    {
      projectId: project?.id,
      tagId: currentFolder?.id
    },
    {
      skip: !project?.id || isNaN(currentFolder?.id)
    }
  );
  const [showArchivedDocuments, setShowArchivedDocuments] = useState(false);
  const { data: archivedDocuments } = useGetArchivedProjectDocumentsQuery(
    { projectId: project?.id },
    {
      skip: !project?.id || !showArchivedDocuments
    }
  );
  const [restoreArchivedDocument] = useRestoreProjectDocumentMutation();
  const [updateProjectTag] = useUpdateProjectTagMutation();
  const [
    deleteProjectTag,
    { isError: isDeleteProjectTagError, error: deleteProjectTagError }
  ] = useDeleteProjectTagMutation();
  const [activeDocumentAction, setActiveDocumentAction] = useState(null);
  const [tagMessage, setTagMessage] = useState("");
  const [showAddTags, setShowAddTags] = useState(false);
  const [editType, setEditType] = useState(editTagTypes.edit);
  const [handleTagSubmit, setHandleTagSubmit] = useState(() => () => {});
  const { user } = useAuthUser();
  const { uiConfig } = useUIConfig();
  const [tagUiConfig, setTagUiConfig] = useState({
    tag: {
      size: "default"
    }
  });
  const { showDocumentNameModifiedToast } = useToasts();
  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = useModalContent();

  const isArchivedFolder = useMemo(() => {
    return currentFolder.id === "archivedFolder";
  }, [currentFolder]);

  useEffect(() => {
    if (uiConfig?.pills?.tag) {
      setTagUiConfig(uiConfig.pills.tag);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (manageProjectDocuments.updatedDocumentWithNewRevsion) {
      setFolder(prevState => {
        const updatedDocuments = prevState.documents.map(doc => {
          if (
            doc.id === manageProjectDocuments.updatedDocumentWithNewRevsion.id
          ) {
            doc = { ...manageProjectDocuments.updatedDocumentWithNewRevsion };
          }
          return doc;
        });
        return { ...prevState, documents: updatedDocuments };
      });
    }
  }, [manageProjectDocuments.updatedDocumentWithNewRevsion]);

  useEffect(() => {
    if (manageProjectDocuments.newUploadedDocument) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
    }
  }, [
    dispatch,
    folder.documents,
    folder.id,
    manageProjectDocuments.newUploadedDocument
  ]);

  useEffect(() => {
    if (!projectDocuments || isArchivedFolder) {
      return;
    }
    setProjectFoldersAndDocuments(prevState => ({
      ...prevState,
      folders: projectDocuments.folders,
      documents: projectDocuments.documents
    }));
    const isTag = currentFolder.type === "tag";
    setFolder(prevState => {
      return {
        ...prevState,
        type: currentFolder.type,
        id: currentFolder.id,
        folders: isTag ? [] : projectDocuments.folders,
        documents: isTag ? projectDocumentsByTag : projectDocuments.documents
      };
    });
  }, [
    projectDocuments,
    isArchivedFolder,
    projectDocumentsByTag,
    currentFolder,
    project?.id
  ]);

  useOnClickOutside(documentMenuRef, () => {});

  const viewArchivedDocuments = useCallback(() => {
    setShowArchivedDocuments(true);
  }, []);

  const handleFolderSelection = useCallback(
    selectedFolder => {
      dispatch(manageDocumentDownloadsActions.resetError());
      setCurrentFolder(selectedFolder);
      setSearchString("");
      if (selectedFolder.id !== "archivedFolder") {
        setShowArchivedDocuments(false);
      }

      if (selectedFolder.isRoot) {
        if (selectedFolder.id === "archivedFolder") {
          viewArchivedDocuments();
        }
      }
    },
    [dispatch, viewArchivedDocuments]
  );

  const handleResetFilters = useCallback(() => {
    handleFolderSelection(projectFoldersAndDocuments);
  }, [handleFolderSelection, projectFoldersAndDocuments]);

  const handleChange = value => {
    setSearchString(value);
  };

  const handleRestoreDocument = useCallback(
    ({ document }) => {
      restoreArchivedDocument({
        documentId: document.id,
        doc: document,
        payload: { status: "ACTIVE" },
        projectId: project.id
      })
        .unwrap()
        .then(data => {
          if (data.success && data.updatedObject?.notification) {
            showDocumentNameModifiedToast(data.updatedObject.notification);
          }
        });
    },
    [project.id, restoreArchivedDocument]
  );

  const handleUploadComplete = useCallback(() => {
    dispatch(
      projectDocumentUtil.invalidateTags([
        { type: "projectDocuments", id: "LIST" }
      ])
    );
  }, [dispatch]);

  const handleOpenDocumentForEdit = useCallback(
    document => {
      //figure out what type of document it is
      //then build the url for it
      //if smartform websheet
      if (document.properties?.actionItemTypeKey === "SMARTFORM") {
        window.open(
          `/projects/${document.projectId}/smartforms/${document.properties.queryId}/websheets/${document.id}`
        );
        return;
      } else if (document.id) {
        window.open(`/projects/${document.projectId}/documents/${document.id}`);
        return;
      }

      //if standalone document
      sessionStorage.setItem(
        "documentEditInfo",
        JSON.stringify({
          item: document,
          projectFolder: folder,
          project,
          title: document.name
        })
      );
      window.open(`${routeConstants.editDocument}`);
      sessionStorage.removeItem("documentEditInfo");
    },
    [folder, project]
  );

  const handleViewInRequest = useCallback(
    document => {
      const questionId = document?.properties?.questionId;
      navigateToRequestPage(
        document.properties?.queryId,
        document.projectId,
        document.properties?.actionItemTypeKey,
        {
          websheetOpensNewWindow: true,
          replace: false,
          hash: `questionId=${questionId}`
        }
      );
    },
    [navigateToRequestPage]
  );

  const handleDownloadDocument = useCallback(
    ({ document, cb }) => {
      dispatch(
        manageDocumentDownloadsActions.downloadDocumentRevision({
          ...document,
          cb
        })
      );
    },
    [dispatch]
  );

  const handleDocumentAction = documentAction => {
    setActiveDocumentAction(documentAction);
  };

  const handleDocumentActionFinished = () => {
    setActiveDocumentAction(null);
    handleCloseModal();
  };

  const hideTagEditor = useCallback(() => {
    setTagMessage("");
    setTagEditorShow(false);
  }, []);

  useEffect(() => {
    if (searchString) {
      setFolder({
        id: "search",
        folders: [],
        documents: structuredClone(searchResults ?? []).map(document => {
          if (document.projectFolder) {
            document.pathName = `../${document.projectFolder.name}/${document.name}`;
          } else {
            document.pathName = document.name;
          }
          return document;
        })
      });
    } else if (folder.id === "search") {
      handleFolderSelection(projectFoldersAndDocuments);
    }
  }, [
    searchString,
    searchResults,
    projectFoldersAndDocuments,
    folder?.id,
    handleFolderSelection
  ]);

  useEffect(() => {
    if (showArchivedDocuments && archivedDocuments) {
      setFolder({
        id: "archivedFolder",
        folders: [],
        documents: structuredClone(archivedDocuments).map(document => {
          document.pathName = document.projectFolder
            ? `../${document.projectFolder.name}/${document.name}`
            : document.name;
          return document;
        })
      });
    }
  }, [
    currentFolder.id,
    currentFolder.type,
    archivedDocuments,
    showArchivedDocuments
  ]);

  const showTagEditor = useCallback((event, tag) => {
    event.stopPropagation();
    setTag(tag);
    setTagMessage("");
    setTagEditorShow(true);
  }, []);

  const handleTagUpdateSubmit = useCallback(
    tag => {
      updateProjectTag({
        projectId: project.id,
        tag
      })
        .unwrap()
        .then(() => {
          setTagEditorShow(false);
        })
        .catch(error => {
          setTagMessage(error.data.message);
        });
    },
    [updateProjectTag, project]
  );

  const handleAddTag = useCallback(event => {
    event.preventDefault();
    setShowAddTags(true);
  }, []);

  const onCancelAddTags = useCallback(() => {
    setShowAddTags(false);
  }, []);

  const onAddTagsSubmitted = useCallback(() => {
    setShowAddTags(false);
  }, []);

  const handleTagDismiss = useCallback(
    event => {
      event?.stopPropagation();
      hideTagEditor();
    },
    [hideTagEditor]
  );

  const handleEditTag = useCallback(
    tag => event => {
      event.preventDefault();
      event.stopPropagation();

      setHandleTagSubmit(() => handleTagUpdateSubmit);
      setEditType(editTagTypes.edit);
      showTagEditor(event, tag);
    },
    [handleTagUpdateSubmit, showTagEditor]
  );

  const handleForceDeleteTag = useCallback(
    tag => {
      setTag(tag);
      deleteProjectTag({
        projectId: project.id,
        tagId: tag.id,
        force: true
      }).then(() => {
        setTagEditorShow(false);
      });
    },
    [deleteProjectTag, project]
  );

  const handleDeleteTagError = data => {
    const items = [
      t("common:ui.documents.displayNameWithCount", {
        count: data.documentCount
      }),
      t("requests:requests.requestWithCount", {
        count: data.queriesCount
      })
    ].filter(a => a);
    return t("common:ui.deleteProjectTag.hasAssociatedTagsWarningMessage", {
      count: items.length,
      items
    });
  };

  const handleDeleteSignedTag = useCallback(data => {
    const message = handleDeleteTagError(data);
    setHandleTagSubmit(() => handleForceDeleteTag);
    setEditType(editTagTypes.delete);
    setTagMessage(message);
    setTagEditorShow(true);
  }, []);

  const handleDeleteTag = useCallback(
    tag => event => {
      event.preventDefault();
      event.stopPropagation();
      setTag(tag);
      deleteProjectTag({
        projectId: project.id,
        tagId: tag.id
      })
        .unwrap()
        .then()
        .catch(error => {
          if (
            error?.data?.message ===
            "common:ui.globalTags.deleteTagHasAssociations"
          ) {
            handleDeleteSignedTag(error.data);
          }
        });
    },
    [deleteProjectTag, project]
  );

  const handleResetTag = useCallback(() => {
    handleFolderSelection(projectFoldersAndDocuments);
  }, [handleFolderSelection, projectFoldersAndDocuments]);

  const getError = useCallback(() => {
    if (error) {
      return error;
    } else if (
      (!tagEditorShow && projectDocumentsError) ||
      manageDocumentDownloads.error
    ) {
      return projectDocumentsError || manageDocumentDownloads.error;
    } else if (
      isDeleteProjectTagError &&
      deleteProjectTagError?.data?.message !==
        "common:ui.globalTags.deleteTagHasAssociations"
    ) {
      return deleteProjectTagError?.data?.message;
    }
    return "";
  }, [
    error,
    manageDocumentDownloads.error,
    projectDocumentsError,
    isDeleteProjectTagError,
    deleteProjectTagError,
    tag,
    tagEditorShow
  ]);

  const isLoading = useMemo(
    () => !getError() && !projectDocumentsLoaded && isLoadingProjectDocuments,
    [getError, isLoadingProjectDocuments, projectDocumentsLoaded]
  );

  const bodySecondary = useMemo(() => {
    if (!project || !user || !projectDocumentsLoaded) {
      return null;
    }
    return (
      <>
        {currentFolder.id === "requestFolder" ||
          currentFolder.id === "archivedFolder" ||
          currentFolder.type === "query" || (
            <div className="ot-client-project-documents__body--index-body">
              <UploadFileBox
                title={t("common:ui.documents.fileUpload.label")}
                project={project}
                boxClassName={"upload-file-box"}
                checkIsMember={true}
                tags={user.isHostUser ? projectTags : []}
                registerModal={registerModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                onUploadComplete={handleUploadComplete}
              />
            </div>
          )}
        <div className="ot-client-project-documents__body--index-body">
          <IndexList
            foldersAndDocuments={[projectFoldersAndDocuments]}
            selectedFolder={folder}
            tags={projectTags}
            canAddTag={user?.isHostUser}
            handleFolderSelection={handleFolderSelection}
            handleAddTag={handleAddTag}
            handleEditTag={handleEditTag}
            handleDeleteTag={handleDeleteTag}
            handleResetTag={handleResetTag}
            isArchivedFolder={isArchivedFolder}
            tagUiConfig={tagUiConfig}
          />
        </div>
      </>
    );
  }, [
    currentFolder.id,
    currentFolder.type,
    folder,
    handleAddTag,
    handleCloseModal,
    handleDeleteTag,
    handleEditTag,
    handleFolderSelection,
    handleOpenModal,
    handleResetTag,
    handleUploadComplete,
    isArchivedFolder,
    projectDocumentsLoaded,
    project,
    projectFoldersAndDocuments,
    projectTags,
    registerModal,
    t,
    tagUiConfig,
    user
  ]);

  const bodyPrimary = useMemo(() => {
    if (!project || !user || !projectDocumentsLoaded) {
      return <></>;
    }
    return (
      <Stack
        className="ot-client-project-documents__body--documents"
        gap="200"
        width="100"
      >
        {isArchivedFolder ? (
          <Box className="ot-client-project-documents__subheading" width="100">
            {t("common:ui.documents.deletedDocuments.label")}
          </Box>
        ) : (
          <Inline className="ot-client-project-documents__search-box" gap="200">
            <Box
              className="ot-client-project-documents__search-box-input"
              width="100"
            >
              <SearchBar
                value={searchString}
                onChange={handleChange}
                placeholder={t("common:ui.documents.searchLabel")}
              />
            </Box>
            <Button
              label={t("common:ui.documents.resetButtonLabel")}
              variant={ButtonVariant.TEXT_PRIMARY}
              onClick={handleResetFilters}
            />
          </Inline>
        )}

        <DocumentsTable
          folder={folder}
          project={project}
          handleFolderSelection={handleFolderSelection}
          handleRestoreDocument={handleRestoreDocument}
          handleOpenDocumentForEdit={handleOpenDocumentForEdit}
          handleDownloadDocument={handleDownloadDocument}
          handleEditDocument={handleDocumentAction}
          handleUploadDocumentRevision={handleDocumentAction}
          handleUpdateDocumentProperties={handleDocumentAction}
          handleReviewRevisionHistory={handleDocumentAction}
          handleDeleteDocument={handleDocumentAction}
          handleEditDocumentTags={handleDocumentAction}
          handleReviewReviewHistory={handleDocumentAction}
          handleViewInRequest={handleViewInRequest}
          downloadingErrorDocumentId={manageDocumentDownloads.errorDocumentId}
        />
      </Stack>
    );
  }, [
    folder,
    handleDownloadDocument,
    handleFolderSelection,
    handleOpenDocumentForEdit,
    handleResetFilters,
    handleRestoreDocument,
    handleViewInRequest,
    isArchivedFolder,
    manageDocumentDownloads.errorDocumentId,
    projectDocumentsLoaded,
    project,
    searchString,
    t,
    user
  ]);

  const downloadAllLabel = useMemo(() => {
    if (isDownloading) {
      return (
        <Loading
          message={t("common:ui.documents.downloadAllLabel", {
            context: "downloading"
          })}
        />
      );
    } else {
      return <>{t("common:ui.documents.downloadAllLabel")}</>;
    }
  }, [isDownloading, t]);

  return (
    <>
      <PageTemplate
        header={{
          title: t("ui.documents.navigation.title"),
          actions: !showArchivedDocuments && (
            <Button
              variant={ButtonVariant.TEXT}
              onClick={handleDownloadAll}
              iconName={!isDownloading ? "download" : ""}
              label={downloadAllLabel}
            />
          )
        }}
        body={{
          primary: bodyPrimary,
          secondary: bodySecondary,
          secondaryWidth: "40vw"
        }}
        other={{
          smallPageSize: 1600,
          error: getError(),
          project,
          loading: isLoading ? t("common:ui.documents.loading") : null
        }}
        classNames={{
          page: "ot-client-project-documents"
        }}
        modal={{
          open: modalOpen,
          content: getModalContent()
        }}
      />
      {activeDocumentAction?.action && (
        <ProjectDocumentsTableActions
          action={activeDocumentAction}
          projectTags={projectTags}
          onFinished={handleDocumentActionFinished}
        />
      )}
      <Popup visibility={showAddTags} handleOutsideClick={false} width="60rem">
        <AddOrCopyProjectTags
          projectId={project.id}
          existingTags={projectTags}
          engagementId={project.engagementId}
          onCancel={onCancelAddTags}
          onSubmitted={onAddTagsSubmitted}
        />
      </Popup>

      <Popup visibility={tagEditorShow} width="60rem">
        <TagEditor
          existingTag={tag}
          editType={editType}
          handleSubmit={handleTagSubmit}
          handleDismiss={handleTagDismiss}
          message={tagMessage}
        />
      </Popup>
    </>
  );
};

ManageClientProjectDocuments.defaultProps = {};

ManageClientProjectDocuments.propTypes = {
  project: PropTypes.object.isRequired,
  handleDownloadAll: PropTypes.func,
  isDownloading: PropTypes.bool,
  error: PropTypes.string
};

export default ManageClientProjectDocuments;
