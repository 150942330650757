import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { systemConstants } from "@shared/constants/systemConstants";
import { useDocumentDownloads } from "@shared/hooks";
import {
  projectDocumentUtil,
  useArchiveProjectDocumentMutation,
  useDeleteProjectDocumentMutation
} from "@shared/services/projectDocumentService";

import Popup from "@shared-components/popup/Popup";

import { documentActions } from "@app/helpers/documents";

import DeleteDocument from "@components/organisms/DeleteDocument";
import ReviewRevisionHistory from "@components/organisms/DocumentRevisionHistory";
import EditDocumentTags from "@components/organisms/EditDocumentTags";
import UpdateProjectDocumentProperties from "@components/organisms/UpdateProjectDocumentPropertiesModal";
import UploadDocumentRevision from "@components/organisms/UploadDocumentRevision";

const ProjectDocumentsTableActions = props => {
  const {
    action: { action, document, projectFolder },
    projectTags,
    onFinished
  } = props;
  const [archiveProjectDocument] = useArchiveProjectDocumentMutation();
  const [deleteProjectDocument] = useDeleteProjectDocumentMutation();
  const { fetchProjectDocument, downloadedDocuments } = useDocumentDownloads();
  const handleActionFinished = useCallback(() => {
    onFinished();
  }, [onFinished]);
  const documentStatus = systemConstants.project.document.status;
  const dispatch = useDispatch();

  const handleDeleteDocument = useCallback(() => {
    if (document.status === documentStatus.archived) {
      deleteProjectDocument({
        documentId: document.id,
        projectId: document.projectId
      });
    } else {
      const doc = {
        ...document,
        status: documentStatus.archived
      };
      archiveProjectDocument({ documentId: document.id, doc });
    }
    handleActionFinished();
  }, [
    archiveProjectDocument,
    handleActionFinished,
    deleteProjectDocument,
    documentStatus.archived,
    document
  ]);

  const handleUpdateDocumentProperties = useCallback(project => {
    dispatch(
      projectDocumentUtil.invalidateTags([
        { type: "projectDocuments", id: project.id }
      ])
    );
    handleActionFinished();
  }, []);

  const isSmartFormType =
    document?.properties?.actionItemTypeKey ===
    systemConstants.actionItemTypes.smartForm;

  return (
    <>
      <Popup
        visibility={action === documentActions.editTags}
        handleOutsideClick={false}
        width="60rem"
      >
        <EditDocumentTags
          document={document}
          availableTags={projectTags}
          onUpdate={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.deleteDocument}
        handleOutsideClick={false}
        width="60rem"
      >
        <DeleteDocument
          document={document}
          handleDelete={handleDeleteDocument}
          onDelete={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.reviewRevisionHistory}
        handleOutsideClick={false}
        width="80rem"
      >
        <ReviewRevisionHistory
          document={document}
          revisionOf="projectDocument"
          onCancel={handleActionFinished}
          onSubmit={handleActionFinished}
          fetchDocument={fetchProjectDocument}
          canSelectActive={!isSmartFormType}
          downloadedDocuments={downloadedDocuments}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.updateDocumentProperties}
        handleOutsideClick={false}
        width="60rem"
      >
        <UpdateProjectDocumentProperties
          document={document}
          onUpdated={handleUpdateDocumentProperties}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.uploadDocument}
        handleOutsideClick={false}
        width="60rem"
      >
        <UploadDocumentRevision
          document={document}
          projectFolder={projectFolder}
          onUpload={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>
    </>
  );
};

ProjectDocumentsTableActions.defaultProps = {};

ProjectDocumentsTableActions.propTypes = {
  action: PropTypes.shape({
    action: PropTypes.string.isRequired,
    document: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    projectFolder: PropTypes.shape({
      id: PropTypes.any
    })
  }),
  onFinished: PropTypes.func.isRequired
};

export default ProjectDocumentsTableActions;
