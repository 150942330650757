import React, { useCallback, useMemo } from "react";

import _ from "lodash";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/index.js";
import dateFormatter from "@shared/helpers/dateHelper.js";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities.js";
import { useAuthUser, useLocaleDate } from "@shared/hooks";

import {
  QUERY_TYPE_ICON,
  getActionIndicator,
  getActionsForQuery
} from "@app/helpers";
import { populateRelevantEntitiesByIdToObject } from "@app/helpers/entity.ts";
import {
  ActionItemType,
  ActionItemTypeAction,
  AuthUser,
  Project,
  Query,
  QueryAction,
  QueryActionHandlers,
  QueryIndicatorConfig,
  QueryStatus,
  Swimlane,
  SwimlaneAction,
  SwimlaneItem
} from "@app/types/index.ts";

import { Stack } from "@fermions";

import { ActionItemPanel } from "@molecules/ActionItemPanel/ActionItemPanel.tsx";

import AskHelp from "@components/molecules/AskHelp/index.js";
import Loading from "@components/molecules/Loading/index.js";
import { KanbanTemplate } from "@components/templates/KanbanTemplate/KanbanTemplate.tsx";

const swimlanesMapping = {
  [QueryStatus.DRAFT]: [QueryStatus.DRAFT],
  [QueryStatus.OPEN]: [QueryStatus.OPEN, QueryStatus.ERROR],
  [QueryStatus.RESPONDED]: [QueryStatus.RESPONDED],
  [QueryStatus.CLOSED]: [QueryStatus.CLOSED],
  ["OTHER"]: [QueryStatus.ATTACHMENT, QueryStatus.NOTE]
};

const hiddenLaneIfNone = [QueryStatus.DRAFT];

interface ActionItemsKanbanViewProps {
  allQueries: Query[];
  setAllQueries: (queries: Query[]) => void;
  actionItemTypes?: ActionItemType[];
  project?: Project;
  actionHandlers: QueryActionHandlers;
  requestActionHandler: (
    query: Query,
    destinationAction: string,
    confirmed: boolean
  ) => void;
  indicatorUiConfig: QueryIndicatorConfig;
  loading?: string;
}

const ActionItemsKanbanView = ({
  allQueries,
  setAllQueries,
  actionItemTypes,
  project,
  actionHandlers,
  indicatorUiConfig,
  loading,
  requestActionHandler
}: ActionItemsKanbanViewProps) => {
  const { t } = useTranslation();
  const { user } = useAuthUser();

  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const formatDate = useCallback(
    date => dateFormatter(date, locale, shortFormat) ?? "",
    [locale, shortFormat]
  );

  const onMoveItem = useCallback(
    (
      destination: string,
      item: SwimlaneItem,
      destinationAction: string,
      confirmed: boolean
    ) => {
      if (
        !item.acceptableLanesToMove?.includes(destination) &&
        !item?.data?.actionItemType?.configuration?.workflow
      ) {
        return;
      }

      const actions: QueryAction[] = item.data.actions?.map(
        (menuItem: { action: QueryAction }) => menuItem.action
      );

      setAllQueries(prev => {
        return structuredClone(prev).map(aq => {
          if (aq.id === item.id) {
            aq.status = destination as QueryStatus;
            aq.isLoading = true;
          }
          return aq;
        });
      });

      if (!item.data?.actionItemType?.configuration?.workflow) {
        switch (destination) {
          case QueryStatus.CLOSED:
            if (actions.includes(QueryAction.CLOSE)) {
              actionHandlers[QueryAction.CLOSE]?.(item.originalData);
            } else if (actions.includes(QueryAction.CLOSE_REQUEST)) {
              actionHandlers[QueryAction.CLOSE_REQUEST]?.(item.originalData);
            }
            break;
          case QueryStatus.OPEN:
            if (actions.includes(QueryAction.REOPEN)) {
              actionHandlers[QueryAction.REOPEN]?.(item.originalData);
            }
            break;
        }
      } else {
        requestActionHandler(item.originalData, destinationAction, confirmed);
      }
    },
    [actionHandlers, requestActionHandler, setAllQueries]
  );

  const menuItemClickHandler = useCallback(
    ({
      menuItem: { action },
      item,
      destinationStatus,
      confirmed = false
    }: {
      menuItem: { action: QueryAction };
      item: SwimlaneItem;
      confirmed?: boolean;
      destinationStatus?: string;
    }) => {
      if (
        [QueryAction.VIEW, QueryAction.EDIT].includes(action) ||
        systemConstants.actionItemTypes.websheet ===
          item?.data?.actionItemType?.configuration?.type
      ) {
        actionHandlers[action]?.(item.originalData);
      } else if (destinationStatus === item.data.status) {
        requestActionHandler(item.originalData, action, confirmed);
      } else if (destinationStatus) {
        onMoveItem(destinationStatus, item, action, confirmed);
      }
    },
    [actionHandlers, onMoveItem, requestActionHandler]
  );
  const onClickItem = useCallback(
    (item: SwimlaneItem) => {
      actionHandlers[QueryAction.VIEW]?.(item.originalData);
    },
    [actionHandlers]
  );

  const renderItem = useCallback(
    (item: SwimlaneItem, isDragging = false) => (
      <ActionItemPanel
        item={item}
        entities={project?.entities}
        isDragging={isDragging}
        isLoading={item.isLoading}
        menuItemClickHandler={menuItemClickHandler}
        onMoveItem={onMoveItem}
      />
    ),
    [project?.entities, menuItemClickHandler, onMoveItem]
  );

  const getStatusText = useCallback(
    status =>
      t(`requests:requests.configured.status.${status.toUpperCase()}.label`),
    [t]
  );

  const getSwimlaneAction = useCallback(
    (swimlaneId: QueryStatus): SwimlaneAction | undefined => {
      if (
        ![
          ...(project?.status !== systemConstants.project.status.draft
            ? [QueryStatus.OPEN]
            : []),
          QueryStatus.DRAFT
        ].includes(swimlaneId)
      ) {
        return;
      }
      return {
        onClick: actionHandlers?.[QueryAction.CREATE],
        labelKey:
          "requests:requests.ui.requestListPage.buttonActionCreateRequest",
        iconName: "add"
      };
    },
    [actionHandlers, project?.status]
  );

  const mappedSwimlanes = useMemo(() => {
    const queriesByStatus = getFormattedQueriesByStatus({
      allQueries: allQueries ?? [],
      actionItemTypes,
      formatDate,
      indicatorUiConfig,
      user,
      project,
      t
    });

    const getItemWithSwimlane = (status: QueryStatus, swimlaneId: string) => {
      const queries = queriesByStatus[status];
      if (!queriesByStatus[status]) {
        return;
      }
      queries.forEach(query => (query.swimlaneId = swimlaneId));
      return queries;
    };

    const swimlanes: Swimlane[] = Object.entries(swimlanesMapping).map(
      ([swimlaneId, statuses]) => ({
        id: swimlaneId,
        title: getStatusText(swimlaneId),
        items: statuses
          .flatMap(status => getItemWithSwimlane(status, swimlaneId))
          .filter(item => item) as SwimlaneItem[],
        action: getSwimlaneAction(swimlaneId as QueryStatus)
      })
    );
    return swimlanes.filter(swimlane => {
      if (!hiddenLaneIfNone.includes(swimlane.id as QueryStatus)) {
        return true;
      }
      return swimlane.items.length > 0;
    });
  }, [
    actionItemTypes,
    formatDate,
    getStatusText,
    getSwimlaneAction,
    indicatorUiConfig,
    project,
    allQueries,
    t,
    user
  ]);

  const overridingMessage = useMemo(() => {
    if (!project || loading) {
      return <Loading message={loading} />;
    } else if (!allQueries?.length) {
      return (
        <Stack>
          <AskHelp
            message={t(
              "requests:requests.ui.requestListPage.noRequestsPresent"
            )}
          />
        </Stack>
      );
    }
  }, [loading, project, allQueries?.length, t]);

  return (
    <KanbanTemplate
      swimlanes={mappedSwimlanes}
      onMoveItem={onMoveItem}
      renderItem={renderItem}
      onClickItem={onClickItem}
      overridingMessage={overridingMessage}
    />
  );
};

function getAcceptableLanesToMove(actionMenuItems: ActionItemTypeAction[]) {
  const actions = actionMenuItems.map(actionMenuItem => actionMenuItem.action);
  const acceptableLanes: QueryStatus[] = [];

  if (actions.includes(QueryAction.REOPEN)) {
    acceptableLanes.push(QueryStatus.OPEN);
  }

  if (actions.includes(QueryAction.CLOSE || QueryAction.CLOSE_REQUEST)) {
    acceptableLanes.push(QueryStatus.CLOSED);
  }

  return acceptableLanes;
}

function getFormattedQueriesByStatus({
  allQueries,
  actionItemTypes,
  formatDate,
  indicatorUiConfig,
  user,
  project,
  t
}: {
  allQueries: Query[];
  actionItemTypes?: ActionItemType[];
  formatDate: (date: string) => string;
  indicatorUiConfig: QueryIndicatorConfig;
  user: AuthUser;
  project?: Project;
  t: (key: string) => string;
}): { [key in QueryStatus]: SwimlaneItem[] } {
  const formattedQueries = allQueries.map(querySource => {
    const actionItemType = actionItemTypes?.find(
      actionItemType =>
        actionItemType.configuration.key === querySource.queryType
    );

    const queryActions = getActionsForQuery({
      item: querySource,
      actionItemTypes,
      user,
      t
    });

    const formattedQuery = {
      ...querySource,
      tags: querySource.tags?.map(tag => tag.name),
      requiredBy: formatDate(querySource.requiredBy),
      actionItemType,
      indicator: getActionIndicator(querySource, new Date(), indicatorUiConfig),
      queryTypeIcon: QUERY_TYPE_ICON[querySource.configType],
      actions: queryActions ?? [],
      entities: populateRelevantEntitiesByIdToObject(project, querySource),
      milestone: milestoneUtilities.getQueryMilestoneDetails(
        project,
        querySource
      ),
      userRoleType: [
        user.xRole.type,
        querySource.isUserOnRequestorTeam ? "REQUESTOR" : ""
      ]
    };

    return {
      id: querySource.id,
      data: formattedQuery,
      originalData: querySource,
      acceptableLanesToMove: querySource.allowedToEdit
        ? getAcceptableLanesToMove(formattedQuery.actions)
        : [],
      hidden: !allQueries.find(fq => fq.id === querySource.id),
      isLoading: querySource?.isLoading
    };
  });

  return _.groupBy(formattedQueries, "data.status");
}

export default ActionItemsKanbanView;
