import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers/utilities";
import {
  useAuthUser,
  useCurrentProject,
  useFeatures,
  useOpenItems,
  useUpdateClientEngagementProject
} from "@shared/hooks";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import { routeConstants } from "@app/constants";

import ProjectTableMenu from "@components/molecules/ProjectTableMenu";
import ProjectTableRow from "@components/molecules/ProjectTableRow";
import WarningModal from "@components/molecules/WarningModal";
import ProjectsTableTemplate from "@components/templates/ProjectsTableTemplate";

import "./ProjectsTable.scss";

// NB: 'key' happens to be the same as our i18n key used to get the translated label
const updateProjectOption = { id: 1, key: "common:ui.project.title_update" };
const copyProjectOption = { id: 2, key: "common:ui.project.title_copy" };

const ProjectsTable = props => {
  const { engagement } = props;
  const { i18n, t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const navigate = useNavigate();
  const [
    projectDeactiveWarningVisibility,
    setProjectDeactiveWarningVisibility
  ] = useState(false);
  const [inactivateProject, setInactivateProject] = useState(null);
  const [openItemsCounter, setOpenItemsCounter] = useState({
    openQueries: 0,
    openRisks: 0
  });
  const { onChangeCurrentProject } = useCurrentProject();
  const { user } = useAuthUser();
  const { isProjectManagerFieldEnabled, isRiskFlagEnabled } = useFeatures();
  const { openItems, getOpenRisks, getOpenQueries, clearOpenItems } =
    useOpenItems();
  const { updateProject } = useUpdateClientEngagementProject();
  const [isMobile, setIsMobile] = useState(true);
  const [hoverVisibility, setHoverVisibility] = useState({
    rowId: -1,
    display: false
  });
  const translatedProjectOptions = useMemo(() => {
    return [updateProjectOption, copyProjectOption].reduce(
      (acc, o) => ({
        ...acc,
        [o.key]: {
          ...o,
          label: t(o.key)
        }
      }),
      {}
    );
  }, [t]);

  const tableConstants = {
    mail: t("common:ui.projectTable.mail"),
    notes: t("common:ui.projectTable.notes"),
    name: t("common:ui.projectTable.name"),
    status: t("common:ui.projectTable.status"),
    upcomingMilestone: t("common:ui.projectTable.upcomingMilestone"),
    projectManager: t("common:ui.projectTable.projectManager"),
    viewDetails: t("common:viewDetails"),
    risks: t("common:ui.projectTable.risks")
  };

  useEffect(() => {
    setIsMobile(
      windowWidth > systemConstants.mediaBreakpoints.tabPort ? false : true
    );
  }, [windowWidth]);

  useEffect(() => {
    if (openItems.openQueries && openItems.openRisks) {
      setOpenItemsCounter({
        ...openItems,
        openQueries: openItems.openQueries.length,
        openRisks: openItems.openRisks.length
      });
      setProjectDeactiveWarningVisibility(true);
    }
  }, [openItems]);

  const initializeDeactivatingProperties = () => {
    clearOpenItems();
    setInactivateProject(null);
    setTimeout(() => {
      setOpenItemsCounter({
        ...openItemsCounter,
        openQueries: 0,
        openRisks: 0
      });
    }, systemConstants.popup.popupCloseTimeoutForTest);
  };

  const onUpdateProject = useCallback(
    ({ id }) => navigate(`/projects/${id}/edit`, { state: { engagement } }),
    [engagement, navigate]
  );
  const onCopyProject = useCallback(
    ({ id }) => navigate(`/projects/${id}/copy`, { state: { engagement } }),
    [engagement, navigate]
  );

  const handleMenuItemClick = useCallback(
    (event, item) => {
      setHoverVisibility({ rowId: -1, display: false });
      const eventMap = {
        [updateProjectOption.key]: onUpdateProject,
        [copyProjectOption.key]: onCopyProject,
        "Archive Project": item => {
          setInactivateProject(item);
          getOpenQueries(item);
          getOpenRisks(item);
        }
      };
      eventMap[event](item);
    },
    [getOpenQueries, getOpenRisks, onCopyProject, onUpdateProject]
  );

  const handlePopupOutsideClick = () => {
    setProjectDeactiveWarningVisibility(false);
    initializeDeactivatingProperties();
  };

  const handleOkClick = () => {
    updateProject({
      ...inactivateProject,
      status: systemConstants.project.status.archived
    });
    setProjectDeactiveWarningVisibility(false);
    initializeDeactivatingProperties();
  };

  const getProjectObject = row => {
    return { ...row };
  };

  const handleProjectRowClick = project => {
    const newProject = getProjectObject(project);
    onChangeCurrentProject(utilities.getProjectWithMilestoneData(newProject));
    navigate(`${routeConstants.projects}/${project.id}`);
  };

  const renderWarningModal = () => {
    let message = "";
    let items = [];
    if (openItemsCounter.openRisks != 0 || openItemsCounter.openQueries != 0) {
      message =
        "Assignees will be notified to let them know that no further action is required for the following items. Click OK if you wish to proceed";
      if (openItemsCounter.openRisks > 0) {
        items.push({
          id: "risk",
          value: `${openItemsCounter.openRisks} open request(s)`
        });
      }
      if (openItemsCounter.openQueries > 0) {
        items.push({
          id: "queries",
          value: `${openItemsCounter.openQueries} open request(s)`
        });
      }
    } else {
      message = "Are you sure you wish to archive this project?";
    }
    return (
      <WarningModal
        title={"Archive project"}
        message={message}
        items={items}
        onClickYes={handleOkClick}
        onClickCancel={handlePopupOutsideClick}
      />
    );
  };

  const renderMenuItems = useCallback(
    row => {
      const isVisible =
        hoverVisibility.rowId === row.id && hoverVisibility.display;
      const isArchived = row.status == systemConstants.project.status.archived;
      const isShowUpdateProject = !isArchived && user.isHostUser;
      const isShowCopyProject = user.isHostUser;

      const menuItems = [];
      if (isVisible) {
        if (isShowUpdateProject) {
          menuItems.push(translatedProjectOptions[updateProjectOption.key]);
        }
        if (isShowCopyProject) {
          menuItems.push(translatedProjectOptions[copyProjectOption.key]);
        }
      }
      return (
        <ProjectTableMenu
          row={row}
          menuItems={menuItems}
          onClickMenuItem={handleMenuItemClick}
        />
      );
    },
    [
      hoverVisibility.rowId,
      hoverVisibility.display,
      user.isHostUser,
      handleMenuItemClick,
      translatedProjectOptions
    ]
  );

  const renderDataRow = row => {
    const isMobile =
      windowWidth > systemConstants.mediaBreakpoints.tabPort ? true : false;
    const isArchived =
      row.status == systemConstants.project.status.archived ? true : false;
    const milestoneName = utilities.getProjectMilestoneName(
      row.upcomingMilestone,
      i18n
    );
    return (
      <ProjectTableRow
        key={row.id}
        isMobile={isMobile}
        isArchived={isArchived}
        row={row}
        onClickRow={handleProjectRowClick}
        showRiskFlag={isRiskFlagEnabled}
        getMenuItems={renderMenuItems}
        tableConstants={tableConstants}
        handleHoverVisibility={({ rowId, display }) => {
          setHoverVisibility({ rowId, display });
        }}
        milestoneName={milestoneName}
        isProjectManagerFieldEnabled={isProjectManagerFieldEnabled}
      />
    );
  };

  return (
    <>
      <ProjectsTableTemplate
        data={props.data}
        isBelongHost={user.isHostUser}
        isMobile={isMobile}
        showRiskFlag={isRiskFlagEnabled}
        renderDataRow={renderDataRow}
        renderWarningModal={renderWarningModal}
        projectDeactiveWarningVisibility={projectDeactiveWarningVisibility}
        tableConstants={tableConstants}
        isProjectManagerFieldEnabled={isProjectManagerFieldEnabled}
      />
    </>
  );
};

ProjectsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      milestones: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired
        })
      ).isRequired,
      projectManager: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    })
  )
};

export default ProjectsTable;
