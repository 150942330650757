import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import { projectDocumentUtil } from "./projectDocumentService";

export const documentService = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    "documentProperties",
    "archivedClientDocuments",
    "clientDocuments",
    "documentRevisions"
  ],
  endpoints: builder => ({
    getDocumentProperties: builder.query({
      query: ({ documentId }) => `/api/documents/${documentId}/properties`,
      providesTags: ["documentProperties"]
    }),
    getClientDocuments: builder.query({
      query: ({ clientId }) => `/api/clients/${clientId}/documents`,
      providesTags: ["clientDocuments"]
    }),
    getProjectDocumentsWithSameNamePresent: builder.query({
      query: ({ projectId, documentNames, includeInternalOnly }) => {
        const documentNamesQuery = documentNames
          .map(name => `name=${name}`)
          .join("&");
        const includeInternalOnlyQuery = includeInternalOnly
          ? `&includeInternal=${includeInternalOnly}`
          : "";
        return `/api/projects/${projectId}/documents/present?${documentNamesQuery}${includeInternalOnlyQuery}`;
      }
    }),
    getArchivedClientDocuments: builder.query({
      query: ({ clientId }) =>
        `/api/clients/${clientId}/documents?status=archived`,
      providesTags: ["archivedClientDocuments"]
    }),
    deleteClientDocument: builder.mutation({
      query: ({ documentId }) => ({
        url: `/api/documents/${documentId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["clientDocuments", "archivedClientDocuments"]
    }),
    restoreClientDocument: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}/restore`,
        method: "POST",
        body: document,
        invalidatesTags: ["clientDocuments", "archivedClientDocuments"]
      })
    }),
    updateProjectDocumentProperties: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}`,
        method: "PUT",
        body: document
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const { document } = request;
        try {
          const result = await queryFulfilled;
          if (result.data) {
            dispatch(
              projectDocumentUtil.updateQueryData(
                "getProjectFolderDocuments",
                { projectId: document.projectId },
                draft => {
                  const draftIndex = draft.documents.findIndex(
                    item => item.id === document.id
                  );
                  const updatedDocuments =
                    result.data.updatedProjectFoldersAndDocuments?.documents ??
                    [];
                  const updatedDocumentsIndex = updatedDocuments?.findIndex(
                    d => d.id === document.id
                  );
                  const updatedDocument =
                    updatedDocuments[updatedDocumentsIndex];
                  if (draftIndex !== -1) {
                    draft.documents[draftIndex] = { ...updatedDocument };
                  }
                }
              )
            );
          }
        } catch (e) {}
      },
      invalidatesTags: ["documentProperties"]
    }),
    updateClientDocumentProperties: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}`,
        method: "PUT",
        body: document
      }),
      invalidatesTags: ["documentProperties", "clientDocuments"]
    }),
    getDocumentRevisions: builder.query({
      query: ({ documentId }) => ({
        url: `/api/documents/${documentId}/revisions`,
        method: "GET"
      }),
      providesTags: ["documentRevisions"]
    }),
    setDocumentCurrentRevision: builder.mutation({
      query: ({ documentId, revisionId }) => ({
        url: `/api/documents/${documentId}/revisions/${revisionId}/current`,
        method: "PUT",
        body: {}
      }),
      invalidatesTags: ["documentRevisions"],
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(
            projectDocumentUtil.invalidateTags([
              { type: "projectDocuments", id: request.documentId }
            ])
          );
        } catch (_e) {}
      }
    })
  })
});

export const {
  useGetClientDocumentsQuery,
  useGetArchivedClientDocumentsQuery,
  useGetDocumentPropertiesQuery,
  useUpdateProjectDocumentPropertiesMutation,
  useUpdateClientDocumentPropertiesMutation,
  useDeleteClientDocumentMutation,
  useRestoreClientDocumentMutation,
  useSetDocumentCurrentRevisionMutation,
  useGetDocumentRevisionsQuery,
  useGetProjectDocumentsWithSameNamePresentQuery,
  util: documentServiceUtil
} = documentService;
