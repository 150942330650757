import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { documentActions } from "@app/helpers/documents";

import Form from "@components/atoms/Form/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import ModalForm from "@components/molecules/ModalForm";

import DocumentProperties from "../DocumentProperties/DocumentProperties";

const projectDocumentStatus = systemConstants.project.document.status;
const documentStatus = Object.keys(projectDocumentStatus)
  .filter(
    key =>
      projectDocumentStatus[key] !== projectDocumentStatus.deleted &&
      projectDocumentStatus[key] !== projectDocumentStatus.uploaded &&
      projectDocumentStatus[key] !== projectDocumentStatus.archived
  )
  .map((status, index) => ({
    id: index,
    name: projectDocumentStatus[status]
  }));

function DocumentPropertiesModalForm({
  document,
  entities,
  source,
  onSubmit,
  onCancel,
  isLoading,
  error
}) {
  const {
    locale,
    options: { longFormat }
  } = useLocaleDate();
  const { t } = useTranslation();

  const initialStatus = useMemo(() => {
    const status =
      document.status &&
      document.status === systemConstants.document_status_uploaded
        ? projectDocumentStatus.none
        : document.status;
    return documentStatus.find(ds => ds.name === status);
  }, [document]);

  const methods = useForm({
    defaultValues: {
      name: document.name,
      status: initialStatus,
      uploadedBy: document.uploadedBy?.name,
      createdAt: dateFormatter(document.createdAt, locale, longFormat),
      updatedAt: dateFormatter(document.updatedAt, locale, longFormat)
    },
    mode: "onChange"
  });
  const { setValue, getValues } = methods;

  const documentExtension = document?.name.split(".").pop();

  const getNameWithoutExtension = () => {
    const currentName = getValues("name");
    return currentName.slice(0, documentExtension.length * -1 - 1);
  };

  const getNameWithExtension = () => {
    const currentName = getValues("name");
    if (currentName?.length === 0) return "";
    return `${currentName}.${documentExtension}`;
  };

  return (
    <FormProvider {...methods}>
      <ModalForm
        boxClassName="document-properties"
        title={t("common:documents.documentActions", {
          context: documentActions.updateDocumentProperties
        })}
        handleCancel={onCancel}
        handleSubmit={onSubmit}
        submitLabel={t("common:ui.forms.update.label")}
        isLoading={isLoading}
        formMethods={methods}
        withoutProvider={true}
      >
        <Form.TextField
          label={t("common:documents.fileName")}
          name="name"
          required={true}
          minLength={documentExtension?.length + 1}
          onFocus={() => {
            setValue("name", getNameWithoutExtension());
          }}
          onBlur={() => {
            setValue("name", getNameWithExtension());
          }}
        />

        {entities?.length &&
          document?.properties?.actionItemTypeKey !==
            systemConstants.actionItemTypes.smartForm && (
            <Form.Multiselect
              key="entities"
              name="entities"
              required={false}
              label={t(`requests:requests.configured.fields.entities.label`)}
              items={entities}
              defaultValue={document?.properties?.entities}
            />
          )}

        <DocumentProperties document={document} source={source} />

        {error && (
          <InlineAlert
            message={t("common:ui.updateDocumentProperties.error.fileName")}
            type="error"
          />
        )}
      </ModalForm>
    </FormProvider>
  );
}

DocumentPropertiesModalForm.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
    entities: PropTypes.array
  }),
  entities: PropTypes.array,
  source: PropTypes.shape({
    smartFormName: PropTypes.string,
    refNo: PropTypes.string,
    categoryName: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default DocumentPropertiesModalForm;
