import {
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery
} from "@reduxjs/toolkit/query/react";
import { Action, Dispatch } from "redux";

import { systemConstants } from "../constants";
import { projectMemberUtil } from "./projectMemberService";
import { projectServiceUtil } from "./projectService";
import prepareHeaders from "./rtkUtilities";

type onUserMutatedParams = {
  dispatch: Dispatch<Action<unknown>>;
  queryFulfilled: Promise<{
    data: unknown;
    meta: FetchBaseQueryMeta | undefined;
  }>;
};

const onUserMutated = async ({
  dispatch,
  queryFulfilled
}: onUserMutatedParams) => {
  try {
    await queryFulfilled;
    dispatch(
      projectServiceUtil.invalidateTags([{ type: "Project", id: "LIST" }])
    );
    dispatch(
      projectMemberUtil.invalidateTags([
        "ProjectMembers",
        "ProjectMembersToAdd"
      ])
    );
  } catch (err) {}
};

export const userService = createApi({
  reducerPath: "userServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["User", "Permission", "Profile"],
  endpoints: builder => ({
    getUserById: builder.query({
      query: userId => `/api/users/${userId}`,
      providesTags: ["User"]
    }),
    addClientUser: builder.mutation({
      query: ({ user }) => ({
        url: `/api/clients/${user.clientId}/users`,
        method: "POST",
        body: user
      }),
      invalidatesTags: ["User"]
    }),
    getHostUsers: builder.query({
      query: () => `/api/users?filter=hostUsers`,
      providesTags: ["User"]
    }),
    getClientUsers: builder.query({
      query: clientId => `/api/clients/${clientId}/users`,
      providesTags: ["User"]
    }),
    addHostUser: builder.mutation({
      query: ({ user }) => ({
        url: `/api/users`,
        method: "POST",
        body: user
      }),
      invalidatesTags: ["User"]
    }),
    updateUser: builder.mutation({
      query: ({ user }) => ({
        url: `/api/users/${user.id}`,
        method: "PUT",
        body: user
      }),
      invalidatesTags: ["User", "Permission"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onUserMutated({ dispatch, queryFulfilled })
    }),
    deactivateUser: builder.mutation({
      query: userId => ({
        url: `/api/users/${userId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["User"]
    }),
    getEngagementManagers: builder.query({
      query: () => `/api/users?filter=engagementManagers`,
      providesTags: ["User"]
    }),
    getProfile: builder.query({
      query: userId => `/api/users/${userId}/profile`,
      providesTags: ["Permission", "Profile"] //TODO: need to invalidate when roles change
    }),
    updateProfile: builder.mutation({
      query: user => ({
        url: `/api/users/${user.id}/profile`,
        method: "PUT",
        body: user
      }),
      invalidatesTags: ["Profile"]
    }),
    uploadBulkHostUsers: builder.mutation({
      query: data => ({
        url: `/api/users/bulkUpsert`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["User"]
    }),
    uploadBulkClientUsers: builder.mutation({
      query: ({ data, clientId }) => ({
        url: `/api/clients/${clientId}/users/bulkUpsert`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["User"]
    })
  })
});

export const {
  useGetUserByIdQuery,
  useGetHostUsersQuery,
  useAddClientUserMutation,
  useAddHostUserMutation,
  useUpdateUserMutation,
  useDeactivateUserMutation,
  useGetClientUsersQuery,
  useGetEngagementManagersQuery,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUploadBulkHostUsersMutation,
  useUploadBulkClientUsersMutation
} = userService;
