import { clientDocumentConstants } from "../constants";
import { clientDocumentService } from "../services";

export const clientDocumentActions = {
  getClientDocuments,
  getClientDocumentsBySearchString,
  getArchivedClientDocuments,
  resetGetClientDocuments,
  resetClientUploadedDocuments,
  updateClientDocumentProperties,
  uploadClientDocument,
  getClientDocumentRevisions,
  updateClientDocumentCurrentRevision,
  deleteClientDocument,
  restoreClientDocument
};

function getClientDocuments(clientId) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.getClientDocuments(clientId).then(
      documents => {
        dispatch(success(documents));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_REQUEST
    };
  }
  function success(documents) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_FAILURE,
      error
    };
  }
}

function getClientDocumentsBySearchString(client, search) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.getClientDocumentsBySearchString(client, search).then(
      documents => {
        dispatch(success(documents));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_REQUEST
    };
  }
  function success(documents) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_BY_SEARCH_STRING_FAILURE,
      error
    };
  }
}

function getArchivedClientDocuments(clientId) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.getArchivedClientDocuments(clientId).then(
      documents => {
        dispatch(success(documents));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_REQUEST
    };
  }
  function success(documents) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_FAILURE,
      error
    };
  }
}

function resetGetClientDocuments() {
  return dispatch =>
    dispatch({
      type: clientDocumentConstants.GET_CLIENT_DOCUMENTS_RESET
    });
}

function uploadClientDocument(clientId, document) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.uploadClientDocument(clientId, document).then(
      uploadedObject => {
        dispatch(success(uploadedObject));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_REQUEST
    };
  }
  function success(uploadedObject) {
    return {
      type: clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_SUCCESS,
      uploadedObject
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_FAILURE,
      error
    };
  }
}

function getClientDocumentRevisions(clientId, documentId) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.getClientDocumentRevisions(clientId, documentId).then(
      documentRevisions => {
        dispatch(success(documentRevisions));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENT_REVISIONS_REQUEST
    };
  }
  function success(documentRevisions) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENT_REVISIONS_SUCCESS,
      documentRevisions
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.GET_CLIENT_DOCUMENT_REVISIONS_FAILURE,
      error
    };
  }
}

function resetClientUploadedDocuments() {
  return {
    type: clientDocumentConstants.UPLOAD_CLIENT_DOCUMENT_RESET
  };
}

function updateClientDocumentCurrentRevision(clientId, revision) {
  return dispatch => {
    dispatch(request());

    clientDocumentService
      .updateClientDocumentCurrentRevision(clientId, revision)
      .then(
        updatedObject => {
          dispatch(success(updatedObject));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: clientDocumentConstants.UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_REQUEST
    };
  }
  function success(updatedObject) {
    return {
      type: clientDocumentConstants.UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_SUCCESS,
      updatedObject
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.UPDATE_CLIENT_DOCUMENT_CURRENT_REVISION_FAILURE,
      error
    };
  }
}

function updateClientDocumentProperties(clientId, document) {
  return dispatch => {
    dispatch(request());

    clientDocumentService
      .updateClientDocumentProperties(clientId, document)
      .then(
        updatedObject => {
          dispatch(success(updatedObject));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: clientDocumentConstants.UPDATE_CLIENT_DOCUMENTS_PROPERTIES_REQUEST
    };
  }
  function success(updatedObject) {
    return {
      type: clientDocumentConstants.UPDATE_CLIENT_DOCUMENTS_PROPERTIES_SUCCESS,
      updatedObject
    };
  }
  function failure(error) {
    return {
      type: clientDocumentConstants.UPDATE_CLIENT_DOCUMENTS_PROPERTIES_FAILURE,
      error
    };
  }
}

function deleteClientDocument(clientId, document) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.deleteClientDocument(clientId, document).then(
      deletedDocumentObject => {
        dispatch(success(deletedDocumentObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.DELETE_CLIENT_DOCUMENTS_REQUEST
    };
  }
  function success(deletedDocumentObject) {
    return {
      type: clientDocumentConstants.DELETE_CLIENT_DOCUMENTS_SUCCESS,
      deletedDocumentObject
    };
  }
  function failure(error) {
    if (error.message === "Access denied") {
      error.message =
        "You need to be involved with the client to delete files.";
    }
    return {
      type: clientDocumentConstants.DELETE_CLIENT_DOCUMENTS_SUCCESS,
      error
    };
  }
}

function restoreClientDocument(clientId, document) {
  return dispatch => {
    dispatch(request());

    clientDocumentService.restoreClientDocument(clientId, document).then(
      restoredDocumentObject => {
        dispatch(success(restoredDocumentObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: clientDocumentConstants.RESTORE_CLIENT_DOCUMENT_REQUEST
    };
  }

  function success(restoredDocumentObject) {
    return {
      type: clientDocumentConstants.RESTORE_CLIENT_DOCUMENT_SUCCESS,
      restoredDocumentObject
    };
  }

  function failure(error) {
    if (error.message === "Access denied") {
      error.message =
        "You need to be involved with the client to restore files.";
    }
    return {
      type: clientDocumentConstants.RESTORE_CLIENT_DOCUMENT_FAILURE,
      error
    };
  }
}
