import React, { useCallback, useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import ModalForm from "@components/molecules/ModalForm";

function ModalFormWithSteps(props) {
  const {
    show,
    title,
    submitLabel,
    steps,
    isSlidingForm,
    currentStepIndex,
    onSubmit,
    onNext,
    onCancel,
    modalClassName
  } = props;

  const { t } = useTranslation();
  const getCurrentStepContent = useMemo(
    () => () => steps[currentStepIndex]?.content ?? "",
    [currentStepIndex, steps]
  );
  const getTitle = () => steps[currentStepIndex]?.title ?? title ?? "";
  const getSchema = useMemo(
    () => () => steps[currentStepIndex]?.schema ?? yup.object({}),
    [currentStepIndex, steps]
  );

  const getSubmitHandler = () => {
    if (currentStepIndex >= steps?.length - 1) {
      return steps?.[currentStepIndex]?.handleSubmit ?? onSubmit;
    } else {
      return steps?.[currentStepIndex]?.handleSubmit ?? onNext;
    }
  };

  const getSubmitButtonLabel = () => {
    if (currentStepIndex >= steps?.length - 1) {
      return submitLabel || t("common:ui.forms.submit.label");
    } else {
      return t("common:ui.forms.next.label");
    }
  };

  const shouldDisableSubmit = () =>
    steps[currentStepIndex]?.disableSubmit ?? false;

  const resolver = useCallback(
    async (data, context, options) => {
      const currSchema = getSchema();
      const result = await yupResolver(currSchema)(data, context, options);
      return result;
    },
    [getSchema]
  );

  const formOptions = {
    resolver,
    defaultValues: props.defaultValues || {},
    mode: "onChange"
  };
  const methods = useForm(formOptions);

  const { reset } = methods;

  useEffect(() => {
    if (steps) {
      reset(
        formValues => ({
          ...formValues
        }),
        { keepValues: true }
      );
    }
  }, [reset, steps]);

  return (
    <FormProvider {...methods}>
      <ModalForm
        title={getTitle()}
        show={show}
        slidingForm={isSlidingForm}
        customResolver={resolver}
        boxClassName={modalClassName}
        disabled={shouldDisableSubmit()}
        submitLabel={getSubmitButtonLabel()}
        handleSubmit={getSubmitHandler()}
        handleCancel={onCancel}
        formMethods={methods}
        withoutProvider={true}
      >
        {props.children}
        {getCurrentStepContent()}
      </ModalForm>
    </FormProvider>
  );
}

ModalFormWithSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
      schema: PropTypes.object,
      defaultValues: PropTypes.any
    })
  ).isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  submitLabel: PropTypes.string,
  currentStepIndex: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired
};

export default ModalFormWithSteps;
