import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { manageDocumentDownloadsActions } from "@shared/actions/manageDocumentDownloadsActions";
import { systemConstants } from "@shared/constants/systemConstants";
import {
  useLazyGetActionItemQuery,
  useLazyGetResponsesQuery
} from "@shared/hooks";
import { useAuthUser } from "@shared/hooks/useAuthUser";
import { useGetProjectMembers } from "@shared/hooks/useGetProjectMembers";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import ions from "@ions";

import { routeConstants } from "@app/constants/routeConstants";
import { getActionItemTypeConfiguration } from "@app/helpers/actionItems";
import { useLookupProjectUser } from "@app/hooks/useLookupProjectUser";

import { Button } from "@atoms/Button";

import Avatar from "@components/atoms/Avatar/Avatar";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import ConversationMessage from "@components/molecules/ConversationMessage/ConversationMessage";
import FileAttachmentList from "@components/molecules/FileAttachmentList/FileAttachmentList";
import ModalContentTemplate from "@components/templates/ModalContentTemplate/ModalContentTemplate";

import "./CommentThreadModal.scss";
import CommentThreadModalActions from "./CommentThreadModalActions";

const STATUS = systemConstants.project.queries.status;

const CommentThreadModal = ({ queryId, project, onClose, t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user: authUser } = useAuthUser();
  const { getProjectUser } = useLookupProjectUser(project?.id);
  const [getActionItemQuery, { data: query }] = useLazyGetActionItemQuery();
  const [getResponsesQuery, { data: responses }] = useLazyGetResponsesQuery();
  const { isProjectMember } = useGetProjectMembers(project);
  const [responseSequenceId, setResponseSequenceId] = useState(1);
  const bottomOfConversations = useRef(null);
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: project?.engagement?.engagementTypeId,
      projectId: project?.id
    },
    { skip: project?.engagement?.engagementTypeId === undefined }
  );

  useEffect(
    () => () => {
      dispatch(manageDocumentDownloadsActions.reset());
    },
    [dispatch]
  );

  const handleFileDownload = useCallback(
    ({ id: documentId, name: filename, documentRevisionId }) => {
      if (documentRevisionId) {
        dispatch(
          manageDocumentDownloadsActions.downloadDocumentRevision({
            id: documentId,
            name: filename,
            documentRevisionId
          })
        );
      } else {
        dispatch(
          manageDocumentDownloadsActions.downloadCurrentDocument({
            id: documentId,
            name: filename
          })
        );
      }
    },
    [dispatch]
  );

  const handleViewMoreInformation = useCallback(() => {
    navigate(routeConstants.request.queryDetails, {
      state: { project, query }
    });
  }, [query, navigate, project]);

  useEffect(() => {
    if (actionItemTypes && query && responses) {
      setResponseSequenceId(prev => (!prev ? 1 : prev));
      bottomOfConversations?.current?.scrollIntoView();
    }
  }, [actionItemTypes, query, responses]);

  useEffect(() => {
    if (responseSequenceId) {
      bottomOfConversations?.current?.scrollIntoView();
    }
  }, [responseSequenceId]);

  useEffect(() => {
    getActionItemQuery({ queryId });
    getResponsesQuery({ queryId });
  }, [
    getActionItemQuery,
    getResponsesQuery,
    project?.engagement?.engagementTypeId,
    queryId
  ]);

  const actionItemTypeConfigForQuery = useMemo(
    () => getActionItemTypeConfiguration(query?.queryType, actionItemTypes),
    [actionItemTypes, query]
  );

  const onAddResponseSuccess = useCallback(() => {
    setResponseSequenceId(null);
    getResponsesQuery({ queryId });
  }, [queryId, getResponsesQuery]);

  const headerProps = useMemo(() => {
    const queryTypeLabel =
      t("requests:requests.configured.name", {
        context: actionItemTypeConfigForQuery?.key
      }) ?? query?.queryType;
    return {
      topContent: (
        <div className="comment-thread-modal-header__headline">
          <div className="comment-thread-modal-header__headline-important-info">
            <span>{queryTypeLabel}</span>
            <span>Ref: {query?.refNo}</span>
          </div>
          <div className="comment-thread-modal-header__headline-important-more">
            <Button
              onClick={handleViewMoreInformation}
              variant={
                ions.components?.templates?.content_container?.header
                  ?.button_variant
              }
              iconName="arrow_forward"
              iconOnRight
              label="MORE INFORMATION"
            />
          </div>
        </div>
      ),
      title: query?.description ?? "",
      subtitle: (
        <div className="">
          {query?.query && (
            <div className="conversation-header__content__info">
              {query?.query.split("\n").map((string, i) => {
                return <p key={i}>{string || <br />}</p>;
              })}
            </div>
          )}
        </div>
      ),
      colouredBg: true,
      expandable: true,
      content: !query ? (
        <></>
      ) : (
        <div className="comment-thread-modal-header">
          <div className="comment-thread-modal-header__property-list">
            <div className="comment-thread-modal-header__item comment-thread-modal-header__item--centered">
              <span className="comment-thread-modal-header__item-field">
                Created by:
              </span>
              <span>
                <Avatar
                  user={getProjectUser(query.requestedBy)}
                  width="2rem"
                  height="2rem"
                />
              </span>
              <span>{query.requestedBy.name}</span>
            </div>
            {!!query.assignedTo?.name && (
              <div className="comment-thread-modal-header__item comment-thread-modal-header__item--centered">
                <span className="comment-thread-modal-header__item-field">
                  Assigned to:
                </span>
                <span>
                  <Avatar
                    user={getProjectUser(query.assignedTo)}
                    width="2rem"
                    height="2rem"
                  />
                </span>
                <span>{query.assignedTo.name}</span>
              </div>
            )}
          </div>
          <div className="comment-thread-modal-header__property-list">
            {query.tags?.length > 0 && (
              <div className="comment-thread-modal-header__item">
                <span className="comment-thread-modal-header__item-field">
                  Labels:
                </span>
                <span>{query.tags.map(t => t.name).join(", ")}</span>
              </div>
            )}
          </div>
          <div className="comment-thread-modal-header__attachments">
            <FileAttachmentList
              columnMode="flex"
              attachments={query.attachments}
              onFileDownloadClicked={handleFileDownload}
            />
          </div>
        </div>
      )
    };
  }, [
    actionItemTypeConfigForQuery?.key,
    getProjectUser,
    handleFileDownload,
    handleViewMoreInformation,
    query,
    t
  ]);

  const CommentThreadContent = useMemo(
    () => (
      <div className="comment-thread-modal__content">
        <div className="request-conversation__content-message">
          {responses?.map(response => {
            return (
              <ConversationMessage
                key={response.id}
                align={authUser?.id === response.userId ? "right" : "left"}
                attachments={response.attachments}
                user={{
                  ...response.user,
                  name:
                    response.user.name ||
                    `${response.user.firstname} ${response.user.lastname}`
                }}
                message={response.response}
                updatedAt={response.updatedAt}
                onFileDownloadClicked={handleFileDownload}
              />
            );
          })}
        </div>
        <div ref={bottomOfConversations}></div>
      </div>
    ),
    [authUser?.id, responses, handleFileDownload]
  );

  const CommentTheadActions = useMemo(() => {
    if (query?.status === STATUS.closed) {
      return <></>;
    }

    const actions =
      actionItemTypeConfigForQuery?.menus.find(m => m.name === "BUTTONS")
        ?.actions ?? [];
    const addResponseAction = actions.find(a => a.action === "ADD_RESPONSE");
    if (!addResponseAction) {
      return <></>;
    }

    if (addResponseAction.availableTo === "HOST" && !authUser.isHostUser) {
      return <></>;
    }
    if (!isProjectMember(authUser)) {
      return (
        <div className="comment-thread-modal__actions">
          <InlineAlert
            type="warning"
            message="You need to be involved with the project to perform any action. Add
          yourself to this project, and then try again."
          />
        </div>
      );
    }

    return (
      <div className="comment-thread-modal__actions comment-thread-modal__actions--sticky">
        {/* Changing the seqId causes the component to remount thus resetting its state ;) */}
        {responseSequenceId ? (
          <CommentThreadModalActions
            query={query}
            actionItemTypeConfig={actionItemTypeConfigForQuery}
            onSuccess={onAddResponseSuccess}
          />
        ) : null}
      </div>
    );
  }, [
    actionItemTypeConfigForQuery,
    authUser,
    isProjectMember,
    onAddResponseSuccess,
    query,
    responseSequenceId
  ]);

  const loadingOrNoContentMessage = () => {
    if (!query && !responses) {
      return t("common:loading");
    } else if (!responses || responses?.length === 0) {
      return t(
        "requests:requests.ui.requestConversationBox.requestHasNoResponses",
        {
          requestName: t("requests:requests.configured.shortName", {
            context: actionItemTypeConfigForQuery?.key
          })
        }
      );
    }
    return "";
  };

  return (
    <ModalContentTemplate
      {...{
        modalClassName: "comment-thread-modal",
        header: headerProps,
        body: {
          content: CommentThreadContent,
          loadingOrNoContentMessage: loadingOrNoContentMessage()
        },
        footer: {
          content: CommentTheadActions,
          hideCancel: true,
          handleCancel: onClose,
          buttonOverride: <></>,
          seperator: true
        }
      }}
    />
  );
};

CommentThreadModal.defaultProps = {};

CommentThreadModal.propTypes = {
  queryId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withTranslation()(CommentThreadModal);
