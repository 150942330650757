import axios from "axios";

import { manageDocumentUploadsActions } from "../actions";
import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { utilities } from "../helpers";

const baseURL = systemConstants.serverURL;

export const manageDocumentUploadsService = {
  uploadDocument,
  uploadInteractiveReport
};

function uploadDocument({
  dispatch,
  file,
  projectId,
  clientId,
  documentType,
  properties,
  internalOnly = false
}) {
  if (projectId && clientId) {
    return Promise.reject({
      message: "cannot have both projectId and clientId"
    });
  }

  if (projectId) {
    return uploadFileForProjectDocument({
      dispatch,
      file,
      projectId,
      documentType,
      properties,
      internalOnly
    });
  }

  if (clientId) {
    return uploadFileForClientDocument({ dispatch, file, clientId });
  }

  return Promise.reject({ message: "must have projectId or clientId" });
}

function uploadFileForClientDocument({ dispatch, file, clientId }) {
  let source = axios.CancelToken.source();

  const data = new FormData();
  data.append("document", JSON.stringify({ clientId }));
  data.append("file", file);

  return axios({
    url: `${baseURL}/api/clients/${clientId}/documents/upload`,
    method: "POST",
    headers: authHeader({ documentUpload: true }),
    data,
    onUploadProgress: progress => {
      const { loaded, total } = progress;
      const percentageProgress = Math.floor((loaded / total) * 100);
      dispatch(
        manageDocumentUploadsActions.updateUploadProgress({
          clientId,
          name: file.name,
          uploadProgress: percentageProgress,
          reqSource: source
        })
      );
    },
    cancelToken: source.token
  })
    .then(handleAxiosResponse)
    .catch(handleUploadError);
}

function uploadFileForProjectDocument({
  dispatch,
  file,
  projectId,
  documentType,
  properties,
  internalOnly = false
}) {
  let source = axios.CancelToken.source();
  const document = { projectId, documentType, properties, internalOnly };
  const data = new FormData();
  data.append("document", JSON.stringify(document));
  data.append("file", file);

  return axios({
    url: `${baseURL}/api/projects/${projectId}/documents/upload`,
    method: "POST",
    headers: authHeader({ documentUpload: true }),
    data,
    onUploadProgress: progress => {
      const { loaded, total } = progress;
      const percentageProgress = Math.floor((loaded / total) * 100);
      dispatch(
        manageDocumentUploadsActions.updateUploadProgress({
          projectId,
          name: file.name,
          uploadProgress: percentageProgress,
          reqSource: source
        })
      );
    },
    cancelToken: source.token
  })
    .then(handleAxiosResponse)
    .catch(handleUploadError);
}

function uploadInteractiveReport({ dispatch, file, projectId }) {
  let source = axios.CancelToken.source();

  const data = new FormData();
  data.append("document", JSON.stringify({ projectId }));
  data.append("file", file);

  return axios({
    url: `${baseURL}/api/projects/${projectId}/interactiveReport/upload`,
    method: "POST",
    headers: authHeader({ documentUpload: true }),
    data,
    onUploadProgress: progress => {
      const { loaded, total } = progress;
      const percentageProgress = Math.floor((loaded / total) * 100);
      dispatch(
        manageDocumentUploadsActions.updateUploadProgress({
          projectId,
          name: file.name,
          uploadProgress: percentageProgress,
          reqSource: source
        })
      );
    },
    cancelToken: source.token
  })
    .then(handleAxiosResponse)
    .catch(handleUploadError);
}

function handleAxiosResponse(response) {
  return Promise.resolve(response.data);
}

function handleUploadError(error) {
  if (axios.isCancel(error)) {
    return Promise.reject({
      message: error?.key ?? error?.message
    });
  }

  if (error?.response?.status === 500) {
    return Promise.reject({
      message: utilities.getFormattedErrorMessage(
        systemConstants.support.message ||
          "An unexpected error occurred. Please try again, and if the problem persists contact support"
      )
    });
  }

  if (error?.response?.data?.message) {
    if (error.response.data.message === "Invalid request") {
      return Promise.reject({
        message: utilities.getFormattedErrorMessage(
          systemConstants.support.email
            ? `Invalid request. Please try again, and if the problem persists contact support on ${systemConstants.support.email}`
            : "Invalid request. Please try again, and if the problem persists contact support"
        )
      });
    }

    if (typeof error.response.data.message === "object") {
      return Promise.reject({ message: error.response.data.message });
    }

    if (
      error.response.data.message.includes("JSON") ||
      error.response.data.message.includes("fetch") ||
      error.response.data.message.includes("null")
    ) {
      return Promise.reject({
        message: utilities.getFormattedErrorMessage(
          systemConstants.support.message ||
            "An unexpected error occurred. Please try again, and if the problem persists contact support"
        )
      });
    }

    return Promise.reject({
      message: error.response.data.key ?? error.response.data.message
    });
  }

  return Promise.reject({
    message: utilities.getFormattedErrorMessage(
      systemConstants.support.message ||
        "An unexpected error occurred. Please try again, and if the problem persists contact support"
    )
  });
}
